/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
} from "design_system/src";
import useLocalStorage from "hooks/useLocalStorage";
import { Grid, useMediaQuery } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import { TVehicle } from "models/inventory/interfaces/vehicle.interface";
import { mockVehicleList } from "models/inventory/mocks/vehicle.mock";
import { formatPrice } from "../../helpers/formatPrice";
import { prices } from "../../helpers/prices";

interface DiscountLabelProps {
  total: number;
  value: number;
}

export const DiscountLabel: React.FC<DiscountLabelProps> = ({ total, value }) => {
  const [percentage, setpercentage] = React.useState<number>();

  useEffect(() => {
    setpercentage(() => 100 - (value * 100) / total);
  }, [value, total]);

  return (
    <Typography
      scale="xxsmall"
      weight="600"
      className="text_no_break"
      style={{ display: "flex", justifyContent: "center", alignItems: "start" }}
    >
      {`-${percentage?.toFixed()}% Desc.`}
    </Typography>
  );
};

export const VehicleCard: React.FC<{ vehicle: TVehicle }> = ({ vehicle }) => {
  const handleCity = (city: string): string => {
    switch (city) {
      case "Guadalajara":
        return "GDL";
      case "Ciudad de México":
        return "CDMX";
      default:
        return "CDMX";
    }
  };

  return (
    <div className="p_y_sm p_x_md display_flex flex_col_desktop flex_gap_xs pos_relative">
      <div className="image-box flex_basis_0_mobile flex_grow_2_mobile overflow_hidden_mobile">
        <figure className="m_none pos_relative">
          <CardMedia
            component="img"
            className="br_xs card_media_vehicle"
            image={vehicle?.images && vehicle?.images[0]?.url}
            alt="vehicle"
            sx={{ borderRadius: 2 }}
          />

          {vehicle?.hasDiscount() && (
            <div
              className="pos_absolute display_none_mobile bg_primary_300 br_xxs text_neutral_0 p_x_xs p_y_xxs"
              style={{ top: 0, right: 0 }}
            >
              <DiscountLabel
                total={vehicle.getWeeklyPriceWithoutDiscount(78)}
                value={vehicle.getWeeklyPrice(78)}
              />
            </div>
          )}
        </figure>
      </div>
      <div className="display_flex flex_col flex_basis_0_mobile flex_grow_3_mobile">
        <div className="p_y_xxs display_flex flex_wrap flex_gap_xxs">
          {vehicle?.hasDiscount() && (
            <div
              className="display_none_desktop bg_primary_300 br_xxs text_neutral_0 p_x_xs p_y_xxs"
              style={{ zIndex: 1, top: 0, right: 0 }}
            >
              <DiscountLabel
                total={vehicle.getWeeklyPriceWithoutDiscount(78)}
                value={vehicle.getWeeklyPrice(78)}
              />
            </div>
          )}

          {vehicle?.isNewVehicle &&
            vehicle.isPresale &&
            vehicle.isNewVehicle === true &&
            vehicle.isPresale === true && (
              <div className="display_flex flex_center">
                <div className="br_xxs bg_green_300 p_y_xxxs p_x_xs">
                  <Typography
                    scale="xsmall"
                    weight="600"
                    className="text_neutral_100"
                  >
                    PRE-VENTA
                  </Typography>
                </div>
              </div>
            )}
          {vehicle?.isNewVehicle && vehicle.isNewVehicle === true && (
            <div className="display_flex flex_center">
              <div className="br_xxs border_solid border_primary_300 border_1 text_primary_300 p_y_xxxs p_x_xs">
                <Typography scale="xsmall" weight="600">
                  NUEVA
                </Typography>
              </div>
            </div>
          )}
          {vehicle?.isPlatedVehicle && vehicle.isPlatedVehicle === true && (
            <div className="display_flex flex_center">
              <div className="br_xxs bg_primary_300 p_y_xxxs p_x_xs">
                <Typography
                  scale="xsmall"
                  weight="600"
                  className="text_neural_0"
                >
                  EMPLACADA
                </Typography>
              </div>
            </div>
          )}
          {!((vehicle?.isNewVehicle &&
            vehicle.isPresale &&
            vehicle.isNewVehicle === true &&
            vehicle.isPresale === true ) || (
            vehicle?.isNewVehicle && vehicle.isNewVehicle === true) || (
            vehicle?.isPlatedVehicle && vehicle.isPlatedVehicle === true)) && (
              <div className="display_flex flex_center">
                <div className="br_xxs bg_neutral_100 p_y_xxxs p_x_xs">
                  <Typography
                    scale="xsmall"
                    weight="600"
                    className="text_neutral_100"
                  >
                    Moto
                  </Typography>
                </div>
              </div>
            )}

        </div>
        <div className="p_y_xxs">
          <Typography
            scale="medium"
            scaleMobile="xsmall"
            weight="600"
            className="m_none"
            component="h3"
          >
            {`${vehicle?.brand?.name} ${vehicle?.model?.name} ${
              vehicle?.cylindersCapacity
            }${vehicle?.suffix ? vehicle.suffix : ""}`}
          </Typography>
        </div>
        <div className="display_flex flex_wrap w_100_per p_y_xxs text_neutral_800">
          <Typography
            scale="xsmall"
            scaleMobile="xxsmall"
            weight="400"
            className="text_center text_no_break p_r_xxs m_r_xxs border_r_solid border_r_1"
          >
            {vehicle?.year}
          </Typography>
          <Typography
            scale="xsmall"
            scaleMobile="xxsmall"
            weight="400"
            className="text_center text_no_break p_r_xxs m_r_xxs border_r_solid border_r_1"
          >
            {vehicle?.mileage === 0 && !vehicle?.isNewVehicle
              ? "KM pendiente"
              : `${vehicle?.mileage} Km`}
          </Typography>
          <Typography
            scale="xsmall"
            scaleMobile="xxsmall"
            weight="400"
            className="text_center text_no_break p_r_xxs m_r_xxs border_r_solid border_r_1"
          >
            {`${vehicle?.cylindersCapacity} CC`}
          </Typography>
          <Typography
            scale="xsmall"
            scaleMobile="xxsmall"
            weight="400"
            className="text_center text_no_break"
          >
            {handleCity(vehicle?.location.city?.name || "CDMX")}
          </Typography>
        </div>
        <div className="p_y_xxs w_100_per br_xs">
            <Typography
              scale="xsmall"
              scaleMobile="xxsmall"
              weight="400"
              component="p"
              className="m_none"
            >
              Desde:
            </Typography>
            <Typography
              scale="heading3"
              weight="600"
              scaleMobile="medium"
              component="p"
              className="m_none"
            >
              ${formatPrice(vehicle.getWeeklyPrice(78), "")}
            </Typography>
            <Typography
              scale="xsmall"
              scaleMobile="xxsmall"
              weight="400"
              component="p"
              className="m_none"
            >
              Semanales
            </Typography>
        </div>
      </div>
    </div>
  );
};

const LoanApplicationVehicles = () => {
  const matchesXS = useMediaQuery("(min-width:600px)");
  const [loading, setLoading] = useState<boolean>(true);
  const [storedValue, setValue] = useLocalStorage<any>("loanApplication", null);
  const navigate = useNavigate();

  useEffect(() => {
    if (storedValue === null && !loading) {
      navigate("/solicitud-credito");
    } else {
      setLoading(false);
    }
  }, [storedValue, loading]);

  const selectVehicle = (vehicle: TVehicle) => {
    setValue({
      ...storedValue,
      vehicle
    });
  };

  return (
    <div className="dso_container" style={{ minHeight: "75vh", maxWidth: "1200px" }}>
      <div className="m_y_lg_mobile m_y_xxxl_desktop m_x_auto">
          <Typography className="m_y_none m_b_md text_center" weight="600" scale="heading3" scaleMobile="heading4" component="h1">
            Vehiculos disponibles para ti
          </Typography>

          <Grid
            container
            rowSpacing={!matchesXS ? 2 : 3}
            columnSpacing={!matchesXS ? 0 : 2}
          >
            {mockVehicleList
              .map((vehicle, idx) => {
                return (
                  <Grid
                    key={`VehicleCardContainer-${idx}`}
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={3}
                    style={{ minHeight: "100%" }}
                  >
                    <div
                      className="dso_card bg_neutral_0 cursor_pointer"
                      style={{ height: "100%"}}
                    >
                      <Link onClick={() => selectVehicle(vehicle)} to="/solicitud-credito/validacion-ingresos-aprobada" className="custom_a">
                        <VehicleCard vehicle={vehicle} />
                      </Link>
                    </div>
                  </Grid>
                );
              })}
          </Grid>
      </div>
    </div>
  );
};

export default LoanApplicationVehicles;
