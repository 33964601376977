/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react";
import { Button } from "design_system/src";
import useLocalStorage from "hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";

const ResetButton = () => {
  const [storedValue, setValue] = useLocalStorage<any>("loanApplication", null);
  const navigate = useNavigate();

  const onClick = () => {
    setValue(null);
    navigate("/solicitud-credito");
  };

  return (
    <div className="m_t_xxl display_flex flex_justify_end">
      <Button scale="small" onClick={onClick} variant="ghost">Restablecer flujo</Button>
    </div>
  );
};

export default ResetButton;
