import {
  DtoCreateOzocio,
  DtoResponseOzocio,
  DtoOzocioVehicle,
  DtoResponseOzocioVehicleSucces,
} from "models/ozocio/interfaces/ozocio.interface";
import { PandaboardApiInstance } from "../../../helpers/api";
import { getUtmObject } from "../../../helpers/queryParams";

export const fetchCreateOzocio = async (
  ozocio: DtoCreateOzocio
): Promise<DtoResponseOzocio> =>
  PandaboardApiInstance.post("ozocio", ozocio)
    .then(({ data }) => data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchCreateOzocioVehicle = async (
  vehicle: DtoOzocioVehicle,
  oid: string
): Promise<DtoResponseOzocioVehicleSucces> => {
  const utm = getUtmObject();
  return PandaboardApiInstance.post(`ozocio/${oid}/vehicle`, {
    ...vehicle,
    utm
  })
    .then(({ data }) => data)
    .catch((err: any) => {
      throw err.response.data;
    });
};
