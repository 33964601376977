import React, { useEffect, useState } from 'react';
import Typography from '../Typography/Typography';
import { ReactComponent as Right } from '../../static/icons/right-lg.svg';

import './Breadcrumb.scss';

interface BreadcrumbProps {
	url: string;
}

const BreadCrumb: React.FC<BreadcrumbProps> = ({ url }) => {
	const [crumbs, setcrumbs] = useState<string[]>([]);

	useEffect(() => {
		if (url) {
			setcrumbs([
				'INICIO',
				...url.split('/').filter((part) => part.length > 0),
			]);
		}
	}, [url]);

	return (
		<div className="display_flex bg_neutral_0 p_md flex_gap_xs flex_align_center">
			{crumbs.map((crumb, index, array) => {
				const path =
					index === 0 ? '/' : `/${array.slice(1, index + 1).join('/')}`;
				const isSelected = index === array.length - 1;

				if (isSelected) {
					return (
						<div key={path}>
							<Typography scale="small" weight="400" className="selected">
								{crumb.toUpperCase()}
							</Typography>
						</div>
					);
				}
				return (
					<>
						<div key={path} className="link">
							<Typography scale="small" weight="400">
								<a href={path}>{crumb.toUpperCase()}</a>
							</Typography>
						</div>
						<div className="display_flex flex_align_center">
							<Right className="text_neutral_700" />
						</div>
					</>
				);
			})}
		</div>
	);
};

export default BreadCrumb;
