/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Grid, Stack, Slider } from "@mui/material";

import "./styles.scss";
import { useFormikContext } from "formik";

import _ from "lodash";
import { Input } from "design_system/src";

import { IStepperFormValues } from "views/formApplication/context/context";

interface Props {
  icon: React.ReactElement;
  label: any;
  name: string;
  placeholder?: string;
  initValue?: number;
}

const boxStyle = {
  bgcolor: "rgb(233, 234, 236)",
  boxShadow:
    "2px 4px 8px rgba(34, 38, 42, 0.05), 4px 8px 24px rgba(34, 38, 42, 0.1)",
  borderRadius: 2,
};

const cardBody = {
  paddingRight: 10,
  paddingLeft: 10,
  paddingBottom: 10,
};

const footerStyle = {
  backgroundColor: "white",
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 40,
  paddingRight: 40,
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
};

const headerStyle = {
  backgroundColor: "white",
  padding: 10,
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  paddingLeft: 30,
  paddingRight: 30,
};

const IncomeCard: React.FC<Props> = ({ icon, label, name, placeholder, initValue }) => {
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<IStepperFormValues>();

  const [value, setValue] = useState<number>(initValue ?? 0);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    let rawValue = Number(event.target.value.replace(/,/g, "").replace("$", ""));
    if (!rawValue) {
      rawValue = 0;
    }
    setValue(rawValue);
    setFieldValue(name, rawValue);
  };

  const formatNumber = (num: Number) => {
    return `$${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  return (
    <Box sx={boxStyle}>
      <>
        <div style={headerStyle} className="shadow_hard_inv">
          <Grid container>
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
                {icon}
              </div>
              {label}
            </Grid>
          </Grid>
        </div>
        <div style={cardBody}>
          <Input
            type="text"
            style={{ textAlign: "center" }}
            title=""
            placeholder={placeholder || ""}
            name={name}
            onChange={handleInputChange}
            onBlur={handleBlur}
            value={value === 0 ? "" : formatNumber(value)}
            error={
              !!_.get(errors, name) &&
              getFieldMeta(name).touched
            }
            subtitle={
              getFieldMeta(name).touched
                ? (_.get(errors, name) as string)
                : undefined
            }
          />
        </div>
      </>
    </Box>
  );
};

export default IncomeCard;
