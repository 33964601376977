/* eslint-disable no-unused-vars */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Stepper, StepperBody, StepperSteps, useStepper } from '../../Stepper';
import './styles.scss';

interface Props {
	swipeable?: boolean;
	autoplay?: boolean;
	autoplaySpeed?: number;
	defaultArrows?: boolean;
}

const Carousel: FC<Props> = ({
	children,
	swipeable = true,
	autoplay = true,
	autoplaySpeed = 5,
	defaultArrows = true,
}) => {
	const {
		decrementCurrentStep,
		incrementCurrentStep,
		currentStep,
		steps,
		setCurrentStep,
	} = useStepper();
	const [swiping, setSwiping] = useState<boolean>(false);

	const onSwiping = useCallback((value) => {
		setSwiping(value);
	}, []);

	useEffect(() => {
		if (!autoplay) return () => {};
		const intervalId = setInterval(() => {
			if (swiping) return;

			if (currentStep < steps.length - 1) {
				incrementCurrentStep();
			} else {
				setCurrentStep(0);
			}
		}, autoplaySpeed * 1000);
		return () => clearInterval(intervalId);
	}, [
		autoplaySpeed,
		autoplay,
		incrementCurrentStep,
		currentStep,
		setCurrentStep,
		steps,
		swiping,
	]);

	return (
		<div className="carousel-container">
			<Stepper cancelScrollOnChange>
				{defaultArrows && steps.length > 1 && (
					<>
						<div
							onClick={decrementCurrentStep}
							className="arrow left"
							onKeyDown={decrementCurrentStep}
							role="button"
							tabIndex={-1}
						>
							<div className="box down bg_primary_300" />
							<div className="box up outline bg_neutral_0 border_neutral_700" />
						</div>
						<div
							onClick={incrementCurrentStep}
							className="arrow right"
							onKeyDown={incrementCurrentStep}
							role="button"
							tabIndex={-1}
						>
							<div className="box down bg_primary_300" />
							<div className="box up outline bg_neutral_0 border_neutral_700" />
						</div>
					</>
				)}

				<StepperBody>
					<StepperSteps onSwiping={onSwiping} swipeable={swipeable}>
						{children}
					</StepperSteps>
				</StepperBody>
			</Stepper>
		</div>
	);
};

export default Carousel;
