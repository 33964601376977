/* eslint-disable no-shadow */
export enum EconomicActivityEnum {
    empleado = "empleado",
    repartidor = "repartidor",
    comerciante = "comerciante",
    conductor = "conductor",
    conductorRepartidor = "conductorRepartidor",
    ayudanteGeneral = "ayudanteGeneral",
    seguridad = "seguridad",
    independiente = "independiente",
    estudiante = "estudiante",
    policia = "policia",
    servidorPublico = "servidorPublico",
    enfermero = "enfermero",
    mecanico = "mecanico",
    otro = "otro",
}

export enum AssetsEnum {
    NINGUNO="Ninguno",
    CASA="Casa",
    CARRO="Carro",
    MOTO="Moto",
    OTRO = "Otro",
}
