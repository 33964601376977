import { Button, ProgressBar, Typography } from "design_system/src";
import moment from "moment";
import ReactGA from "react-ga4";

import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "store";
import { useFinancialForm } from "hooks/useFinancialForm";
import { useUser } from "hooks/useUser";
import { logout } from "store/actions/user";
import { fetchGetIdLoan } from "models/loan/controllers/loan.controller";
import { formatPrice } from "helpers/formatPrice";
import { FinancialFormStatusEnum } from "models/financialForm/enums/financialForm.enums";

import "./miCuentaScreen.scss";
import { LoanWithExtraFields } from "models/loan/interfaces/loan.interface";
import { Payment } from "models/loan/interfaces/payment.interface";
import { PaymentStatus } from "models/loan/enums/payment.enum";
import { OrderStatus } from "models/loan/enums/order.enums";
import { Order } from "models/loan/interfaces/order.interface";

moment.locale("es");

export const MiCuentaScreen = () => {
  const navigate = useNavigate();
  const { financialForm } = useFinancialForm();
  const { user } = useUser();
  const [selectedLoan, setSelectedLoan] = useState<LoanWithExtraFields>();
  const [loans, setLoans] = useState<LoanWithExtraFields[]>([]);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [nextPayment, setNextPayment] = useState<Order>();
  const defaultMoto =
    "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/generic-moto.png";

  useLayoutEffect(() => {
    if (financialForm?.status === FinancialFormStatusEnum.COMPLETED) {
      ReactGA.event("VIEW_MyAccountCredit", {
        category: "VIEW_MyAccountCredit",
        label:"page view for loan info view",
      });

      fetchGetIdLoan(user._id)
        .then((loansResponse: LoanWithExtraFields[]) => {
          setLoans(loansResponse);
          const currentLoan = loansResponse.filter(
            (loan) => loan.financialForm._id === financialForm._id
          )[0];
          setPayments(currentLoan.payments);
          setSelectedLoan(currentLoan);
          setNextPayment(
            currentLoan.orders
              .filter(
                (order) => order.status !== OrderStatus.paid
              )
              .sort((a, b) => a.quota - b.quota)[0]
          );
        });

      return;
    }

    if (financialForm?.status === FinancialFormStatusEnum.PENDING) {
      navigate("/mi-cuenta/documentos");
      return;
    }

    if (
      !(
        financialForm?.status === FinancialFormStatusEnum.CANCELED ||
        financialForm?.status === FinancialFormStatusEnum.EXPIRED
      )
    ) {
      navigate("/mi-cuenta/solicitud");
      return;
    }

    store.dispatch(logout());
    navigate("/ingresar");
  }, []);

  const openPayPlatform = ()=>{
    ReactGA.event("CTA_MyAccount_go_to_moonflow_account", {
      category: "MyAccount",
      label: "click in CTA to open moonflow dashboard",
    });
    window.open(process.env.REACT_APP_PAYMENT_PLATFORM_URL, "_blank");

  };

  return selectedLoan ? (
    <div
      className="dso_container p_t_xxxl_desktop p_t_xl_mobile"
    >
      <Typography
        weight="600"
        scale="large"
        scaleMobile="medium"
        className=" w_100_per text_center_mobile m_b_xl_mobile m_b_md_desktop text_capitalize"
      >
        Bienvenid@ <strong>{user?.name}</strong>
      </Typography>
      <div className="display_flex flex_col_mobile flex_gap_xl_desktop flex_gap_sm_mobile m_b_md">
        <div className="display_flex flex_col_mobile flex_gap_xxxl_desktop flex_gap_xl_mobile br_xs bg_primary_25 flex_grow_2  p_x_xxxl_desktop p_y_lg_desktop p_xs_mobile">
          <div className="flex_center_col flex_grow_2 flex_gap_xl_desktop flex_gap_md_mobile p_y_xxl_desktop">
            <img
              className="w_100_per h_200_px br_sm"
              style={{ objectFit: "cover" }}
              src={
                selectedLoan.vehicle.images?.length
                  ? selectedLoan.vehicle.images[0].url
                  : defaultMoto
              }
              alt={
                selectedLoan.vehicle.images?.length
                  ? selectedLoan.vehicle.images[0].name
                  : "ozon-mototcycle"
              }
            />
            <div className="flex_center_col flex_gap_xs">
              <Typography weight="400" scale="large">
                {selectedLoan.vehicle.brand.name}{" "}
                <strong>{selectedLoan.vehicle.model.name}</strong>
              </Typography>
              <Typography weight="400" scale="small">
                {selectedLoan.vehicle.year} |{" "}
                {selectedLoan.vehicle.cylindersCapacity}cc |{" "}
                {selectedLoan.vehicle.mileage}km
              </Typography>
            </div>
          </div>
          <div className="flex_grow_8 flex_shrink_0 display_flex flex_col flex_gap_xs p_x_md_mobile">
            <Typography weight="400" scale="xsmall" className="text_right">
              Vehículo
            </Typography>
            <Typography weight="600" scale="large" className="text_right">
              {selectedLoan.vehicle.internalId}
            </Typography>
            {selectedLoan ? (
              <>
                <Typography weight="400" scale="small">
                  Deuda
                </Typography>
                <Typography weight="600" scale="heading3">
                  ${formatPrice(selectedLoan.totalDebt, "CO")}
                </Typography>
                <ProgressBar
                  containerClassName="bg_primary_100 h_sm"
                  fillClassName="bg_primary_300 h_sm"
                  percentage={
                    (Number(selectedLoan.totalPaid) * 100) /
                    Number(selectedLoan.totalLoan)
                  }
                />
                <div className="flex_center flex_justify_between border_b_1 border_neutral_600 border_b_dotted">
                  <Typography weight="400" scale="small">
                    Saldado:
                  </Typography>
                  <Typography weight="400" scale="small" className="m_b_md">
                    ${formatPrice(selectedLoan.totalPaid, "CO")}
                  </Typography>
                </div>
              </>
            ) : null}
            {nextPayment && (<>
              <div className="flex_center flex_justify_between m_b_md">
                <div>
                  <Typography weight="400" scale="small" className="m_b_xs">
                    Siguiente pago
                  </Typography>
                  <Typography weight="600" scale="large" className="">
                    ${formatPrice(selectedLoan.weeklyPayment)}
                  </Typography>
                </div>
                <div>
                  <Typography
                    weight="400"
                    scale="small"
                    className="text_right m_b_xs"
                  >
                    Fecha máxima de pago
                  </Typography>
                  <Typography weight="600" scale="large" className="text_right">
                    {moment.utc(nextPayment.paidDate).format("DD MMM, YYYY")}
                  </Typography>
                </div>
              </div>
              <Button scale="small" className="m_b_md" onClick={openPayPlatform}>Pagar cuota semanal</Button>
            </>)}
          </div>
        </div>
        <div className="flex_grow_1 br_xs bg_neutral_300 p_xl_desktop p_y_xl_mobile p_x_md_mobile pos_relative">
          <Typography weight="400" scale="small" className="text_center m_b_xl">
            Pagos <strong>recientes</strong>
          </Typography>
          <div className="pos_absolute_desktop h_80_per_desktop overflow_y_scroll w_100_per_desktop p_x_xl_desktop p_x_md_mobile payments">

          {payments
            .filter((payment) => payment.status === PaymentStatus.paid)
            .sort((a, b) => b.quota - a.quota)
            .map((payment) => (
              <div className="flex_center flex_justify_between border_b_1 border_neutral_600 border_b_dotted p_y_md">
                <Typography weight="600" scale="small" className="">
                  {payment.quota}
                </Typography>
                <Typography weight="400" scale="small" className="">
                  {moment.utc(payment.paidDate).format("DD MMM, YYYY")}
                </Typography>
                <Typography weight="400" scale="small" className="">
                  ${formatPrice(payment.paidAmount, "CO")}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div/>
  );
};
