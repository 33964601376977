export const translateColorValues =  (selectedFilters:any) => {

  if(!selectedFilters) return {};

  const selectedColor = selectedFilters.color;
  const defaultSource = "Blanco";

  const sources: { [key: string]: string; } = {
    "pink": "Rosa",
    "purple": "Morado",
    "blue": "Azul",
    "orange": "Anaranjado",
    "green": "Verde",
    "white": "Blanco",
    "gray": "Gris",
    "red": "Rojo",
    "black": "Negro",
    "yellow": "Amarillo",
    "graphite": "Grafito",
  };

  // eslint-disable-next-line consistent-return
  return {
    ...selectedFilters,
     color: sources[selectedColor] || defaultSource
  };
};
