/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { ReactComponent as Close } from '../../static/icons/close-lg.svg';

import './Tag.scss';
import Typography from '../Typography/Typography';

interface TagProps {
	icon: any;
	onDelete: Function;
	value: string;
	iconColor?: string;
	className?: string;
}
const Tag: FC<TagProps> = ({
	icon,
	onDelete,
	value,
	iconColor = 'primary_300',
	className = '',
}) => (
	<div
		className={`tag-cont display_flex  overflow_hidden br_xs w_fit  shadow_hard flex_align_center ${className}`}
	>
		<div className="display_flex flex_align_center border_r_solid border_neutral_400 p_xs content">
			<div className={`icon text_${iconColor} m_r_xs`}>{icon}</div>
			<Typography scale="small" weight="400" className="text_center w_100_per">
				{value}
			</Typography>
		</div>
		<div className="m_xs icon text_neutral_600 cursor_pointer">
			<Close
				onClick={() => onDelete()}
				role="button"
				tabIndex={0}
				onKeyDown={() => onDelete()}
			/>
		</div>
	</div>
);
export default Tag;
