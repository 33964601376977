import { types } from "../types/types";
import { TVehicle } from "../../models/inventory/interfaces/vehicle.interface";

export interface ILoanVerificationReducerState {
  selectedVehicle: TVehicle | null
  selectedLoanDuration: number | null
}

const DEFAULT_STATE : ILoanVerificationReducerState = {
  selectedVehicle: null,
  selectedLoanDuration: null
};

// eslint-disable-next-line default-param-last
export const applicationReducer = (state = DEFAULT_STATE, action: any) => {
  switch (action.type) {
    case types.applicationSetVehicle:
      return {
        ...state,
        selectedVehicle: action.payload
      };
    case types.applicationSetLoanTime:
      return {
        ...state,
        selectedLoanDuration: action.payload
      };
    default:
      return state;
  }
};
