/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import logoc from "static/logos/logoc.svg";

// design
import "./navbar.scss";

// imgs
import { ReactComponent as HamMenu } from "design_system/src//static/icons/hamburger-menu.svg";

import {
    Button,
    Typography,
} from "design_system/src";
import { store } from "store";
import { logout } from "store/actions/user";
import { fetchLogout } from "models/auth-client/controllers/auth.controller";
import { Cities } from "../../../store/reducers/cityReducer";

type MiCuentaNavbarProps = {
    setOpenCityModal: Function;
};

export const MiCuentaNavbar: React.FC<MiCuentaNavbarProps> = ({ setOpenCityModal }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [sideBar, setSideBar] = useState<boolean>(false);
    const [url, setURL] = useState("");

    const paths: any[] = [];

    const handleLogout = () => {
        fetchLogout().finally(() => {
            store.dispatch(logout());
            navigate("/ingresar");
        });
    };

    useEffect(() => {
        setURL(location.pathname);
    }, [location.pathname]);

    function toggleSideBar() {
        setSideBar(!sideBar);
    }

    const city = localStorage.getItem("city");

    useEffect(() => {
        if (city !== Cities.CDMX) {
            localStorage.setItem("city", Cities.CDMX);
        }
    }, [city]);

    return (
        <div
            className="pos_fixed display_block"
            style={{ zIndex: 999, width: "100%" }}
        >
            <header className="navbar shadow_medium ">
                <div className="main-container">
                    <div
                        className="display_flex"
                        style={{ justifyContent: "start", alignItems: "center" }}
                    >
                        <Link to="/" className="custom_a">
                            <figure className="m_none display_flex">
                                <img src={logoc} alt="Logo Ozon" className="h_xl " />
                            </figure>
                        </Link>
                        <Typography scale="large" weight="400" className="text_primary_200 m_l_sm">
                            | Mi Cuenta
                        </Typography>
                    </div>

                    <Button
                        className="display_none_desktop"
                        variant="icon"
                        scale="small"
                        subvariant="edit"
                        icon={<HamMenu />}
                        onClick={toggleSideBar}
                    />
                    <div
                        className={`backdrop display_none_desktop ${sideBar ? "open" : ""}`}
                        onClick={toggleSideBar}
                        tabIndex={0}
                    >
                        <div className="sidebar" tabIndex={1}>
                            <Link to="/" className="custom_a">
                                <figure className="m_none">
                                <img
                                    src={logoc}
                                    alt="Logo Ozono"
                                    className="m_y_xxxl center_x h_xl"
                                />
                                </figure>
                            </Link>

                            <nav className="flex_center_col flex_align_start p_xxl ">
                                {paths.map((ele) => {
                                    return (
                                        <Link
                                        to={ele.path}
                                        key={ele.path}
                                        className="custom_a"
                                        >
                                            <Button
                                                variant="link"
                                                className={`${ele.path === url ? "active" : ""} m_b_md`}
                                                scale="small"
                                            >
                                                {ele.label}
                                            </Button>
                                        </Link>
                                    );
                                })}
                                <Button
                                    variant="link"
                                    className="m_b_md"
                                    scale="small"
                                    onClick={handleLogout}
                                >
                                    Cerrar sesión
                                </Button>
                            </nav>
                        </div>
                    </div>
                    <nav className="flex_center flex_justify_end flex_grow_1 display_none_mobile pos_relative">
                        {paths.map((ele) => {
                            return (
                                <Link
                                to={ele.path}
                                key={ele.path}
                                className="custom_a"
                                >
                                    <Button
                                        variant="link"
                                        className={`${
                                        ele.path === url ? "m_r_xxxl active" : "m_r_xxxl"
                                        }`}
                                        scale="small"
                                    >
                                        {ele.label}
                                    </Button>
                                </Link>
                            );
                        })}
                        <Button
                            variant="link"
                            className="m_b_md"
                            scale="small"
                            onClick={handleLogout}
                        >
                            Cerrar sesión
                        </Button>
                    </nav>
                </div>
            </header>
        </div>
    );
};
