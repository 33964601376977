import { combineReducers } from "redux";
import { countryReducer } from "./countryReducer";
import { cityReducer } from "./cityReducer";
import { selectReducer } from "./selectReducer";
import { userReducer } from "./userReducer";
import { vehiclesReducer } from "./vehiclesReducer";
import { recommendedReducer } from "./recommendedReducer";
import { applicationReducer } from "./applicationReducer";
import { financialFormReducer } from "./financialFormReducer";

export const RootReducer = combineReducers({
  countryReducer,
  cityReducer,
  applicationReducer,
  selectReducer,
  userReducer,
  vehiclesReducer,
  recommendedReducer,
  financialFormReducer
});
