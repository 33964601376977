import React, { useState } from "react";

import { Typography, Button } from "design_system/src";
import { ReactComponent as WhatsApp } from "design_system/src//static/icons/whatsapp.svg";

import { fetchGetSellResponsible } from "models/financialForm/controllers/sales.controller";
import { useFinancialForm } from "hooks/useFinancialForm";
import { useUser } from "hooks/useUser";

export const WhatsappButton = () => {
    const { financialForm } = useFinancialForm();
    const { user } = useUser();
    const [loading, setLoading] = useState<boolean>(false);

    const handleClick = () => {
        setLoading(true);
        fetchGetSellResponsible(user._id, financialForm!._id)
            .then(({ phone }) => {
                window.open(`https://api.whatsapp.com/send?phone=${phone.replaceAll(/\D/g, "")}`, "_blank");
            })
            .finally(() => setLoading(false));
    };

    return <div className="w_100_per display_flex flex_center">
        <Button className="bg_neutral_100" scale="small" onClick={handleClick} disabled={loading}>
            {loading ? (
                <Typography weight="600" scale="medium" scaleMobile="medium" textColor="green_300" className="display_flex flex_align_center">
                    <WhatsApp className="m_r_xs"/> Cargando ...
                </Typography>
            ) : (
                <Typography weight="600" scale="medium" scaleMobile="medium" textColor="green_300" className="display_flex flex_align_center">
                    <WhatsApp className="m_r_xs"/> Contactar a mi asesor
                </Typography>
            )}
        </Button>
    </div>;
};
