import React, { FC } from 'react';
import './styles.scss';
import SectionListItem from './sectionListItem/SectionListItem';
import useStepper from '../Hooks/useStepper';

type labelsProp = {
	label: string;
	point: number;
};
interface Props {
	completed?: boolean;
	labels: labelsProp[];
	className?: string;
}

const SectionList: FC<Props> = ({ completed, labels, className }) => {
	const { currentStep } = useStepper();

	return (
		<div className={`section-list-container ${className}`}>
			{labels.map((section, index) => (
				<SectionListItem
					key={index}
					completed={completed || currentStep >= section.point}
					number={index + 1}
					separator={!(labels.length - 1 <= index)}
					label={section.label}
				/>
			))}
		</div>
	);
};

export default SectionList;
