/* eslint-disable no-shadow */
export enum LeadStepEnum {
    emailStep = "emailStep",
    personalInformationStep = "personalInformationStep",
    addressStep = "addressStep",
    financialInformationStep = "financialInformationStep",
    dependantsStep = "dependantsStep",
    employmentAndEducationStep = "employmentAndEducationStep",
    civilStatusStep = "civilStatusStep",
    assetsStep = "assetsStep",
}

export enum LeadStatusEnum {
    inProgress = "inProgress",
    completed = "completed",
    expired = "expired",
}

export enum LeadCreatedOnEnum {
    webpage = "webpage",
    pandaboard = "pandaboard",
}

export enum LeadFormFieldsEnum {
    EMAIL = "emailStep.email",
    PRIVACY_POLICY_ACCEPTANCE = "emailStep.privacyPolicyAcceptance",
    TERMS_OF_SERVICE_ACCEPTANCE = "emailStep.termsOfServiceAcceptance",

    NAME = "personalInformationStep.name",
    LAST_NAME = "personalInformationStep.lastName",
    CURP = "personalInformationStep.curp",
    PHONE = "personalInformationStep.phone",

    ZIP_CODE = "addressStep.fullAddress.zipCode",
    STATE = "addressStep.fullAddress.state",
    DELEGATION = "addressStep.fullAddress.delegation",
    NEIGHBORHOOD = "addressStep.fullAddress.neighborhood",
    STREET = "addressStep.fullAddress.street",
    INT_NUMBER = "addressStep.fullAddress.intNumber",
    EXT_NUMBER = "addressStep.fullAddress.extNumber",
    LAT = "addressStep.fullAddress.lat",
    LONG = "addressStep.fullAddress.long",
    FULL_ADDRESS = "addressStep.fullAddress",

    MONTHLY_INCOME = "financialInformationStep.monthlyIncome",
    MONTHLY_OUTCOME = "financialInformationStep.monthlyOutcome",

    DEPENDANTS = "dependantsStep.dependantsCount",
    CHILDREN = "dependantsStep.childrenCount",

    WORK_IN_DIGITAL_PLATFORMS = "employmentAndEducationStep.workInDigitalPlatforms",
    ECONOMIC_ACTIVITY = "employmentAndEducationStep.economicActivity",
    ECONOMIC_ACTIVITY_VALUE = "employmentAndEducationStep.economicActivity.value",
    ECONOMIC_ACTIVITY_DESCRIPTION = "employmentAndEducationStep.economicActivity.description",
    COMPANY_NAME = "employmentAndEducationStep.companyName",
    EDUCATION_LEVEL = "employmentAndEducationStep.educationalLevel",

    CIVIL_STATUS = "civilStatusStep.civilStatus",
    LIVES_WITH = "civilStatusStep.livesWith",

    ASSETS = "assetsStep.assets",
}
