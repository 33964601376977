export const types = {
  country: "[CHANGE] country",
  changeCity: "[CHANGE] root site city",

  applicationSetVehicle: "[SET] Application Vehicle",
  applicationSetLoanTime: "[SET] Application Loan Time",

  select: "[SL] update Select search",
  city: "[SL] update Select city search",
  forSellS: "[SL] update Tab VH Select search",

  user: "[US] set user",
  userDt: "[UD] set user data",
  temp: "[UT] set user temp",
  logout: "[UL] set user logout",

  vehiclesFetch: "[VEHICLES]: Fetch vehicles",
  vehiclesFetchSuccess: "[VEHICLES]: Fetch vehicles success",
  vehiclesFetchHomeSuccess: "[VEHICLES]: Fetch home vehicles success",
  vehiclesFetchFail: "[VEHICLES]: Fetch vehicles fail",
  vehiclesFetchUpdateTimestamp: "[VEHICLES]: Fetch vehicles update timestamp",
  vehiclesFetchUpdateCity: "[VEHICLES]: Fetch vehicles update city",
  vehiclesUpdateSelectedFilter: "[VEHICLES]: Update Selected Filter value",
  vehiclesSetSelectedFilter: "[VEHICLES]: Set Selected Filter value",
  vehiclesClearSelectedFilter: "[VEHICLES]: Clear All Selected Filters",
  vehiclesSetSort: "[VEHICLES]: Set sort vehicles",

  recommendedFetch: "[RECOMMENDED]: Fetch recommended",
  recommendedFetchSuccess: "[RECOMMENDED]: Fetch recommended success",
  recommendedFetchFail: "[RECOMMENDED]: Fetch recommended fail",
  recommendedFetchUpdateTimestamp:
    "[RECOMMENDED]: Fetch recommended update timestamp",
  recommendedFetchUpdateCity: "[RECOMMENDED]: Fetch recommended update city",

  financialFormStart: "[FF] Get financial form",
  financialForm: "[FF] Get financial form succeed",
  financialFormUpdateDocumentStart: "[FF] Update financial form document",
  financialFormUpdateDocument: "[FF] Update financial form document succeed",
  financialFormVehicleStart: "[FF] Get financial form vehicle",
  financialFormVehicle: "[FF] Get financial form vehicle succeed",
  financialFormVehicleAvailabilityStart: "[FF] Get vehicle availability for financial form",
  financialFormVehicleAccesibility: "[FF] Get vehicle availability for financial form succeed",
  financialFormRemove: "[FF] Delete financial form",
};
