import { gql } from "@apollo/client";

export const GET_VEHICLES = gql`
  query GetVehicles(
    $status: String!,
    $limit: Float!,
    $page: Float
    $city: String!,
    $brand: String,
    $model: String,
    $year: Float,
    $cylinders: Float,
    $mileages: [Float!],
    $color: String,
    $salePrice: [Float!],
    $weeks: [Float!],
    $isNewVehicle: String,
    $searchText: String,
    $sort: String,
  ) {
    getAllVehicles(
      status: $status
      limit: $limit
      page: $page
      city: $city
      brand: $brand
      model: $model,
      year: $year,
      cylinders: $cylinders,
      mileages: $mileages,
      color: $color,
      salePrice: $salePrice,
      weeks: $weeks,
      isNewVehicle: $isNewVehicle,
      searchText: $searchText,
      sort: $sort,
    ) {
      count
      pages
      page
      filters {
        models
        years
        cc
        colors
        mileageRange
        priceRange
      }
      data {
        internalId
        isNewVehicle
        isPlatedVehicle
        isPresale
        mileage
        year
        cylindersCapacity
        location {
          city {
            name
          }
        }
        discounts {
          status
          value
          type
        }
        activeDiscount {
          type
          value
        }
        salePrices {
          weeks
          paymentWeek
          paymentWeekBeforeDiscount
          percentageIncrease
          capital
          interest
          earn
          loanAmount
          loanAmountBeforeDiscount
        }
        images {
          url
        }
        brand {
          name
        }
        model {
          name
        }
      }
    }
  }
`;
