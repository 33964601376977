/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as Close } from '../../static/icons/close-circle.svg';
import Button from '../Button/Button';

import './modal.scss';

interface ModalProps {
	open: boolean;
	setOpen: Function;
	children: React.ReactNode;
	className?: string;
	onClose?: Function;
}

const Modal: FC<ModalProps> = ({
	open,
	setOpen,
	children,
	className = '',
	onClose,
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const body = (
		<div ref={ref} className={`modalcontainer ${open ? 'open' : ''}`}>
			<div
				className="backdrop"
				onClick={() => setOpen(false)}
				onKeyPress={() => setOpen(false)}
				role="button"
				tabIndex={0}
			/>
			<div
				className={`${className} dso_card bg_neutral_0 w_90_vp_mobile modal p_xxxl display_flex`}
			>
				<div className=".flex_center_col w_100_per">
					<div className="display_flex flex_justify_end button">
						<Button
							variant="icon"
							icon={<Close />}
							subvariant="edit"
							scale="small"
							onClick={() => setOpen(false)}
						/>
					</div>
					<div>{children}</div>
				</div>
			</div>
		</div>
	);

	useEffect(() => {
		if (ref.current) {
			if (ref.current.parentElement?.parentElement) {
				ref.current.parentElement.parentElement.style.overflow = open
					? 'hidden'
					: 'auto';
				if (!open && onClose) {
					onClose();
				}
			}
		}
	}, [open]);

	return createPortal(
		body,
		document.getElementById('modal-root') as HTMLElement,
	);
};

export default Modal;
