/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
import React, { ReactElement, useState } from "react";
import * as yup from "yup";

export const step1 = "startApplication";
export const step2 = "phoneVerification";
export const step3 = "personalInfo";
export const step4 = "deliveryWorkersValidation";
export const step5 = "palencaIntegration";
export const step6 = "incomesVerification";

export interface step {
  component: ReactElement<any, any>;
  name: string;
  title: string;
  modalConfig?: {
    widthDesktop: number;
    heightDesktop: number;
    title?: ReactElement<any, any>;
    component: ReactElement<any, any>;
  },
  button?: {
    text?: string;
    color?: string;
  }
  validationFn?: any;
  complementComponent?: ReactElement<any, any>;
}

export enum StepEnum {
  name = "startApplication.name",
  phone = "startApplication.phone",
  email = "startApplication.email",
  curp = "startApplication.curp",
  address = "startApplication.address",
  zipCode = "startApplication.fullAddress.zipCode",
  state = "startApplication.fullAddress.state",
  delegation = "startApplication.fullAddress.delegation",
  neighborhood = "startApplication.fullAddress.neighborhood",
  street = "startApplication.fullAddress.street",
  extNumber = "startApplication.fullAddress.extNumber",
  intNumber = "startApplication.fullAddress.intNumber",
  lat = "startApplication.fullAddress.lat",
  long = "startApplication.fullAddress.long",
  code = "phoneVerification.code",
  phoneValidated = "phoneVerification.phoneValidated",
  authDataTreatment = "phoneVerification.authDataTreatment",
  buroAcceptance = "phoneVerification.buroAcceptance",
  buroScore = "personalInfo.buroScore",
  dependantsCount = "personalInfo.dependantsCount",
  livesWith = "personalInfo.livesWith",
  assets = "personalInfo.assets",
  workInDigitalPlatforms = "deliveryWorkersValidation.workInDigitalPlatforms",
  economicActivity = "deliveryWorkersValidation.economicActivity.value",
  otherEconomicActivity = "deliveryWorkersValidation.economicActivity.description",
  palenca = "palencaIntegration.palenca",
  checksIncomes = "incomesVerification.checksIncomes",
}

export interface StepFormValues {
  [step1]:{
    name?: string;
    phone?: string;
    email?: string;
    curp?: string;
    address?: string;
    fullAddress?: {
      zipCode: string;
      state: string;
      delegation: string;
      neighborhood: string;
      street: string;
      intNumber?: number;
      extNumber?: number;
      lat?: number;
      long?: number;
    }
  },
  [step2] : {
    code?: string;
    phoneValidated?: boolean;
    authDataTreatment?: boolean;
    buroAcceptance?: boolean;
  },
  [step3] : {
    buroScore?: number;
    dependantsCount?: number;
    livesWith?: string[];
    assets?: string[];
  },
  [step4] : {
    workInDigitalPlatforms?: boolean;
    economicActivity?: {
      value: string;
      description?: string;
    };
    otherEconomicActivity?: string;
  },
  [step5] : {
    palenca?: boolean;
  },
  [step6] : {
    checksIncomes?: boolean;
  },
};

export const initialValues: StepFormValues = {
  [step1]: {
    name: undefined,
    phone: undefined,
    email: undefined,
    curp: undefined,
    address: undefined,
    fullAddress: undefined,
  },
  [step2] : {
    code: undefined,
    phoneValidated: undefined,
    authDataTreatment: undefined,
    buroAcceptance: undefined,
  },
  [step3] : {
    buroScore: undefined,
    dependantsCount: undefined,
    livesWith: undefined,
    assets: undefined,
  },
  [step4] : {
    workInDigitalPlatforms: true,
    economicActivity: undefined,
  },
  [step5] : {
    palenca: undefined,
  },
  [step6] : {
    checksIncomes: true,
  },
};

const curpRegex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

export const validationSchema = yup.object().shape({
  [step1]: yup.object().shape({
    name: yup
      .string()
      .required("Campo requerido"),
    phone: yup
      .string()
      .required("Campo requerido")
      .matches(/^!*(\d!*){10,}$/, "Numero invalido"),
    email: yup
      .string()
      .email("Ingrese un correo valido")
      .required("Campo requerido"),
    curp: yup
      .string()
      .required("Campo requerido")
      .matches(curpRegex, "CURP inválido"),
    address: yup
      .string()
      .required("Campo requerido"),
    fullAddress: yup.object().shape({
      zipCode: yup
        .string()
        .matches(/^\d{5}$/, "Debe ser un número de 5 dígitos."),
      lat: yup.number(),
      long: yup.number(),
    }),
  }),
  [step2]: yup.object().shape({
    code: yup
      .string()
      .required("Campo requerido"),
    authDataTreatment: yup
    .bool()
    .required("Campo requerido")
    .test(
        "is-true",
        "El valor debe ser siempre true",
        (value) => value === true
    ),
    buroAcceptance: yup
    .bool()
    .required("Campo requerido")
    .test(
        "is-true",
        "El valor debe ser siempre true",
        (value) => value === true
    ),
  }),
  [step3]: yup.object().shape({
    buroScore: yup
      .number()
      .required("Campo requerido"),
    dependantsCount: yup
      .number()
      .required("Campo requerido"),
    livesWith: yup.array().of(yup.string()).min(1, "Al menos uno requerido"),
    assets: yup.array().of(yup.string()),
  }),
  [step4]: yup.object().shape({
    workInDigitalPlatforms: yup.bool(),
    economicActivity: yup.object().shape({
      value: yup.string().required("Campo requerido"),
      description: yup.string().test("", "Campo requerido", function (value) {
          if (this.parent.value === "otro") { return value ? value?.trim().length > 0 : false; }
          return true;
      }),
    }),
  }),
  [step5]: yup.object().shape({
    palenca: yup.bool(),
  }),
  [step6]: yup.object().shape({
    checksIncomes: yup.bool(),
  }),
});

export const validateStep = (
  step: string,
  values: StepFormValues
) => yup.reach(validationSchema, step).isValidSync(values[step as keyof StepFormValues]);
