/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { FormikProvider, useFormik } from "formik";
import useLocalStorage from "hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import Grid from "@mui/material/Grid";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Typography, Input, Button } from "design_system/src";
import { ReactComponent as Invoice } from "design_system/src//static/icons/invoice.svg";
import { ReactComponent as Mobile } from "design_system/src/static/icons/mobile-2.svg";
import { ReactComponent as Note } from "design_system/src/static/icons/note.svg";
import { ReactComponent as Bill } from "design_system/src/static/icons/bill.svg";
import { ReactComponent as SuccessPlan } from "design_system/src/static/icons/success-plan.svg";

import UploadInput from "views/ozonFly/uploadInput/UploadInput";
import ResetButton from "components/ResetButton/ResetButton";

import { initialValues, StepEnum, StepFormValues, validationSchema, validateStep, step1 } from "./constants";

import "./LoanApplicationIncomes.scss";

const LoanApplicationIncomes = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [storedValue, setStoredValue] = useLocalStorage<any>("loanApplication", null);
  const [completed, setCompleted] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (storedValue === null && !loading) {
      navigate("/solicitud-credito");
    } if (storedValue !== null && storedValue.loanApplicationIncomes && !loading) {
      navigate("/solicitud-credito/validacion-ingresos-aprobada");
    } else {
      setLoading(false);
    }
  }, [storedValue, loading]);

  const onSubmitForm = (values: any) => {
    setCompleted(true);
  };

  const formik = useFormik<StepFormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onSubmitForm(values);
    },
    enableReinitialize: true,
  });

  const { values, errors } = formik;

  const isValid = () => {
    return validateStep(step1, values);
  };

  const formatNumber = (num: Number) => {
    return `$${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    let rawValue = Number(event.target.value.replace(/,/g, "").replace("$", ""));
    if (!rawValue) {
      rawValue = 0;
    }
    setValue(rawValue);
    formik.setFieldValue(StepEnum.incomes, rawValue);
  };

  const onContinue = () => {
    setStoredValue({
      ...storedValue,
      ...values,
      loanApplicationIncomes: true
    });
  };

  return (
    <div className="dso_container" style={{ minHeight: "75vh" }}>
      <div className="m_y_lg_mobile m_y_xxxl_desktop m_x_auto w_800_px_desktop">
        {!completed && <>
          <Typography className="m_y_none m_b_md text_center" weight="600" scale="heading3" scaleMobile="heading4" component="h1">
            Confirma tu información financiera
          </Typography>
          <Typography className="m_y_none m_b_md text_center" weight="400" scale="medium" scaleMobile="medium" component="p">
            Ahora necesitamos un último paso: por favor, sube los comprobantes de tus ingresos y dinos cuánto ganas para validar la información y seguir avanzando.
          </Typography>
          <FormikProvider value={formik}>
            <div className="animate__animated animate__fadeIn p_y_xl">
              <div className="w_400_px_desktop m_x_auto">
                <Input
                  type="text"
                  title="¿Cuánto ganas?"
                  name={StepEnum.incomes}
                  onChange={handleInputChange}
                  icon={<SuccessPlan className="primary_300" />}
                  onBlur={formik.handleBlur}
                  value={value === 0 ? "" : formatNumber(value)}
                  error={
                    !!_.get(errors, StepEnum.incomes) &&
                    formik.getFieldMeta(StepEnum.incomes).touched
                  }
                  subtitle={
                    formik.getFieldMeta(StepEnum.incomes).touched
                      ? (_.get(errors, StepEnum.incomes) as string)
                      : undefined
                  }
                />
              </div>
              {storedValue.palencaIntegration.palenca && <Grid container>
                <Grid item md={4} xs={12} className="p_lg m_x_auto">
                  <UploadInput
                    onChange={() => {}}
                    accept="application/pdf"
                    title="Afiliación"
                    value={_.get(values, StepEnum.delivery_affiliation) as any}
                    name={StepEnum.delivery_affiliation}
                    placeholderIcon={<Invoice className="dim_xl" />}
                    error={
                      _.get(errors, StepEnum.delivery_affiliation) &&
                      formik.getFieldMeta(StepEnum.delivery_affiliation).touched
                        ? (_.get(errors, StepEnum.delivery_affiliation) as string)
                        : ""
                    }
                  />
                </Grid>
              </Grid>}
              {!storedValue.palencaIntegration.palenca && <>
                <div className="m_y_md m_t_xxl border_solid border_neutral_600 border_0 border_t_1 p_y_xxl">
                  <Typography className="m_y_none m_b_md text_center" weight="400" scale="small" scaleMobile="small" component="p">
                    Sube tus comprobantes:
                  </Typography>
                  <div className="checksIncomes display_flex">
                    <div className="checksIncomes-card">
                      <Note className="checksIncomes-icon" />
                      <Typography className="m_y_none m_b_md text_center" weight="400" scale="small" scaleMobile="small" component="p">
                        Recibos de nómina
                      </Typography>
                      <div className="display_flex flex_align_center flex_justify_center  ">
                        <div className="bg_primary_300 br_circle dim_10_px" />
                        <Typography
                          weight="400"
                          scale="small"
                          className="m_l_sm"
                        >
                          {"Los últimos 6 "}
                          <Typography weight="600" scale="small" className="" component="span">
                            {"si el pago es quincenal "}
                          </Typography>
                        </Typography>
                      </div>
                      <div className="display_flex flex_align_center flex_justify_center  ">
                        <div className="bg_primary_300 br_circle dim_10_px" />
                        <Typography
                          weight="400"
                          scale="small"
                          className="m_l_sm"
                        >
                          {"Los últimos 3 recibos "}
                          <Typography weight="600" scale="small" className="" component="span">
                            {"si el pago es mensual "}
                          </Typography>
                        </Typography>
                      </div>
                    </div>
                    <div className="checksIncomes-card">
                      <Bill className="checksIncomes-icon" />
                      <Typography className="m_y_none m_b_md text_center" weight="400" scale="small" scaleMobile="small" component="p">
                        Estados de cuenta
                      </Typography>
                      <div className="display_flex flex_align_center flex_justify_center  ">
                        <div className="bg_primary_300 br_circle dim_10_px" />
                        <Typography
                          weight="400"
                          scale="small"
                          className="m_l_sm"
                        >
                          {"Los PDFs completos "}
                          <Typography weight="600" scale="small" className="" component="span">
                            {"de los últimos 3 meses "}
                          </Typography>
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
                <Grid container>
                  <Grid item md={4} xs={12} className="p_lg">
                    <UploadInput
                      onChange={() => {}}
                      accept="application/pdf"
                      title="1er Comprobante"
                      value={_.get(values, StepEnum.bank_certificate_1) as any}
                      name={StepEnum.bank_certificate_1}
                      placeholderIcon={<Invoice className="dim_xl" />}
                      error={
                        _.get(errors, StepEnum.bank_certificate_1) &&
                        formik.getFieldMeta(StepEnum.bank_certificate_1).touched
                          ? (_.get(errors, StepEnum.bank_certificate_1) as string)
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12} className="p_lg m_y_md_mobile">
                    <UploadInput
                      onChange={() => {}}
                      accept="application/pdf"
                      title="2do Comprobante"
                      value={_.get(values, StepEnum.bank_certificate_2) as any}
                      name={StepEnum.bank_certificate_2}
                      placeholderIcon={<Invoice className="dim_xl" />}
                      error={
                        _.get(errors, StepEnum.bank_certificate_2) &&
                        formik.getFieldMeta(StepEnum.bank_certificate_2).touched
                          ? (_.get(errors, StepEnum.bank_certificate_2) as string)
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12} className="p_lg">
                    <UploadInput
                      onChange={() => {}}
                      accept="application/pdf"
                      title="3er Comprobante"
                      value={_.get(values, StepEnum.bank_certificate_3) as any}
                      name={StepEnum.bank_certificate_3}
                      placeholderIcon={<Invoice className="dim_xl" />}
                      error={
                        _.get(errors, StepEnum.bank_certificate_3) &&
                        formik.getFieldMeta(StepEnum.bank_certificate_3).touched
                          ? (_.get(errors, StepEnum.bank_certificate_3) as string)
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12} className="p_lg">
                    <UploadInput
                      onChange={() => {}}
                      accept="application/pdf"
                      title="4to Comprobante"
                      value={_.get(values, StepEnum.bank_certificate_4) as any}
                      name={StepEnum.bank_certificate_4}
                      placeholderIcon={<Invoice className="dim_xl" />}
                      error={
                        _.get(errors, StepEnum.bank_certificate_4) &&
                        formik.getFieldMeta(StepEnum.bank_certificate_4).touched
                          ? (_.get(errors, StepEnum.bank_certificate_4) as string)
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12} className="p_lg m_y_md_mobile">
                    <UploadInput
                      onChange={() => {}}
                      accept="application/pdf"
                      title="5to Comprobante"
                      value={_.get(values, StepEnum.bank_certificate_5) as any}
                      name={StepEnum.bank_certificate_5}
                      placeholderIcon={<Invoice className="dim_xl" />}
                      error={
                        _.get(errors, StepEnum.bank_certificate_5) &&
                        formik.getFieldMeta(StepEnum.bank_certificate_5).touched
                          ? (_.get(errors, StepEnum.bank_certificate_5) as string)
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12} className="p_lg">
                    <UploadInput
                      onChange={() => {}}
                      accept="application/pdf"
                      title="6to Comprobante"
                      value={_.get(values, StepEnum.bank_certificate_6) as any}
                      name={StepEnum.bank_certificate_6}
                      placeholderIcon={<Invoice className="dim_xl" />}
                      error={
                        _.get(errors, StepEnum.bank_certificate_6) &&
                        formik.getFieldMeta(StepEnum.bank_certificate_6).touched
                          ? (_.get(errors, StepEnum.bank_certificate_6) as string)
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </>}


              <Button
                className="w_100_per"
                orientation="right"
                scale="small"
                icon={<ArrowForwardIcon />}
                disabled={!isValid()}
                onClick={formik.submitForm}
              >
                Finalizar
              </Button>
            </div>
          </FormikProvider>
        </>}
        {completed && <div className="m_y_md m_x_auto w_400_px_desktop">
          <Typography className="m_y_none m_b_md text_center" weight="600" scale="heading3" scaleMobile="heading4" component="h1">
            ¡Formulario completado!
          </Typography>
          <Typography className="m_y_none m_b_md text_center" weight="400" scale="medium" scaleMobile="medium" component="p">
            ¡Buen trabajo! Muy pronto te estaremos contactando sobre la aprobación de tu couta máxima.
          </Typography>
          <br />
          <br />
          <br />
          <br />
          <div className="m_t_xxl display_flex flex_justify_center">
            <Button
              scale="small"
              onClick={onContinue}
              variant="ghost"
            >Recibir respuesta aprobado</Button>
          </div>
        </div>}
      </div>
      <ResetButton />
    </div>
  );
};

export default LoanApplicationIncomes;
