import { Cities } from "store/reducers/cityReducer";
import { VehicleStatusEnum } from "../enums/vehicle.enums";
import { BaseModel } from "./model.interface";
import { Brand } from "./brand.interface";
import { getQuotaVehicle } from "../controllers/vehicle.controller";

export interface IColorOptions {
  value: string;
  label: string;
}

export interface VehiclesFilters {
  brands: string[];
  cylinder_Capacities: number[];
  years: number[];
  city: Cities;
  mileageRange?: string[]
  priceRange?: string[]
  sort?: string;
  colors?: IColorOptions[];
  ranges?: number[];
  models?: string[];
  limit: number;
  weeks?: number[];
}

export const colorOptions: IColorOptions[] = [
  { value: "pink", label: "Rosa" },
  { value: "purple", label: "Morado" },
  { value: "blue", label: "Azul" },
  { value: "orange", label: "Anaranjado" },
  { value: "green", label: "Verde" },
  { value: "white", label: "Blanco" },
  { value: "gray", label: "Gris" },
  { value: "red", label: "Rojo" },
  { value: "black", label: "Negro" },
  { value: "yellow", label: "Amarillo" },
  { value: "graphite", label: "Grafito" },
];

export type TImages = {
  _id: string;
  name: string;
  url: string;
  size?: number;
  path?: string;
  hash?: string;
  ext?: string;
  mimeType?: string;
  width?: number;
  height?: number;
  formats: {
    _id: string;
    thumbnail: {
      _id: string;
      name?: string;
      url: string;
      size: number;
      path: string;
      hash: string;
      ext: string;
      mimeType: string;
      width: number;
      height: number;
    };
    small: {
      _id: string;
      name?: string;
      url: string;
      size: number;
      path: string;
      hash: string;
      ext: string;
      mimeType: string;
      width: number;
      height: number;
    };
  };
};

type TCity = {
  _id: string;
  name: string;
};

type TCountry = {
  _id: string;
  deliveryPrice: number;
  insurance: number;
  deposit: number;
  name: string;
  iso: string;
  currency: string;
  tax: number;
  tax_name: string;
};

export interface TDiscount {
  type: "value" | "percentage";
  value: number;
  createdAt: string;
  id: string;
  status: "active" | "inactive";
}

interface THub {
  _id: string;
  name: string;
}

interface ILocation {
  hub: THub;
  city: TCity;
  country: TCountry;
}

export const statusVehicles = {
  available: {
    title: "Disponible",
    color: "green_300",
  },
  delivered: {
    title: "Entregada",
    color: "primary_200",
  },
  aside: {
    title: "Apartada",
    color: "secondary_500",
  },
};

export interface IDetailsData {
  year: string;
  mileage: string;
  color: string;
  secondaryColor: string;
  yellow: string;
  pink: string;
  purple: string;
  blue: string;
  orange: string;
  green: string;
  white: string;
  gray: string;
  red: string;
  black: string;
  brakeType: string;
  disc: string;
  drum: string;
}

export const detailsData: IDetailsData = {
  year: "Año",
  mileage: "Kilometraje",
  color: "Color",
  disc: "Disco",
  drum: "Tambor",
  secondaryColor: "Color secundario",
  yellow: "Amarillo",
  pink: "Rosa",
  purple: "Morado",
  blue: "Azul",
  orange: "Naranja",
  green: "Verde",
  white: "Blanco",
  gray: "Gris",
  red: "Rojo",
  black: "Negro",
  brakeType: "Tipo de frenos",
};

export interface ISalePrices {
  weeks: number;
  paymentWeek: number;
  paymentWeekBeforeDiscount: number | null;
  percentageIncrease: number;
  capital: number;
  interest: number;
  loanAmount: number;
  loanAmountBeforeDiscount: number | null;
  earn: number;
}

export interface IExtraCosts {
  basicExtraCost: number;
  repairsCost: number;
  debtsCost: number;
  gpsHardwareCost: number;
  gpsMonthlySoftwareCost: number;
}

export interface ITVehicle {
  _id: string;
  internalId: string;
  location: ILocation;
  isNewVehicle?: boolean;
  isPlatedVehicle?: boolean;
  isPresale?: boolean;
  status?: VehicleStatusEnum;
  suffix?: string;
  discounts?: TDiscount[];
  salePrices: ISalePrices[];
  engineSN: string;
  registrationCard?: string;
  plate?: string;
  score?: number;
  type?: string;
  owner?: string;
  vehicleSN: string;
  purchaseCost: number;
  color: string;
  purchaseDate?: string;
  year: number;
  mileage: number;
  images?: TImages[];
  documents?: any[];
  brand: Brand;
  model: BaseModel;
  cylindersCapacity: number;
  brakeType?: string;
  secondaryColor?: string;
  shortDescription?: string;
  description?: string;
  extraCosts: IExtraCosts;
  marketPrice: number;
  hasTileDevice: boolean;
  maintenanceDays: number;
  visualStatus: any;
  documentalStatus: any;
  maintenanceColor: string;
  activeDiscount: TDiscount | null;
  hasGpsTracker?: boolean;
}

export class TVehicle implements ITVehicle {
  _id: string;
  internalId: string;
  location: ILocation;
  isNewVehicle?: boolean;
  isPlatedVehicle?: boolean;
  isPresale?: boolean;
  status?: VehicleStatusEnum;
  suffix?: string;
  discounts?: TDiscount[];
  salePrices: ISalePrices[];
  engineSN: string;
  registrationCard?: string;
  plate?: string;
  score?: number;
  type?: string;
  owner?: string;
  vehicleSN: string;
  purchaseCost: number;
  color: string;
  purchaseDate?: string;
  year: number;
  mileage: number;
  images?: TImages[];
  documents?: any[];
  brand: Brand;
  model: BaseModel;
  cylindersCapacity: number;
  brakeType?: string;
  secondaryColor?: string;
  advancedMoney: number;
  shortDescription?: string;
  description?: string;
  extraCosts: IExtraCosts;
  marketPrice: number;
  hasTileDevice: boolean;
  maintenanceDays: number;
  visualStatus: any;
  documentalStatus: any;
  maintenanceColor: string;
  activeDiscount: TDiscount | null;
  hasGpsTracker?: boolean;

  constructor(data: ITVehicle) {
    this._id = data._id;
    this.internalId = data.internalId;
    this.location = data.location;
    this.isNewVehicle = data.isNewVehicle;
    this.isPlatedVehicle = data.isPlatedVehicle;
    this.isPresale = data.isPresale;
    this.status = data.status;
    this.discounts = data.discounts;
    this.suffix = data.suffix;
    this.salePrices = data.salePrices?.filter(({ weeks }) => weeks !== 1);
    this.engineSN = data.engineSN;
    this.registrationCard = data.registrationCard;
    this.plate = data.plate;
    this.type = data.type;
    this.owner = data.owner;
    this.vehicleSN = data.vehicleSN;
    this.purchaseCost = data.purchaseCost;
    this.color = data.color;
    this.purchaseDate = data.purchaseDate;
    this.year = data.year;
    this.mileage = data.mileage;
    this.images = data.images;
    this.documents = data.documents;
    this.brand = data.brand;
    this.model = data.model;
    this.cylindersCapacity = data.cylindersCapacity;
    this.brakeType = data.brakeType;
    this.secondaryColor = data.secondaryColor;
    this.advancedMoney = 0;
    this.shortDescription = data.shortDescription;
    this.description = data.description;
    this.extraCosts = data.extraCosts;
    this.marketPrice = data.marketPrice;
    this.hasTileDevice = data.hasTileDevice;
    this.maintenanceDays = data.maintenanceDays;
    this.visualStatus = data.visualStatus;
    this.documentalStatus = data.documentalStatus;
    this.maintenanceColor = data.maintenanceColor;
    this.activeDiscount = data.activeDiscount;
    this.hasGpsTracker = data.hasGpsTracker;
  }

  hasDiscount(): boolean {
    return this.activeDiscount?.value !== undefined && this.activeDiscount?.value > 0;
  }

  getSalePriceItem(weeks: number): ISalePrices | null {
    return this.salePrices.filter((item) => item.weeks === weeks)[0] ?? null;
  };

  getWeeklyPriceWithoutDiscount(weeks: number = 78): number {
    const found = this.getSalePriceItem(weeks);
    return found?.paymentWeekBeforeDiscount ?? 0;
  }

  getWeeklyPrice(weeks: number = 78): number {
    const found = this.getSalePriceItem(weeks);
    if (found) return found.paymentWeek;
    return 0;
  }

  async getQuotaValue(weeks: number, advancedMoney: number): Promise<number> {
    const found = this.getSalePriceItem(weeks);
    if (found) {
      return (await getQuotaVehicle(this.internalId, weeks, advancedMoney)).paymentWeek;
    }
    return 0;
  }

  setAdvancedMoney(money: number) {
    this.advancedMoney = money;
  }
}

export interface ListVehicles {
  filters: Partial<VehiclesFilters>;
  vehicles: TVehicle[];
  homeVehicles: TVehicle[];
  homeBrands: string[];
  page: number;
  pages: number;
  count: number;
}

export interface GetVehiclesResponse {
  vehicles: TVehicle[];
  filters: Omit<VehiclesFilters, "city">;
}
