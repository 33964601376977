/* eslint-disable no-shadow */
export enum CivilStatusEnum {
    single = "Solter@",
    married = "Casad@",
    Cohabitation = "Union Libre",
    divorced = "Divorciad@",
    widower = "Viud@",
}

export enum EducationLevelEnum {
    primary = "Primaria",
    secondary = "Secundaria",
    HighSchool = "Preparatoria",
    AssociateDegree = "Tecnicatura",
    BachelorDegree = "Licenciatura",
    MasterDegree = "Maestria",
}

export enum LivesWithEnum {
    alone = "Solo",
    spouse = "Esposo (a)",
    friends = "Amigos",
    parents = "Padres",
    couple = "Pareja Estable",
    Siblings = "Hermanos",
    children = "Hijos",
}
