import _ from "lodash";
import { IOzonerGeolocation } from "helpers/getOzonerLocation";
import { PandaboardApiInstance } from "../../../helpers/api";
import { OzonerDocumentRequest } from "../interfaces/ozoner.interface";

export const fetchOzonerGeolocation = async (coords: IOzonerGeolocation) =>
  PandaboardApiInstance.post("ozoner/geolocation", coords, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchOzonerAddDocument = async ({
  ozonerId,
  formData,
  documentName,
}: OzonerDocumentRequest) =>
  PandaboardApiInstance.put(`ozoner/wp/${ozonerId}/${documentName}`, formData, {
    requireAuth: true,
  })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchOzonerDeleteDocument = async ({
  ozonerId,
  documentId,
}: OzonerDocumentRequest) =>
  PandaboardApiInstance.delete(`ozoner/wp/${ozonerId}/document/${documentId}`, {
    requireAuth: true,
  })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });
