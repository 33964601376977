/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-return */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Swipe from 'react-easy-swipe';
import './styles.scss';
import useWindowSize from '../../../hooks/useWindowSize';
import useStepper from '../Hooks/useStepper';

interface StepperStepsProps {
	swipeable?: boolean;
	onSwiping?: (value: boolean) => void;
}

// eslint-disable-next-line func-names
export const StepperSteps: React.FC<StepperStepsProps> = function ({
	children,
	onSwiping,
	swipeable = false,
}) {
	const {
		currentStep,
		steps,
		setSteps,
		incrementCurrentStep,
		decrementCurrentStep,
	} = useStepper();
	const { width: windowWidth, height: windowHeight } = useWindowSize();
	const [width, setWidth] = useState<string | number | undefined>('100%');
	const [height, setHeight] = useState<string | number | undefined>('100%');
	const [swipeMoveOffset, setSwipeMoveOffset] = useState<number>(0);
	const [swiping, setSwiping] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>(null);

	const onSwipeStart = useCallback(() => {
		if (!swipeable) return;
	}, [swipeable]);

	const onSwipeLeft = useCallback(() => {
		if (!swipeable) return;
		incrementCurrentStep();
	}, [incrementCurrentStep, swipeable]);

	const onSwipeRight = useCallback(() => {
		if (!swipeable) return;
		decrementCurrentStep();
	}, [decrementCurrentStep, swipeable]);

	const onSwipeMove = useCallback(
		(position) => {
			if (!swipeable) return;
			setSwiping(true);
			setSwipeMoveOffset(-1 * position.x);
			return true;
		},
		[swipeable],
	);

	const onSwipeEnd = useCallback(() => {
		if (!swipeable) return;
		setSwipeMoveOffset(0);
		setSwiping(false);
	}, [swipeable]);

	useEffect(() => {
		if (ref.current) {
			setWidth(ref.current.parentElement?.clientWidth);
		}
	}, [steps, currentStep, windowWidth, windowHeight]);

	useEffect(() => {
		if (ref.current && ref.current.children.length) {
			// @ts-ignore
			setHeight(ref.current.children[currentStep]?.childNodes[0]?.clientHeight);
		}
	}, [steps, currentStep, windowHeight, windowWidth]);

	useEffect(() => {
		const stepperSteps = React.Children.toArray(children)
			.filter((step: any) => step.props.__TYPE === 'Step')
			.map((step: any) => step.props);
		setSteps(stepperSteps);
	}, [children, setSteps]);

	useEffect(() => {
		if (onSwiping) {
			onSwiping(swiping);
		}
	}, [swiping, onSwiping]);

	const offset =
		currentStep * Number(width) + swipeMoveOffset >
		(steps.length - 1) * Number(width)
			? (steps.length - 1) * Number(width)
			: currentStep * Number(width) + swipeMoveOffset;

	const stepperStepsWidth = Number(width) * steps.length;
	return (
		<Swipe
			onSwipeStart={onSwipeStart}
			onSwipeMove={onSwipeMove}
			onSwipeEnd={onSwipeEnd}
			onSwipeLeft={onSwipeLeft}
			onSwipeRight={onSwipeRight}
			allowMouseEvents
			tolerance={32}
		>
			<div
				ref={ref}
				className={`stepper-steps ${swiping ? 'swiping' : ''}`}
				style={{
					width: `${steps.length ? `${stepperStepsWidth}px` : '100%'}`,
					height,
					transform: `translateX(-${offset}px)`,
				}}
			>
				{children}
			</div>
		</Swipe>
	);
};
