/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import * as yup from "yup";

export const step1 = "validation";
export const step2 = "reservationReceipt";
export const step3 = "schedule";
export const step4 = "deliveryDocuments";

export enum StepEnum {
    ACCEPTED_VALIDATION="validation.accepted",
    RESERVATION_RECEIPT="reservationReceipt.layaway_receipt",
    SCHEDULE_DATE="schedule.scheduled_date",
    FISCAL_SITUATION="deliveryDocuments.fiscal_situation",
    DEPOSIT="deliveryDocuments.deposit",
    ADVANCED_MONEY="deliveryDocuments.advanced_money",
    FIRST_PAYMENT="deliveryDocuments.first_payment",
}

export interface StepFormValues {
    [step1]: {
        accepted?: boolean;
    };
    [step2]: {
        layaway_receipt?: DocumentType;
    },
    [step3]: {
        scheduled_date?: Date;
    };
    [step4]: {
        fiscal_situation?: DocumentType;
        deposit?: DocumentType;
        advanced_money?: DocumentType;
        first_payment?: DocumentType;
    };
}

export const SUPPORTED_FORMATS = ["image/jpg", "image/png", "image/jpeg"];

export const validationSchema = yup.object().shape({
    [step1]: yup.object().shape({
        accepted: yup
            .bool()
            .required("Campo requerido")
            .test(
                "is-true",
                "El valor debe ser siempre true",
                (value) => value === true
            ),
    }),
    [step2]: yup.object().shape({
        layaway_receipt: yup.mixed(),
    }),
    [step3]: yup.object().shape({
        scheduled_date: yup.date().required("Campo requerido"),
    }),
    [step4]: yup.object().shape({}),
});

export const initialValues: StepFormValues = {
    [step1]: {
        accepted: undefined,
    },
    [step2]: {
        layaway_receipt: undefined,
    },
    [step3]: {
        scheduled_date: undefined,
    },
    [step4]: {
        fiscal_situation: undefined,
        deposit: undefined,
        advanced_money: undefined,
        first_payment: undefined,
    }
};

export const validateStep = (
    step: string,
    values: StepFormValues
) => yup.reach(validationSchema, step).isValidSync(values[step as keyof StepFormValues]);
