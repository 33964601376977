/* eslint-disable default-param-last */
import { TVehicle } from "models/inventory/interfaces/vehicle.interface";
import { EvidenceDocument } from "models/shared/interfaces/shared.interface";
import { TFinancialForm } from "models/financialForm/interfaces/financialForm.interface";
import { types } from "../types/types";

export interface FinancialFormState {
  financialForm: TFinancialForm | null;
  vehicle: TVehicle | null;
  vehicleAvailability: boolean,
  loading: boolean,
  documentLoading: boolean,
  vehicleLoading: boolean,
  documents: EvidenceDocument[]
}

export const initialState: FinancialFormState = {
  financialForm: null,
  vehicle: null,
  vehicleAvailability: false,
  loading: false,
  documentLoading: false,
  vehicleLoading: false,
  documents: []
};

export const financialFormReducer = (
  state: FinancialFormState = initialState,
  action: any
) => {
  switch (action.type) {
    case types.financialFormStart:
      return {
        ...state,
        loading: true
      };
    case types.financialForm:
      return {
        ...state,
        loading: false,
        financialForm: action.payload.financialForm
      };
    case types.financialFormUpdateDocumentStart:
      return {
        ...state,
        documentLoading: true
      };
    case types.financialFormUpdateDocument:
      return {
        ...state,
        documentLoading: false,
        documents: action.payload.documents
      };
    case types.financialFormVehicleStart:
      return {
        ...state,
        vehicleLoading: true
      };
    case types.financialFormVehicle:
      return {
        ...state,
        vehicleLoading: false,
        vehicle: action.payload.vehicle
      };
    case types.financialFormVehicleAvailabilityStart:
      return {
        ...state,
        vehicleLoading: true
      };
    case types.financialFormVehicleAccesibility:
      return {
        ...state,
        vehicleLoading: false,
        vehicleAvailability: action.payload.vehicleAvailability
      };
    case types.financialFormRemove:
      return initialState;
    default:
      return state;
  }
};
