import { ApolloClient, InMemoryCache } from "@apollo/client";
import { VehiclesFilters } from "models/inventory/interfaces/vehicle.interface";
import { GET_VEHICLES } from "queries/getVehicles";

export const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_BASE_URL_PA}graphql`,
  cache: new InMemoryCache(),
});

// eslint-disable-next-line consistent-return
export const fetchVehiclesGraphql = async (filters: Partial<VehiclesFilters>) => {
    try {
      const { data } = await client.query({
        query: GET_VEHICLES,
        variables: {
          status: "available",
          ...filters
        }
    });
      return data;
    } catch (error) {
      console.log(error);
    }
  };
