import React, { FC } from 'react';
import './styles.scss';

interface Props {}

const StepperFooter: FC<Props> = ({ children }) => (
	<div className="stepper-footer m_y_md">{children}</div>
);

export default StepperFooter;
