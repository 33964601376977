/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import logoc from "static/logos/logoc.svg";
import { useDispatch } from "react-redux";

// design
import "./navbar.scss";

// imgs
import { ReactComponent as HamMenu } from "design_system/src/static/icons/hamburger-menu.svg";
import { ReactComponent as Money } from "design_system/src/static/icons/money.svg";

import { Button } from "design_system/src";
import {
  clearApplicationLoanTime,
  clearApplicationVehicle,
} from "store/actions/application";
import { Cities } from "../../../store/reducers/cityReducer";

type NavbarProps = {
  setOpenCityModal: Function;
};
export const Navbar: React.FC<NavbarProps> = ({ setOpenCityModal }) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const [sideBar, setSideBar] = useState<boolean>(false);
  const [url, setURL] = useState("");

  const paths = [
    {
      path: "/catalogo",
      label: "Compra tu moto",
    },
    {
      path: "/vende-tu-moto",
      label: "Vende tu moto",
    },
    {
      path: "/faqs",
      label: "Ayuda",
    },
  ];

  useEffect(() => {
    setURL(location.pathname);
  }, [location.pathname]);

  function toggleSideBar() {
    setSideBar(!sideBar);
  }

  function handleSidebar(selectedSection: string) {
    if (selectedSection === "/vende-tu-moto") {
      // analytics event
      ReactGA.event("CTA_Header_sell_bike", {
        category: "Ozocio",
        label: "click in sell your bike btn in web header view",
      });
    } else if (selectedSection === "/faqs") {
      ReactGA.event("CTA_navbar_FAQS", {
        category: "Support",
        label: "click faqs from navbar to FAQS view",
      });
    } else if (selectedSection === "/catalogo") {
      ReactGA.event("CTA_catalog_navbar", {
        category: "Marketplace",
        label: "click catalog from navbar to catalog view",
      });
    } else {
      ReactGA.event("CTA_navbar_icon", {
        category: "Support",
        label: "click ozon´s icon button to home view (/)",
      });
    }
  }

  const city = localStorage.getItem("city");

  useEffect(() => {
    if (city !== Cities.CDMX) {
      localStorage.setItem("city", Cities.CDMX);
    }
  }, [city]);

  const goLoan = () => {
    dispatch(clearApplicationLoanTime());
    dispatch(clearApplicationVehicle());
  };

  return (
    <div
      className="pos_fixed display_block"
      style={{ zIndex: 999, width: "100%" }}
    >
      <header className="navbar shadow_medium ">
        <div className="main-container">
          <div
            className="display_flex"
            style={{ justifyContent: "start", alignItems: "center" }}
          >
            <Link to="/" className="custom_a">
              <figure className="m_none">
                <img src={logoc} alt="Logo Ozon" className="h_xl " />
              </figure>
            </Link>
          </div>

          <Button
            className="display_none_desktop"
            variant="icon"
            scale="small"
            subvariant="edit"
            icon={<HamMenu />}
            onClick={toggleSideBar}
          />
          <div
            className={`backdrop display_none_desktop ${sideBar ? "open" : ""}`}
            onClick={toggleSideBar}
            tabIndex={0}
          >
            <div className="sidebar" tabIndex={1}>
              <Link to="/" className="custom_a">
                <figure className="m_none">
                  <img
                    src={logoc}
                    alt="Logo Ozono"
                    className="m_y_xxxl center_x h_xl"
                  />
                </figure>
              </Link>

              <nav className="flex_center_col flex_align_start p_xxl ">
                <div
                  className="dim_100_per flex_center flex_col m_b_xs p_b_xl w_100_per"
                  style={{ borderBottom: "1px solid #E0E3E5" }}
                >
                  <Link
                    to="/mi-cuenta"
                    onClick={goLoan}
                    className="m_b_sm w_100_per"
                  >
                    <Button
                      variant="ghost"
                      scale="small"
                      className="w_100_per bg_primary_25 text_primary_300"
                    >
                      Mi cuenta
                    </Button>
                  </Link>

                  <Link
                    to="/financia-tu-moto"
                    onClick={goLoan}
                    className="custom_a w_100_per"
                  >
                    <Button
                      variant="principal"
                      icon={<Money />}
                      scale="small"
                      className="w_100_per"
                    >
                      Solicita tu crédito ya
                    </Button>
                  </Link>
                </div>
                {paths.map((ele) => {
                  return (
                    <Link
                      to={ele.path}
                      onClick={() => handleSidebar(ele.path)}
                      key={ele.path}
                      className="custom_a"
                    >
                      <Button
                        variant="link"
                        className={`${ele.path === url ? "active" : ""} m_b_md`}
                        scale="small"
                      >
                        {ele.label}
                      </Button>
                    </Link>
                  );
                })}
              </nav>
            </div>
          </div>
          <nav className="flex_center flex_justify_end flex_grow_1 display_none_mobile pos_relative">
            {paths.map((ele) => {
              return (
                <Link
                  to={ele.path}
                  onClick={() => handleSidebar(ele.path)}
                  key={ele.path}
                  className="custom_a"
                >
                  <Button
                    variant="link"
                    className={`${
                      ele.path === url ? "m_r_xxxl active" : "m_r_xxxl"
                    }`}
                    scale="small"
                  >
                    {ele.label}
                  </Button>
                </Link>
              );
            })}

            <Link
              to="/financia-tu-moto"
              onClick={goLoan}
              className="custom_a"
            >
              <Button
                variant="principal"
                icon={<Money />}
                onClick={goLoan}
                scale="small"
              >
                Solicita tu crédito ya
              </Button>
            </Link>

            <Link
              to="/mi-cuenta"
              onClick={goLoan}
              className="m_l_sm"
            >
              <Button
                variant="ghost"
                scale="small"
                className="w_100_per bg_primary_25 text_primary_300"
              >
                Mi cuenta
              </Button>
            </Link>
          </nav>
        </div>
      </header>
    </div>
  );
};
