/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import _ from "lodash";
import { useFormikContext } from "formik";
import Grid from "@mui/material/Grid";
import {
  Input,
  OzonSelect,
  Typography,
} from "design_system/src";

import { useSelector } from "react-redux";
import { RootState } from "store";


import { ReactComponent as Moto } from "design_system/src//static/icons/motorcycle.svg";
import { ReactComponent as Home } from "design_system/src//static/icons/home.svg";
import { ReactComponent as Boy } from "design_system/src//static/icons/boy.svg";
import { ReactComponent as Invoice } from "design_system/src//static/icons/invoice.svg";
import { ReactComponent as InformationTriangle } from "design_system/src//static/icons/information-triangle.svg";
import { ReactComponent as StarsProfile } from "design_system/src//static/icons/stars-profile.svg";

import { ReactComponent as UserCard2 } from "design_system/src/static/icons/user-card-2.svg";
import { FormControl } from "@mui/material";

import UploadInput from "views/ozonFly/uploadInput/UploadInput";
import ineF from "static/images/validateIdentity/ineF.png";
import ineB from "static/images/validateIdentity/ineB.png";
import selfie from "static/images/validateIdentity/selfie.png";

import { StepFormValues, StepEnum } from "./constants";

const tooltipData = [
  {
    title: "¿Cómo tomar una foto a tu INE?",
    step1: "De frente sin Flash",
    step2: "No deberá tener reflejos o sombras que impidan su legibilidad.",
    image: ineF,
  },
  {
    title: "¿Cómo tomar una foto a tu INE?",
    step1: "De atras sin Flash",
    step2: "No deberá tener reflejos o sombras que impidan su legibilidad.",
    image: ineB,
  },
  {
    title: "¿Como tomarte Selfie?",
    step1: "Rostro Centrado",
    step2: "De frente mirando a la camara.",
    image: selfie,
  },
];

const relationOptions = [
  { label: "Familiar", value: "Familiar" },
  { label: "Pareja", value: "Pareja" },
  { label: "Amigo/a", value: "Amigo/a" },
  { label: "Compañero de trabajo", value: "Compañero de trabajo" },
  { label: "Otro", value: StepEnum.OTHER },
];

const documentTooltip = (id: number) => (
  <div className="display_flex flex_gap_xxs">
    <div className="p_y_md p_l_lg">
      <Typography weight="600" scale="medium" className=" text_center m_b_md">
        {tooltipData[id].title}
      </Typography>
      <Typography
        weight="600"
        scale="xxsmall"
        className="m_b_sm"
        textColor="neutral_900"
      >
        <Typography
          weight="600"
          scale="xxsmall"
          textColor="primary_300"
          component="span"
        >
          {"1. "}
        </Typography>
        {tooltipData[id].step1}
      </Typography>
      <Typography
        weight="600"
        scale="xxsmall"
        className=""
        textColor="neutral_900"
      >
        <Typography
          weight="600"
          scale="xxsmall"
          textColor="primary_300"
          component="span"
        >
          {"2. "}
        </Typography>
        {tooltipData[id].step2}
      </Typography>
    </div>
    <img src={tooltipData[id].image} className="h_100_per" alt="" />
  </div>
);

export const IneStep = () => {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<StepFormValues>();

    return <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <Typography className="text_center" weight="600" scale="medium" component="h2">INE legible por ambas caras</Typography>
        <div className="animate__animated animate__fadeIn p_x_lg p_y_xl">
          <Grid container>
            <Grid
              item
              md={6}
              xs={12}
              className="p_x_xxxl_desktop p_x_md_mobile p_y_md_mobile"
            >
              <UploadInput
                onChange={() => {}}
                value={_.get(values, StepEnum.INE_FRONT)}
                name={StepEnum.INE_FRONT}
                tooltip={documentTooltip(0)}
                error={_.get(errors, StepEnum.INE_FRONT, undefined)}
                title="Sube la foto de tu INE por la "
                titleFocus="parte frontal"
                placeholderIcon={<UserCard2 className="dim_xl" />}
                accept="image/jpeg,image/png"
              />
            </Grid>
            <Grid item md={6} xs={12} className="p_x_xxxl_desktop p_x_md_mobile ">
              <UploadInput
                onChange={() => {}}
                value={_.get(values, StepEnum.INE_BACK)}
                name={StepEnum.INE_BACK}
                tooltip={documentTooltip(1)}
                error={_.get(errors, StepEnum.INE_BACK, undefined)}
                title="Sube la foto de tu INE por la "
                titleFocus="parte posterior"
                placeholderIcon={<UserCard2 className="dim_xl" />}
                accept="image/jpeg,image/png"
              />
            </Grid>
          </Grid>
        </div>
    </div>;
};

export const SelfieStep = () => {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<StepFormValues>();
    return <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <Typography className="text_center" weight="600" scale="medium" component="h2">Selfie</Typography>
        <div className="animate__animated animate__fadeIn p_x_lg p_y_xl">
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12} className="p_x_xxxl_desktop p_x_md_mobile">
          <UploadInput
            onChange={() => {}}
            value={_.get(values, StepEnum.SELFIE)}
            name={StepEnum.SELFIE}
            tooltip={documentTooltip(2)}
            error={_.get(errors, StepEnum.SELFIE, undefined)}
            title="¡Genial! ahora "
            titleFocus="tómate una selfie"
            placeholderIcon={<Boy className="dim_xl" />}
            accept="image/jpeg,image/png"
          />
        </Grid>
      </Grid>
    </div>
    </div>;
};

export const AddressStep = () => {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<StepFormValues>();

    return <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <Typography className="text_center" weight="600" scale="medium" component="h2">Comprobante de domicilio</Typography>
        <div className="animate__animated animate__fadeIn p_x_lg p_y_xl">
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12} className="p_x_xxxl_desktop p_x_md_mobile">
          <UploadInput
            onChange={() => {}}
            value={_.get(values, StepEnum.PROOF_OF_ADDRESS, undefined)}
            name={StepEnum.PROOF_OF_ADDRESS}
            title="Comprobante de domicilio"
            titleFocus=""
            subTitle="Sube un recibo de agua, luz o gas de menos de 3 meses"
            placeholderIcon={<Home className="dim_xl" />}
            error={
              _.get(errors, StepEnum.PROOF_OF_ADDRESS) &&
              getFieldMeta(StepEnum.PROOF_OF_ADDRESS).touched
                ? _.get(errors, StepEnum.PROOF_OF_ADDRESS, undefined)
                : ""
            }
          />
        </Grid>
      </Grid>
    </div>
    </div>;
};

export const ReferencesStep = () => {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<any>();

  const [showRef1OtherField, setShowRef1OtherField] = useState(false);
  const [showRef2OtherField, setShowRef2OtherField] = useState(false);

  useEffect(() => {
    if (_.get(values, StepEnum.REFERENCE_1_RELATION) === StepEnum.OTHER) {
      setShowRef1OtherField(true);
    } else {
      setShowRef1OtherField(false);
      setFieldValue(StepEnum.REFERENCE_1_OTHER, "");
    }
  }, [
    _.get(values, StepEnum.REFERENCE_1_RELATION),
    _.get(values, StepEnum.REFERENCE_1_OTHER),
  ]);

  useEffect(() => {
    if (_.get(values, StepEnum.REFERENCE_2_RELATION) === StepEnum.OTHER) {
      setShowRef2OtherField(true);
    } else {
      setShowRef2OtherField(false);
      setFieldValue(StepEnum.REFERENCE_1_OTHER, "");
    }
  }, [
    _.get(values, StepEnum.REFERENCE_2_RELATION),
    _.get(values, StepEnum.REFERENCE_2_OTHER),
  ]);

  return (
    <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <Typography className="text_center" weight="600" scale="medium" component="h2">Referencias</Typography>
        <Typography className="text_center" weight="400" scale="small" component="p">Ingresa los datos de tus referencias</Typography>
        <div className="animate__animated animate__fadeIn">
      <Grid container>
        <Grid item md={6} xs={12} className="p_x_xxl">

          <Input
            type="text"
            title="Referencia 1"
            name={StepEnum.REFERENCE_1_NAME}
            icon={<StarsProfile />}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe el nombre completo aquí"
            value={_.get(values, StepEnum.REFERENCE_1_NAME)}
            error={
              !!_.get(errors, StepEnum.REFERENCE_1_NAME) &&
              getFieldMeta(StepEnum.REFERENCE_1_NAME).touched
            }
            subtitle={
              getFieldMeta(StepEnum.REFERENCE_1_NAME).touched
                ? (_.get(errors, StepEnum.REFERENCE_1_NAME) as string)
                : undefined
            }
          />
          <div className="w_100_per display_flex flex_align_start flex_gap_xs">
            <div className="display_flex flex_center flex_gap_xxs w_30_per bg_neutral_500 m_y_md_mobile m_y_md_desktop p_y_sm_desktop  p_y_xs_mobile br_xxs">
              <img
                src="https://flagcdn.com/w80/mx.png"
                alt="mx"
                height="20"
              />
              <Typography scale="xsmall" weight="600" className="">
                +52
              </Typography>
            </div>
            <div className="w_70_per">
              <Input
                type="text"
                title=""
                name={StepEnum.REFERENCE_1_PHONE}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Escribe el celular aquí"
                value={_.get(values, StepEnum.REFERENCE_1_PHONE)}
                error={
                  !!_.get(errors, StepEnum.REFERENCE_1_PHONE) &&
                  getFieldMeta(StepEnum.REFERENCE_1_PHONE).touched
                }
                subtitle={
                  getFieldMeta(StepEnum.REFERENCE_1_PHONE).touched
                    ? (_.get(errors, StepEnum.REFERENCE_1_PHONE) as string)
                    : undefined
                }
              />
            </div>
          </div>
          <div>
            <div style={{ paddingTop: "1rem", marginBottom: "1rem" }}>
              <FormControl sx={{ minWidth: "100%" }} size="small">
                <OzonSelect
                  itemsLabel="Parentesco"
                  fieldName={StepEnum.REFERENCE_1_RELATION}
                  options={relationOptions}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  getFieldMeta={getFieldMeta}
                  errors={errors}
                />
              </FormControl>
            </div>
          </div>

          {showRef1OtherField && (
            <Input
              onBlur={handleBlur}
              error={
                !!_.get(errors, StepEnum.REFERENCE_1_OTHER) &&
                getFieldMeta(StepEnum.REFERENCE_1_OTHER).touched
              }
              subtitle={
                getFieldMeta(StepEnum.REFERENCE_1_OTHER).touched
                  ? (_.get(
                      errors,
                      StepEnum.REFERENCE_1_OTHER
                    ) as string)
                  : undefined
              }
              name={StepEnum.REFERENCE_1_OTHER}
              placeholder="Escribe el parentesco"
              title=""
              type="text"
              className="m_b_xs w_100_per"
              value={_.get(values, StepEnum.REFERENCE_1_OTHER)}
              onChange={handleChange}
            />
          )}
        </Grid>
        <Grid item md={6} xs={12} className="p_x_xxl">
          <Input
            type="text"
            title="Referencia 2"
            name={StepEnum.REFERENCE_2_NAME}
            icon={<StarsProfile />}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe el nombre aquí"
            value={_.get(values, StepEnum.REFERENCE_2_NAME)}
            error={
              !!_.get(errors, StepEnum.REFERENCE_2_NAME) &&
              getFieldMeta(StepEnum.REFERENCE_2_NAME).touched
            }
            subtitle={
              getFieldMeta(StepEnum.REFERENCE_2_NAME).touched
                ? (_.get(errors, StepEnum.REFERENCE_2_NAME) as string)
                : undefined
            }
          />
          <div className="w_100_per display_flex flex_align_start flex_gap_xs">
            <div className="display_flex flex_center flex_gap_xxs w_30_per bg_neutral_500 m_y_md_mobile m_y_md_desktop p_y_sm_desktop  p_y_xs_mobile br_xxs">
              <img
                src="https://flagcdn.com/w80/mx.png"
                alt="mx"
                height="20"
              />
              <Typography scale="xsmall" weight="600" className="">
                +52
              </Typography>
            </div>
            <div className="w_70_per">
              <Input
                type="text"
                title=""
                name={StepEnum.REFERENCE_2_PHONE}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Escribe el celular aquí"
                value={_.get(values, StepEnum.REFERENCE_2_PHONE)}
                error={
                  !!_.get(errors, StepEnum.REFERENCE_2_PHONE) &&
                  getFieldMeta(StepEnum.REFERENCE_2_PHONE).touched
                }
                subtitle={
                  getFieldMeta(StepEnum.REFERENCE_2_PHONE).touched
                    ? (_.get(errors, StepEnum.REFERENCE_2_PHONE) as string)
                    : undefined
                }
              />
            </div>
          </div>
          <div>
            <div style={{ paddingTop: "1rem", marginBottom: "1rem" }}>
              <FormControl sx={{ minWidth: "100%" }} size="small">
                <OzonSelect
                  itemsLabel="Parentesco"
                  fieldName={StepEnum.REFERENCE_2_RELATION}
                  options={relationOptions}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  getFieldMeta={getFieldMeta}
                  errors={errors}
                />
              </FormControl>
            </div>
          </div>

          {showRef2OtherField && (
            <Input
              onBlur={handleBlur}
              error={
                !!_.get(errors, StepEnum.REFERENCE_2_OTHER) &&
                getFieldMeta(StepEnum.REFERENCE_2_OTHER).touched
              }
              subtitle={
                getFieldMeta(StepEnum.REFERENCE_2_OTHER).touched
                  ? (_.get(
                      errors,
                      StepEnum.REFERENCE_2_OTHER
                    ) as string)
                  : undefined
              }
              name={StepEnum.REFERENCE_2_OTHER}
              placeholder="Escribe el parentesco"
              title=""
              type="text"
              className="m_b_xs w_100_per"
              value={_.get(values, StepEnum.REFERENCE_2_OTHER)}
              onChange={handleChange}
            />
          )}
        </Grid>
      </Grid>
    </div>
    </div>
  );
};
