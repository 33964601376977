/* eslint-disable prefer-destructuring */
import { useFormikContext } from "formik";
import ReactGA from "react-ga4";
import {
  fetchOzonerAddDocument,
  fetchOzonerDeleteDocument,
} from "models/ozoner/controllers/ozoner.controller";
import {
  fetchDeleteFormCrediticioAddDocument,
  fetchFormCrediticioAddDocument
} from "models/financialForm/controllers/financialForm.controller";
import { store } from "store";
import { useUser } from "hooks/useUser";
import { useFinancialForm } from "hooks/useFinancialForm";
import {
  toasterNotification,
  ToasterOptions,
} from "helpers/toastNotification";
import { updateDocumentStart, updateDocumentSucceed } from "store/actions/financialForm";
import { EvidenceDocument } from "models/shared/interfaces/shared.interface";
import { OnUploadInputChangeProps } from "../uploadInput/UploadInput";

const docsOzoner = ["ine_front", "ine_back", "selfie", "proof_of_address"];

export const useUploadFiles = () => {
  const { user } = useUser();
  const { financialForm, documents } = useFinancialForm();
  const { setFieldValue, setFieldTouched, errors, validateForm } = useFormikContext<any>();

  const handleUpdateDocument = (documentName: string, newDocuments: EvidenceDocument[]) => {
    store.dispatch(updateDocumentSucceed([
      ...documents.filter((doc) => documentName !== doc.name),
      ...newDocuments.filter((doc) => documentName === doc.name)
    ]));
  };

  const handleFetchUplodadFile = async ({
    name,
    imageFile,
    imageData,
    location = "OzonFly",
  }: OnUploadInputChangeProps) => {
    const documentName = name.split(".").pop() || "";
    const inOzoner = docsOzoner.includes(documentName);
    store.dispatch(updateDocumentStart());

    if (imageFile) {
      const formData = new FormData();
      formData.append("file", imageFile as Blob);
      formData.append("name", documentName);
      try {
        if (inOzoner) {
          const data = await fetchOzonerAddDocument({
            documentName,
            ozonerId: user._id,
            formData,
          });

          handleUpdateDocument(documentName, data.documents);
        } else {
          const data = await fetchFormCrediticioAddDocument({
            formId: financialForm!._id,
            documentName,
            formData,
          });

          handleUpdateDocument(documentName, data.documents);
        }

        setFieldValue(
          name,
          imageFile && imageData ? { imageFile, imageData } : null
        );
        toasterNotification({
          msg: "¡Documento subido!",
          toasterType: ToasterOptions.success,
          style: "light",
        });
        ReactGA.event(`DOCUMENT_${location}_${documentName}_uploaded`, {
          category: `DOCUMENT_${location}_${documentName}_uploaded`,
          label: `DOCUMENT ${location}, ${documentName} uploaded`,
        });
      } catch (error) {
        handleUpdateDocument(documentName, []);
        toasterNotification({
          msg: "¡Error al subir!",
          toasterType: ToasterOptions.error,
          style: "light",
        });
      }
    } else {
      try {
        if (inOzoner) {
          const docsForDelete = documents.filter(
            (doc) => doc.name === documentName
          );
          if (docsForDelete.length > 0) {
            docsForDelete.forEach(async (docToDel) => {
              const dataupdated = await fetchOzonerDeleteDocument({
                ozonerId: user._id,
                documentId: docToDel._id,
              });
              handleUpdateDocument(documentName, dataupdated.documents);
              setFieldValue(
                docToDel.name,
                null
              );
              toasterNotification({
                msg: "¡Documento eliminado!",
                toasterType: ToasterOptions.error,
                style: "light",
              });
            });
          }
        } else if (documents !== undefined && documents.length > 0) {
          const docsForDelete = documents.filter(
            (doc: { name: string; }) => doc.name === documentName
          );
          if (docsForDelete.length > 0) {
            docsForDelete.forEach(async (docToDel) => {
              const dataupdated = await fetchDeleteFormCrediticioAddDocument({
                formId: financialForm!._id,
                documentId: docToDel._id,
              });
              handleUpdateDocument(documentName, dataupdated.documents);
              setFieldValue(
                docToDel.name,
                null
              );
              toasterNotification({
                msg: "¡Documento eliminado!",
                toasterType: ToasterOptions.error,
                style: "light",
              });
            });
          }
        }
        ReactGA.event(`DOCUMENT_${location}_${documentName}_deleted`, {
          category: `DOCUMENT_${location}_${documentName}_deleted`,
          label: `DOCUMENT ${location}, ${documentName} deleted`,
        });
      } catch (error) {
        handleUpdateDocument(documentName, []);
        toasterNotification({
          msg: "¡Error al eliminar!",
          toasterType: ToasterOptions.error,
          style: "light",
        });
      }
    }
  };

  const handleGenericUploadFile = ({
    name,
    imageFile,
    imageData,
    location,
  }: OnUploadInputChangeProps) => {
    setFieldTouched(name);
    validateForm().then(() => {
      if (!errors[name]) {
        handleFetchUplodadFile({ name, imageFile, imageData, location });
      }
    });
  };

  return handleGenericUploadFile;
};
