import Swal from "sweetalert2";
import { PandaboardApiInstance } from "helpers/api";
import { cleanUtmQueryParamsFromSession } from "helpers/queryParams";
import { Lead, LeadCreate, LeadUpdate } from "../interfaces/lead.interface";
import { toLead } from "../helpers/formatData";

export const getLead = async (id: string): Promise<Lead> =>
  PandaboardApiInstance
    .get(`leads/${id}`)
    .then((res) => toLead(res.data))
    .catch((err: any) => {
      throw err.response.data;
    });

export const createLead = async (
  lead: LeadCreate
): Promise<Lead> => PandaboardApiInstance
  .post("leads", lead)
  .then((res) => toLead(res.data))
  .catch((err: any) => {
    throw err.response.data;
  })
  .finally(() => cleanUtmQueryParamsFromSession());

export const updateLead = async (
  id: string,
  lead: LeadUpdate
): Promise<Lead> => PandaboardApiInstance
  .put(`leads/${id}`, lead)
  .then((res) => toLead(res.data))
  .catch((err: any) => {
    if (err.response && err.response.status === 400) {
      Swal.fire({
        title: "Ha ocurrido un error",
        text: "No se ha podido generar la actualización del lead",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
    throw err.response.data;
  })
  .finally(() => cleanUtmQueryParamsFromSession());

export const completeLead = async (
  id: string,
  lead: LeadUpdate
): Promise<{ ozoner: any, financialForm: any}> => PandaboardApiInstance
  .put(`leads/${id}`, lead)
  .then((res) => res.data)
  .catch((err: any) => {
    if (err.response && err.response.status === 400) {
      Swal.fire({
        title: "Ha ocurrido un error",
        text: "No se ha podido crear la solicitud.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
    throw err.response.data;
  })
  .finally(() => cleanUtmQueryParamsFromSession());
