/* eslint-disable no-unused-vars */
import { ListVehicles } from "models/inventory/interfaces/vehicle.interface";
import { CACHE_TIME, getVehicles } from "models/inventory/controllers/vehicle.controller";
import { RootState, store } from "../index";
import { types } from "../types/types";
import { Cities } from "../reducers/cityReducer";
import { OrderType, SALE_PRICE_STATE, SelectedFilters, WEEKS_STATE } from "../reducers/vehiclesReducer";

const fetchVehiclesSuccess = (vehicles: ListVehicles) => ({
  type: types.vehiclesFetchSuccess,
  payload: vehicles
});

const fetchHomeVehiclesSuccess = (vehicles: ListVehicles) => ({
  type: types.vehiclesFetchHomeSuccess,
  payload: vehicles
});

export const setSelectedFilters = (filters: Partial<SelectedFilters>) => ({
  type: types.vehiclesSetSelectedFilter,
  payload: { filters }
});

export const updateSelectedFilters = (key: keyof SelectedFilters, value: any) => ({
  type: types.vehiclesUpdateSelectedFilter,
  payload: { key, value }
});

export const clearSelectedFilters = () => ({
  type: types.vehiclesClearSelectedFilter
});

const fetchVehiclesUpdateTimestamp = () => ({
  type: types.vehiclesFetchUpdateTimestamp
});

const fetchVehiclesCity = (city?: Cities | null) => ({
  type: types.recommendedFetchUpdateTimestamp,
  payload: city
});


export const fetchVehiclesUpdateCity = (city?: Cities | null) => ({
  type: types.vehiclesFetchUpdateCity,
  payload: city
});

const fetchVehiclesStart = () => ({
  type: types.vehiclesFetch
});

const fetchVehiclesFail = (message: string) => ({
  type: types.vehiclesFetchFail,
  payload: message
});

const needToCallApi = (timestamp: number) =>
  Math.abs((Date.now() - timestamp) / 1000) > CACHE_TIME;

const fetchVehiclesThunk =
  ({ filters }: { filters: Partial<SelectedFilters> }) =>
    async (dispatch: any, getState: () => RootState) => {
      try {
        const { timestamp, data, city } = getState().vehiclesReducer;
        dispatch(fetchVehiclesStart());

        let result: ListVehicles | null = data;

        if (!timestamp || needToCallApi(timestamp) || city !== filters?.city ) {
          result = await getVehicles({
            salePrice: SALE_PRICE_STATE,
            ...filters,
            weeks: WEEKS_STATE
          });
          dispatch(fetchVehiclesUpdateTimestamp());
          dispatch(fetchVehiclesCity(filters?.city));
        }

        if (result) {
          dispatch(fetchHomeVehiclesSuccess(result));
        }
      } catch (e) {
        dispatch(fetchVehiclesFail("error"));
      }
    };

const fetchVehicles = () => async (dispatch: any, getState: () => RootState) => {
  try {
    const { selectedFilters } = getState().vehiclesReducer;
    dispatch(fetchVehiclesStart());
    const result: ListVehicles | null = await getVehicles({
      salePrice: SALE_PRICE_STATE,
      ...selectedFilters,
      weeks: WEEKS_STATE
    });
    dispatch(fetchVehiclesUpdateTimestamp());

    if (result) {
      dispatch(fetchVehiclesSuccess(result));
    }
  } catch (e) {
    dispatch(fetchVehiclesFail("error"));
  }
};

export { fetchVehiclesThunk, fetchVehicles };
