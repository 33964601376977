/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
import React, { ReactElement } from "react";
import { IDocument } from "views/ozonFly/ozonFlyForm/OzonFlyForm";
import * as yup from "yup";

export const step1 = "certificates";

export interface step {
  component: ReactElement<any, any>;
  name: string;
  title: string;
  modalConfig?: {
    widthDesktop: number;
    heightDesktop: number;
    title?: ReactElement<any, any>;
    component: ReactElement<any, any>;
  },
  button?: {
    text?: string;
    color?: string;
  }
  validationFn?: any;
  complementComponent?: ReactElement<any, any>;
}

export enum StepEnum {
  incomes = "certificates.incomes",
  bank_certificate_1 = "certificates.bank_certificate_1",
  bank_certificate_2 = "certificates.bank_certificate_2",
  bank_certificate_3 = "certificates.bank_certificate_3",
  bank_certificate_4 = "certificates.bank_certificate_4",
  bank_certificate_5 = "certificates.bank_certificate_5",
  bank_certificate_6 = "certificates.bank_certificate_6",
  delivery_affiliation = "certificates.delivery_affiliation",
}

type DocumentType = IDocument | null | undefined;

export interface StepFormValues {
  [step1]:{
    incomes?: number;
    bank_certificate_1?: DocumentType;
    bank_certificate_2?: DocumentType;
    bank_certificate_3?: DocumentType;
    bank_certificate_4?: DocumentType;
    bank_certificate_5?: DocumentType;
    bank_certificate_6?: DocumentType;
    delivery_affiliation?: DocumentType;
  }
};

export const initialValues: StepFormValues = {
  [step1]: {
    incomes: undefined,
    bank_certificate_1: null,
    bank_certificate_2: null,
    bank_certificate_3: null,
    bank_certificate_4: null,
    bank_certificate_5: null,
    bank_certificate_6: null,
    delivery_affiliation: null,
  },
};

export const validationSchema = yup.object().shape({
  [step1]: yup.object().shape({
    incomes: yup
      .number()
      .required("Campo requerido")
      .lessThan(100001, "Debe ser menor a $100,000.00mxn")
      .positive("Debe ser mayor a $0.00mxn")
      .integer("Debe ser mayor a $0.00mxn"),
    bank_certificate_1: yup.mixed(),
    bank_certificate_2: yup.mixed(),
    bank_certificate_3: yup.mixed(),
    bank_certificate_4: yup.mixed(),
    bank_certificate_5: yup.mixed(),
    bank_certificate_6: yup.mixed(),
    delivery_affiliation: yup.mixed(),
  })
});

export const validateStep = (
  step: string,
  values: StepFormValues
) => yup.reach(validationSchema, step).isValidSync(values[step as keyof StepFormValues]);
