/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import axios, { CancelTokenSource } from "axios";
import {
  colorOptions,
  IColorOptions,
  ISalePrices,
  ITVehicle,
  ListVehicles,
  TVehicle,
  VehiclesFilters
} from "models/inventory/interfaces/vehicle.interface";
import { fetchVehiclesGraphql } from "apolloClient";
import { Cities } from "../../../store/reducers/cityReducer";
import { PandaboardApiInstance } from "../../../helpers/api";
import {cleanBrands, cleanCylindersFilters, cleanModels, cleanRangeValues, cleanYears} from "../../../helpers/formatFilters";

let source: CancelTokenSource;
export const getVehiclesRecommended = (country: string, city?: Cities): Promise<TVehicle[]> => {
  if(source){
    source.cancel();
  }
  const {CancelToken} = axios;
  source = CancelToken.source();
  return PandaboardApiInstance.get(
    "vehicle/recommended",{params: {countryIso: country, cityId: city}, cancelToken: source.token }
  )
    .then((response) => response.data.map((vehicle: any) => new TVehicle(vehicle)))
    .catch((e) => {
      if (axios.isCancel(e)) {
        console.log("Request canceled", e.message);
      }
      return [] as TVehicle[];});
};

export const CACHE_TIME =  10 * 1000; // 10 Minutes

export const getVehicles = async (filters: Partial<VehiclesFilters>): Promise<ListVehicles | null> => {
  try {
    const {getAllVehicles} = await fetchVehiclesGraphql(filters);
    const cylinderCapacities = cleanCylindersFilters(getAllVehicles.filters.cc);
    const years = cleanYears(getAllVehicles.filters.years);
    const models = cleanModels(getAllVehicles.filters.models);
    const brands = cleanBrands(getAllVehicles.filters.models);
    const mileageRange = cleanRangeValues(getAllVehicles.filters.mileageRange);
    const priceRange = cleanRangeValues(getAllVehicles.filters.priceRange, false);
    const colors:IColorOptions[] = colorOptions.filter(({ value }) => getAllVehicles.filters.colors.includes(value));

    const vehicleInstace = getAllVehicles.data.map((item: ITVehicle) => new TVehicle(item));

    const fetchVehicleResponse = {
      vehicles: vehicleInstace,
      filters: {
        ...(getAllVehicles.filters),
        brands,
        cylinder_Capacities: cylinderCapacities,
        models,
        years,
        colors,
        mileageRange,
        priceRange
      },
      page: getAllVehicles.page,
      pages: getAllVehicles.pages,
      count: getAllVehicles.count,
    } as ListVehicles;


    return fetchVehicleResponse;
  } catch (e:any) {
    console.error(e);
    return null;
  }
};

export const getVehicle = (id: string): Promise<TVehicle | null> =>
  PandaboardApiInstance
    .get(`vehicle/${id}`)
    .then((response) => {
      const salePrices = Object.keys(response.data.salePrices).map((key) => response.data.salePrices[key]);
      response.data.salePrices = salePrices;
      return new TVehicle(response.data);
    })
    .catch(() => null);

export const getQuotaVehicle = (id: string, duration: number, advancedMoney?: number): Promise<ISalePrices> =>
  PandaboardApiInstance
    .get(`vehicle/${id}/quota-vehicle?duration=${duration}${advancedMoney ? `&advancedMoney=${advancedMoney}` : ""}`)
    .then((response) => response.data)
    .catch(() => null);
