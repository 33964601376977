export const cleanCylindersFilters = (options:number[]) => {
  const orderedOptions = options.slice();
  return orderedOptions.sort((a, b) => a - b);
};

export const cleanYears = (options:number[]) => {
  const orderedOptions = options.slice();
  return orderedOptions.sort((a, b) => a - b);
};

export const cleanModels = (options:string[]) => {
  return options.map((value) => value.split(".")[1]);
};

export const cleanBrands = (options:string[]) => {
  return Array.from(new Set(options.map((value) => value.split(".")[0])));
};

export const cleanRangeValues = (options:number[][], zeroValid: boolean = true) => {
  return options.map((range: number[]) => {
    if (range[0] === 0 && !zeroValid) {
      return [1, range[1]].join(".");
    }
    return range.join(".");
  });
};
