import React, { useEffect, useState } from "react";
import _ from "lodash";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useFormikContext } from "formik";
import { Typography } from "design_system/src";
import { StepFormValues, StepEnum } from "./constants";


interface Ubi {
  latitude: number;
  longitude: number;
  zoom: number;
}

export const ModalAddress = () => {
  const { values, validateForm, setFieldValue } =
    useFormikContext<StepFormValues>();
  const mapboxToken = process.env.REACT_APP_MAPBOX_API_KEY || "";
  const [viewState, setViewState] = useState<Ubi>({
    latitude: 19.432608,
    longitude: -99.133209,
    zoom: 16,
  });

  const updatePositionFromAddress = async (query: string) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      query
    )}.json?access_token=${mapboxToken}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      const coordinates = data.features[0]?.center;
      const zipCode = data.features[0]?.id.split(".")[0] === "postcode"
        ? data.features[0]?.text
        : data.features[0]?.context
          .filter(({ id }: { id: any }) => id.split(".")[0] === "postcode")
          .map(({ text }: { text: string }) => text)[0];

      if (coordinates) {
        setViewState({
          latitude: coordinates[1],
          longitude: coordinates[0],
          zoom: viewState.zoom,
        });
        setFieldValue(StepEnum.lat, coordinates[1]);
        setFieldValue(StepEnum.long, coordinates[0]);
      }

      if (zipCode) {
        setFieldValue(StepEnum.zipCode, zipCode);
      }
    } catch (error) {
      console.error("Error al obtener dirección:", error);
    }
  };

  useEffect(() => {
    const addressString = `${_.get(values, StepEnum.address)}, México`;
    updatePositionFromAddress(addressString);
  }, []);

  const onDragEnd = (event: { lngLat: { lng: number; lat: number } }) => {
    const { lng, lat } = event.lngLat;
    updatePositionFromAddress(`${lng},${lat}`);
  };

  return (
    <div className="display_flex flex_col h_250_px_mobile h_90_per_desktop">
      <Typography
        scale="small"
        weight="400"
        textColor="neutral_900"
        className="text_center"
      >
        Encuentra el punto exacto de tu residencia
      </Typography>
      <Map
        {...viewState}
        onMove={(evt) => setViewState({ ...viewState, ...evt.viewState })}
        style={{ width: "100%", flexGrow: 1 }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken={mapboxToken}
      >
        <Marker
          latitude={viewState.latitude}
          longitude={viewState.longitude}
          draggable
          onDragEnd={onDragEnd}
          color="orange"
        />
      </Map>
    </div>
  );
};
