/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useMemo, useReducer } from 'react';
import { defaultStepperState, reducer, StepperStateType } from '../Store/store';

interface StepperContextType extends StepperStateType {
	dispatch: any;
}

const StepperContext = React.createContext<StepperContextType>({
	currentStep: 0,
	steps: [],
	dispatch: () => {},
});

const StepperProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, defaultStepperState);
	const memoizedState = useMemo(
		() => ({ ...state, dispatch }),
		[state, dispatch],
	);

	return (
		<StepperContext.Provider value={memoizedState}>
			{children}
		</StepperContext.Provider>
	);
};

export { StepperContext, StepperProvider };
