import { TVehicle } from "../../models/inventory/interfaces/vehicle.interface";
import { types } from "../types/types";

export const setApplicationVehicle = (vehicle: TVehicle) => ({
  type: types.applicationSetVehicle,
  payload: vehicle,
});

export const clearApplicationVehicle = () => ({
  type: types.applicationSetVehicle,
  payload: null,
});

export const setApplicationLoanTime = (weeks: number) => ({
  type: types.applicationSetLoanTime,
  payload: weeks,
});

export const clearApplicationLoanTime = () => ({
  type: types.applicationSetLoanTime,
  payload: null,
});
