import React from 'react';
import SliderMat from '@mui/material/Slider';
import './styles.scss';
import { Mark } from '@mui/core/SliderUnstyled/SliderUnstyled';

interface Props {
	step?: number;
	min?: number;
	max?: number;
	marks?: Mark[];
	defaultValue?: number;
	valueLabelDisplay?: 'on' | 'off' | 'auto';
	ariaLabel?: string;
	value?: number;
	name?: string;
	onChange?: any;
}

const Slider: React.FC<Props> = ({
	marks,
	min,
	max,
	ariaLabel,
	step = null,
	defaultValue,
	valueLabelDisplay = 'auto',
	name,
	value,
	onChange,
}) => (
	<div className="slider-container">
		<SliderMat
			aria-label={ariaLabel}
			defaultValue={defaultValue}
			step={step}
			max={max}
			min={min}
			marks={marks}
			valueLabelDisplay={valueLabelDisplay}
			name={name}
			value={value}
			onChange={onChange}
		/>
	</div>
);

export default Slider;
