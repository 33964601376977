import Swal from "sweetalert2";
import { FetchValidateCurp } from "../interfaces/auth-client.interface";
import { PandaboardApiInstance } from "../../../helpers/api";

// eslint-disable-next-line camelcase
export const fetchRefreshToken = (refresh_token: string) =>
  PandaboardApiInstance.post(
    "/auth-client/refresh",
    // eslint-disable-next-line camelcase
    { refresh_token },
    {
      headers: { refreshRequest: true },
    }
  );

export const fetchValidateCurp = async (curp: string) =>
  PandaboardApiInstance.post<FetchValidateCurp>("auth-client/validate-ozoner", { curp })
    .then((res) => {
      const {
        data: { exist, email, financialFormStatus },
      } = res;
      if (exist && financialFormStatus) {
        Swal.fire({
          text: `Este CURP ya se encuentra vinculado a una solicitud con el correo ${email}, ingresa un CURP valido.`,
          title: "¡TU CURP YA ESTÁ REGISTRADO!",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "bg_primary_300 w_90_per",
            title: "text_neutral_800",
            container: "text_neutral_700",
          },
        });
      }
      return !(exist && financialFormStatus);
    })
    .catch((err: any) => {
      if (err.response && err.response.status === 400) {
        Swal.fire({
          text: "¡CURP invalido!",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
      throw err.response.data;
    });

export const fetchValidateOzoner = async (data: { email: string, curp?: string }) =>
  PandaboardApiInstance.post<FetchValidateCurp>("auth-client/validate-ozoner", data)
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchCURP = async (curp: string) =>
  PandaboardApiInstance
    .post("auth-client/validate-ozoner", {curp})
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchLogin = async (curp: string, password: string) =>
  PandaboardApiInstance
    .post("auth-client/login", { curp, password })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchLogout = async () =>
  PandaboardApiInstance
    .get("auth-client/logout", { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchOtpPassword = async (curp: string) =>
  PandaboardApiInstance
    .post(`auth-client/otp/${curp}`)
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchOtpValidation = async (curp: string, otp: string) =>
  PandaboardApiInstance
    .post("auth-client/otp/validate", { curp, otp })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchChangePassword = async (password: string, confirmPassword: string) =>
  PandaboardApiInstance
    .post("auth-client/reset-password", { password, confirmPassword}, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchGetTempToken = async (email: string) =>
  PandaboardApiInstance
    .post("auth-client/temp-token", { email }, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });