/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import * as yup from "yup";

export enum StepEnum {
    INDENTITY_STEP="identityVerificationStep",
    SELFIE_STEP="selfieVerificationStep",
    ADDRESS_STEP="validationAddressStep",
    REFERENCES_STEP="validationReferencesStep",
    RESERVATION_RECEIPT_STEP="reservationReceiptStep",

    INE_FRONT="identityVerificationStep.ine_front",
    INE_BACK="identityVerificationStep.ine_back",
    SELFIE="selfieVerificationStep.selfie",
    PROOF_OF_ADDRESS = "validationAddressStep.proof_of_address",
    REFERENCE_1 = "validationReferencesStep.personal_reference_1",
    REFERENCE_1_NAME = "validationReferencesStep.personal_reference_1.name",
    REFERENCE_1_PHONE = "validationReferencesStep.personal_reference_1.phone",
    REFERENCE_1_RELATION = "validationReferencesStep.personal_reference_1.relation",
    REFERENCE_1_OTHER = "validationReferencesStep.personal_reference_1.other",
    REFERENCE_2 = "validationReferencesStep.personal_reference_2",
    REFERENCE_2_NAME = "validationReferencesStep.personal_reference_2.name",
    REFERENCE_2_PHONE = "validationReferencesStep.personal_reference_2.phone",
    REFERENCE_2_RELATION = "validationReferencesStep.personal_reference_2.relation",
    REFERENCE_2_OTHER = "validationReferencesStep.personal_reference_2.other",

    OTHER = "Otro"
}

export type StepType = StepEnum.INDENTITY_STEP |
    StepEnum.SELFIE_STEP |
    StepEnum.ADDRESS_STEP |
    StepEnum.REFERENCES_STEP;

export interface StepFormValues {
    [StepEnum.INDENTITY_STEP]: {
        ine_front?: DocumentType;
        ine_back?: DocumentType;
    };
    [StepEnum.SELFIE_STEP]: {
        selfie?: DocumentType;
    },
    [StepEnum.ADDRESS_STEP]: {
        proof_of_address?: DocumentType;
    };
    [StepEnum.REFERENCES_STEP]: {
        personal_reference_1?: {
            name: string;
            phone: string;
            relation: string;
            other: string;
        };
        personal_reference_2?: {
            name: string;
            phone: string;
            relation: string;
            other: string;
        };
    };
}

export const SUPPORTED_FORMATS = ["image/jpg", "image/png", "image/jpeg"];

export const validationSchema = yup.object().shape({
    [StepEnum.INDENTITY_STEP]: yup.object().shape({}),
    [StepEnum.SELFIE_STEP]: yup.object().shape({}),
    [StepEnum.ADDRESS_STEP]: yup.object().shape({}),
    [StepEnum.REFERENCES_STEP]: yup.object().shape({
        personal_reference_1: yup.object().shape({
            name: yup.string().required("Campo requerido"),
            phone: yup
                .string()
                .required("Campo requerido")
                .matches(/^!*(\d!*){10,}$/, "Numero invalido"),
            relation: yup.string().required("Campo requerido"),
            other: yup
                .string()
                .test(
                    "",
                    "Campo requerido",
                    function (value) {
                        if (!value && this.parent.relation === StepEnum.OTHER) {
                            return false;
                        }
                        return true;
                    }
                ),
        }),
        personal_reference_2: yup.object().shape({
            name: yup.string().required("Campo requerido"),
            phone: yup
                .string()
                .required("Campo requerido")
                .matches(/^!*(\d!*){10,}$/, "Numero invalido"),
            relation: yup.string().required("Campo requerido"),
            other: yup
                .string()
                .test(
                    "",
                    "Campo requerido",
                    function (value) {
                        if (!value && this.parent.relation === StepEnum.OTHER) {
                            return false;
                        }
                        return true;
                    }
                ),
        })
    }),
});

export const initialValues: StepFormValues = {
    [StepEnum.INDENTITY_STEP]: {
        ine_front: undefined,
        ine_back: undefined,
    },
    [StepEnum.SELFIE_STEP]: {
        selfie: undefined,
    },
    [StepEnum.ADDRESS_STEP]: {
        proof_of_address: undefined,
    },
    [StepEnum.REFERENCES_STEP]: {
        personal_reference_1: undefined,
        personal_reference_2: undefined,
    }
};
