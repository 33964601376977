
import {
  FinancialFormStatusEnum,
  FinancialFormTypeEnum,
  FinancialFormDocsEnum
} from "models/financialForm/enums/financialForm.enums";
import { TFinancialInformation, TPersonalInformation, TPersonalReference } from "models/shared/interfaces/shared.interface";
import { ITVehicle } from "../../inventory/interfaces/vehicle.interface";
import { Ozoner } from "../../ozoner/interfaces/ozoner.interface";

export interface FormAddDocumentRequestDelete {
  formId: string;
  documentId: string;
}

export interface FormAddDocumentRequestPut {
  formId: string;
  formData: FormData;
  documentName: string;
}

export interface FormAddReferenceRequest {
  loanFormId: string;
  references: {
    personal_reference_1: TPersonalReference;
    personal_reference_2: TPersonalReference;
  };
}

export interface StatusHistoric {
  status: FinancialFormStatusEnum;
  updatedAt: string;
  by: string;
}

export interface IEvidenceDocument {
  _id: string;
  name: string;
  url: string;
  size: number;
  path: string;
  hash: string;
  ext: string;
  mimeType: string;
  typeEvidence: FinancialFormDocsEnum;
}

export interface ILoanApplication {
  loanDuration: number,
  advancedMoney?: number,
  rejectedReason?: string,
  devolutionReason?: string,
  partnership?: string,
  approvedWeeklyPayment: number,
  approvedPercentage: number,
  loanSegmentation: string,
  sellResponsible: string,
  discountId: string,
  deposit: number,
  salesRank: string,
  appointment: {
      scheduledAt: string,
      deliverOzonio: string,
  },
  contactMethod: string,
  isResponseSuccess: boolean,
}

export interface IFinancialForm {
  _id: string;
  status: FinancialFormStatusEnum;
  type: FinancialFormTypeEnum;
  ozoner: Ozoner;
  vehicle?: ITVehicle;
  personalInformation: TPersonalInformation;
  financialInformation: TFinancialInformation;
  loanApplication: ILoanApplication;
  documents: IEvidenceDocument[];
  statusHistoric: StatusHistoric[];
  city: string;
  createdAt: string;
  updatedAt: string;
}

export class TFinancialForm implements IFinancialForm {
  _id: string;
  status: FinancialFormStatusEnum;
  type: FinancialFormTypeEnum;
  ozoner: Ozoner;
  vehicle?: ITVehicle;
  personalInformation: TPersonalInformation;
  financialInformation: TFinancialInformation;
  loanApplication: ILoanApplication;
  documents: IEvidenceDocument[];
  statusHistoric: StatusHistoric[];
  city: string;
  createdAt: string;
  updatedAt: string;

  constructor(data: IFinancialForm) {
    this._id = data._id;
    this.status = data.status;
    this.type = data.type;
    this.ozoner = data.ozoner;
    this.vehicle = data.vehicle;
    this.personalInformation = data.personalInformation;
    this.financialInformation = data.financialInformation;
    this.loanApplication = data.loanApplication;
    this.documents = data.documents;
    this.statusHistoric = data.statusHistoric;
    this.city = data.city;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
