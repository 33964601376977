import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useTrackPixelOnMount from "hooks/FacebookPixel/useTrackPixelOnMount";
import { FormApplicationProvider } from "./context/context";
import { FormApplication } from "./FormApplication";

export const FormApplicationWithProvider = () => {
    const navigate = useNavigate();

    useTrackPixelOnMount("Subscribe");

    useEffect(() => {
        document.title = "Estudio financiero en Ozon";
        window.focus();
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <FormApplicationProvider>
            <FormApplication />
        </FormApplicationProvider>
    );
};
