import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { FormikProvider, useFormik, useFormikContext } from "formik";

import Grid from "@mui/material/Grid";
import { Typography, Button, Input } from "design_system/src";
import { ReactComponent as Invoice } from "design_system/src//static/icons/invoice.svg";
import { ReactComponent as Clock } from "design_system/src//static/icons/clock.svg";
import { ReactComponent as UserCircle } from "design_system/src//static/icons/user-circle.svg";
import { ReactComponent as GPS } from "design_system/src//static/icons/gps.svg";
import { ReactComponent as Dollar } from "design_system/src//static/icons/dollar.svg";

import { WhatsappButton } from "views/miCuenta/components/whatsappButton/WhatsappButton";
import { ValidationIdentityFormEnum } from "enums/validationIdentityForm.enum";
import { UserDocument } from "models/auth-client/interfaces/auth-client.interface";
import { useUploadFiles } from "views/ozonFly/hooks/useUploadFiles";
import UploadInput, { getBase64 } from "views/ozonFly/uploadInput/UploadInput";
import { store } from "store";

import { ReactComponent as ViewerIcon } from "static/images/uploadInput/documento_preview_icono.svg";
import UploadPdfBk from "static/images/uploadInput/documento_preview_recibo.png";
import moment from "moment";
import { fetchGetSellResponsible } from "models/financialForm/controllers/sales.controller";
import { FinancialFormStatusEnum, FinancialFormDocsEnum } from "models/financialForm/enums/financialForm.enums";
import { useUser } from "hooks/useUser";
import { useFinancialForm } from "hooks/useFinancialForm";
import useLocalStorage from "hooks/useLocalStorage";
import { VehicleCard } from "views/LoanApplicationVehicles/LoanApplicationVehicles";
import { TVehicle } from "models/inventory/interfaces/vehicle.interface";
import { StepEnum, StepFormValues } from "./constants";

export interface IDocument {
    imageData: any;
    imageFile: Blob | UserDocument;
}

export const LoanValidationStep = () => {
    const {
        values,
        setFieldValue,
    } = useFormikContext<StepFormValues>();
    const [acceptance, setAcceptance] = useState<boolean>(false);

    useEffect(() => {
        setAcceptance(_.get(values, StepEnum.ACCEPTED_VALIDATION, false));
    }, [values]);

    return <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <Typography className="text_center" weight="600" scale="medium" component="h2">Validación de documentos</Typography>
        <div className="p_y_xxxl w_50_per_desktop m_x_auto">
            {!acceptance &&<Typography
                className="text_center"
                weight="400"
                scale="medium"
                component="p"
            >
                Tus documentos esta <strong>siendo validados.</strong> Pronto recibiras respuesta de nuestra parte.
            </Typography>}

            {acceptance &&<Typography
                className="text_center"
                weight="400"
                scale="medium"
                component="p"
            >
                Tu crédito ha sido <strong className="text_green_300">aprobado</strong> por una cuota semanal máxima de:
            </Typography>}

            {!acceptance && <>
                <br />
                <br />
                <br />
                <br />
                <div className="m_t_xxl display_flex flex_justify_end">
                    <Button
                        variant="ghost"
                        scale="small"
                        onClick={() => setFieldValue(StepEnum.ACCEPTED_VALIDATION, true)}
                    >Aceptar documentos</Button>
                </div>
            </>}
        </div>
    </div>;
};

const InputComponent = () => {
    const {
        values,
        errors,
    } = useFormikContext<any>();
    return <UploadInput
        onChange={() => {}}
        value={_.get(values, StepEnum.RESERVATION_RECEIPT)}
        name={StepEnum.RESERVATION_RECEIPT}
        error={(_.get(errors, StepEnum.RESERVATION_RECEIPT) as string) ?? undefined}
        title="Sube o toma la foto del comprobante de apartado"
        placeholderIcon={<Invoice className="dim_xl" />}
        accept="image/jpeg,image/png"
    />;
};

export const ReservationStep = () => {
    return <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <Typography className="text_center" weight="600" scale="medium" component="h2">Comprobante de apartado</Typography>
        <div className="animate__animated animate__fadeIn p_x_lg p_y_xl">
            <Grid container justifyContent="center">
                <Grid item md={6} xs={12} className="p_x_xxxl_desktop p_x_md_mobile">
                    <InputComponent/>
                </Grid>
            </Grid>
        </div>
    </div>;
};

export const SchedulingStep = () => {
    const {
        values,
        handleChange,
        errors,
        handleBlur,
        getFieldMeta,
        setFieldValue,
    } = useFormikContext<StepFormValues>();
    const [storedValue, setStoredValue] = useLocalStorage<any>("loanApplication", null);
    const [vehicle, setVehicle] = useState<TVehicle | null>(null);

    useEffect(() => {
        if (storedValue.vehicle) {
            setVehicle(new TVehicle(storedValue.vehicle));
        }
    });

    return <div className="w_100_per m_x_auto">
        <Typography className="text_center" weight="600" scale="medium" component="h2">Agendar entrega</Typography>
        <div className="m_y_md p_y_md w_80_per_desktop m_x_auto display_flex flex_gap_xxl">
            {vehicle && <div className="dso_card bg_neutral_0 cursor_pointer w_30_per">
                <VehicleCard vehicle={vehicle} />
            </div>}

            <div className="w_70_per">
                <div>
                    <div>
                        <div className="m_b_md display_flex flex_align_center">
                            <Input
								onBlur={handleBlur}
								error={
									!!_.get(errors, StepEnum.SCHEDULE_DATE) &&
									(getFieldMeta(StepEnum.SCHEDULE_DATE).touched)
								}
								subtitle={_.get(errors, StepEnum.SCHEDULE_DATE)}
								title="Fecha de entrega"
								name={StepEnum.SCHEDULE_DATE}
								type="date"
								className="m_b_xs w_100_per"
								value={
									_.get(values, StepEnum.SCHEDULE_DATE)
										? moment
											.parseZone(_.get(values, StepEnum.SCHEDULE_DATE, new Date()))
											.format( "YYYY-MM-DD ")
										: ""
								}
								onChange={handleChange}
							/>
                        </div>
                        <div className="m_b_md display_flex flex_align_center">
                            <GPS className="m_r_sm"/>
                            <div className="">
                                <Typography
                                    weight="600"
                                    scale="medium"
                                >
                                    Lugar
                                </Typography>
                                <Typography
                                    weight="400"
                                    scale="medium"
                                >
                                    Laguna de Mayran 396, Anáhuac I Secc., Anáhuac I Secc, Miguel Hidalgo, 11320 Ciudad de México, CDMX, México
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg_primary_25 m_y_md p_y_md p_x_xl br_md m_x_auto" style={{ maxWidth: 400 }}>
            <Typography
                            className="m_l_sm"
                            weight="400"
                            scale="medium"
                            component="p"
                        >
                            <strong>Importante</strong> para el día de la entrega
            </Typography>
            <div>
                <div className="display_flex flex_align_start m_b_sm">
                                <div className="w_10_per display_flex">
                                    <Typography
                                        className="bg_primary_300 br_circle m_none w_20_px h_20_px display_flex flex_center"
                                        weight="600"
                                        scale="small"
                                        component="p"
                                    >
                                        1
                                    </Typography>
                                </div>
                                <Typography
                                    className="m_l_sm m_y_none w_80_per"
                                    weight="400"
                                    scale="small"
                                    component="p"
                                >
                                    Revisa y firma el contrato con tu asesor
                                </Typography>
                </div>
                <div className="display_flex flex_align_start m_b_sm">
                                <div className="w_10_per display_flex">
                                    <Typography
                                        className="bg_primary_300 br_circle m_none w_20_px h_20_px display_flex flex_center"
                                        weight="600"
                                        scale="small"
                                        component="p"
                                    >
                                        2
                                    </Typography>
                                </div>
                                <Typography
                                    className="m_l_sm m_y_none w_80_per"
                                    weight="400"
                                    scale="small"
                                    component="p"
                                >
                                    Realiza el depósito de garantía y tu primera semanalidad
                                </Typography>
                </div>
                <div className="display_flex flex_align_start m_b_sm">
                                <div className="w_10_per display_flex">
                                    <Typography
                                        className="bg_primary_300 br_circle m_none w_20_px h_20_px display_flex flex_center"
                                        weight="600"
                                        scale="small"
                                    >
                                        3
                                    </Typography>
                                </div>
                                <Typography
                                    className="m_l_sm m_y_none w_90_per"
                                    weight="400"
                                    scale="small"
                                    component="p"
                                >
                                    Revisa tu moto y llévatela a rodar
                                </Typography>
                </div>
            </div>
        </div>
    </div>;
};

export const DeliveryDocumentsStep = () => {
    const {
        values,
        handleChange,
        errors,
        handleBlur,
        getFieldMeta,
        setFieldValue,
    } = useFormikContext<StepFormValues>();

    return <div className="w_100_per m_x_auto">
        <Typography className="text_center" weight="600" scale="medium" component="h2">Documentos para la entrega</Typography>
        <div className="m_y_md p_y_md w_80_per_desktop m_x_auto display_flex flex_gap_xxl">
            <Grid container>
                <Grid item md={4} xs={12} className="p_lg">
                    <UploadInput
                        onChange={() => {}}
                        accept="application/pdf"
                        title="Situación fiscal"
                        value={_.get(values, StepEnum.FISCAL_SITUATION) as any}
                        name={StepEnum.FISCAL_SITUATION}
                        placeholderIcon={<Invoice className="dim_xl" />}
                        error={
                            _.get(errors, StepEnum.FISCAL_SITUATION) &&
                            getFieldMeta(StepEnum.FISCAL_SITUATION).touched
                                ? (_.get(errors, StepEnum.FISCAL_SITUATION) as string)
                                : ""
                        }
                    />
                </Grid>
                <Grid item md={4} xs={12} className="p_lg">
                    <UploadInput
                        onChange={() => {}}
                        accept="application/pdf"
                        title="Pago por adelantado"
                        value={_.get(values, StepEnum.ADVANCED_MONEY) as any}
                        name={StepEnum.ADVANCED_MONEY}
                        placeholderIcon={<Invoice className="dim_xl" />}
                        error={
                            _.get(errors, StepEnum.ADVANCED_MONEY) &&
                            getFieldMeta(StepEnum.ADVANCED_MONEY).touched
                                ? (_.get(errors, StepEnum.ADVANCED_MONEY) as string)
                                : ""
                        }
                    />
                </Grid>
                <Grid item md={4} xs={12} className="p_lg">
                    <UploadInput
                        onChange={() => {}}
                        accept="application/pdf"
                        title="Depósito"
                        value={_.get(values, StepEnum.DEPOSIT) as any}
                        name={StepEnum.DEPOSIT}
                        placeholderIcon={<Invoice className="dim_xl" />}
                        error={
                            _.get(errors, StepEnum.DEPOSIT) &&
                            getFieldMeta(StepEnum.DEPOSIT).touched
                                ? (_.get(errors, StepEnum.DEPOSIT) as string)
                                : ""
                        }
                    />
                </Grid>
                <Grid item md={4} xs={12} className="p_lg">
                    <UploadInput
                        onChange={() => {}}
                        accept="application/pdf"
                        title="Primer pago"
                        value={_.get(values, StepEnum.FIRST_PAYMENT) as any}
                        name={StepEnum.FIRST_PAYMENT}
                        placeholderIcon={<Invoice className="dim_xl" />}
                        error={
                            _.get(errors, StepEnum.FIRST_PAYMENT) &&
                            getFieldMeta(StepEnum.FIRST_PAYMENT).touched
                                ? (_.get(errors, StepEnum.FIRST_PAYMENT) as string)
                                : ""
                        }
                    />
                </Grid>
            </Grid>
        </div>
    </div>;
};
