import { UtmParams } from "enums/utmParams.enum";
import { Utm } from "models/shared/interfaces/utm.interface";

export interface QueryParams {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    [key: string]: string | undefined;
}

export const getQueryParams = (search: string): QueryParams => {
    const params = new URLSearchParams(search);
    const result: QueryParams = {};

    params.forEach((value, key) => {
        if (result[key]) {
            result[key] = `${result[key]};${value}`;
        } else {
            result[key] = value;
        }
    });
    return result;
};

const saveQueryParamsToSession = (keys: string[]): void => {
    const queryParams: QueryParams = getQueryParams(window.location.search);
    Object.keys(queryParams).forEach((key: string) => {
        if (queryParams[key] && keys.includes(key)) {
            sessionStorage.setItem(key, queryParams[key] as string);
        }
    });
};

export const cleanUtmQueryParamsFromSession = (): void => {
    sessionStorage.removeItem(UtmParams.SOURCE);
    sessionStorage.removeItem(UtmParams.MEDIUM);
    sessionStorage.removeItem(UtmParams.CAMPAIGN);
};

export const saveUtmQueryParamsToSession = (): void => {
    saveQueryParamsToSession([UtmParams.SOURCE, UtmParams.MEDIUM, UtmParams.CAMPAIGN]);
};

export const getUtmObject = (): Utm | undefined => {
    const utmSource: string | null = sessionStorage.getItem(UtmParams.SOURCE);
    const utmMedium: string | null = sessionStorage.getItem(UtmParams.MEDIUM);
    const utmCampaign: string | null = sessionStorage.getItem(UtmParams.CAMPAIGN);
    let utm: Utm | undefined;

    if (utmSource && utmMedium && utmCampaign) {
        utm = {
            utmSource,
            utmMedium,
            utmCampaign
        };
    }
    return utm;
};
