/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign*/
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

import { ReactComponent as Calendar } from "design_system/src/static/icons/date.svg";
import { ReactComponent as Motorcycle } from "design_system/src/static/icons/motorcycle.svg";
import { ReactComponent as Speed } from "design_system/src/static/icons/speedometer.svg";
import { ReactComponent as Color } from "design_system/src/static/icons/color.svg";
import { ReactComponent as Cash } from "design_system/src/static/icons/money.svg";

import { UniqueSelectionFilter } from "components/uniqueSelectionFilter/UniqueSelectionFilter";
import useVehicles from "../../hooks/useVehicles";
import { updateSelectedFilters } from "../../store/actions/vehicles";
import CustomAccordion from "../accordion/CustomAcccordion";

interface Props {
  setCurrentPage: any;
  updateCatalogFilters: any;
}

const InputFilters: FC<Props> = ({ setCurrentPage, updateCatalogFilters }) => {
  const {
    loading,
    data: { vehicles, filters },
    selectedFilters: { year, brand, cylinders, mileages, color, model, salePrice } = {},
  } = useVehicles();
  const dispatch = useDispatch();

  const {
    brands = [],
    years = [],
    models = [],
    // eslint-disable-next-line camelcase
    cylinder_Capacities = [],
    colors = [],
    mileageRange = [],
    priceRange = [],
  } = filters ?? {};

  const yearOptions = useMemo(
    () =>
      vehicles && !loading
        ? [...years.map((y) => ({ label: y.toString(), value: y }))]
        : [],
    [vehicles, years, loading]
  );

  const cylinderOptions = useMemo(
    () =>
      vehicles && !loading
        ? [
            // eslint-disable-next-line camelcase
            ...cylinder_Capacities.map((c) => ({
              label: `${c} CC`,
              value: c,
            })),
            // eslint-disable-next-line camelcase
          ]
        : [],
    // eslint-disable-next-line camelcase
    [vehicles, cylinder_Capacities, loading]
  );

  const brandOptions = useMemo(
    () =>
      vehicles && !loading
        ? [
            // { label: "Selecciona marca", value: "" },
            ...brands.map((b) => ({
              label: `${b}`,
              value: b,
            })),
          ]
        : [],
    [vehicles, loading, brands]
  );

  const modelOptions = useMemo(
    () =>
      vehicles && !loading
        ? [
            // { label: "Selecciona el modelo", value: "" },
            ...models.map((b: any) => ({
              label: `${b}`,
              value: b,
            })),
          ]
        : [],
    [vehicles, loading, models]
  );

  const colorOptions = useMemo(
    () =>
      vehicles && !loading
        ? [
            ...colors.map((c) => {
              return { label: c.label, value: c.value };
            }),
            // @ts-ignore
          ].sort((a, b) => a.label - b.label || a.label.localeCompare(b.label))
        : [],
    [vehicles, loading, colors]
  );

  const mileageRangeOptions = useMemo(
    () =>
      vehicles && !loading
        ? [
            ...mileageRange.map((c, index) => ({
              label: index === mileageRange.length - 1 ? `Más de ${c.split(".")[0]}Km` : `De ${c.split(".")[0]}Km a ${c.split(".")[1]}Km`,
              value: c,
            })),
          ]
        : [],
    [vehicles, mileageRange, loading]
  );

  const priceRangeOptions = useMemo(
    () =>
      vehicles && !loading
        ? [
            ...priceRange.map((c, index) => ({
              label: index === priceRange.length - 1
                ? `Más de $${c.split(".")[0]}`
                : index === 0
                  ? `Menos de $${c.split(".")[1]}`
                  : `De $${c.split(".")[0]} a $${c.split(".")[1]}`,
              value: c,
            })),
          ]
        : [],
    [vehicles, priceRange, loading]
  );

  interface IconTypeProps {
    fill: string;
  }

  interface IAcordionItems {
    title: string;
    icon: any;
    expanded: boolean;
    children: any;
  }

  const acordionOptions: IAcordionItems[] = useMemo(
    () => [
      {
        title: "Marca",
        expanded: true,
        icon: (props: IconTypeProps) => <Motorcycle {...props} />,
        children: (
          !brand
            ? <UniqueSelectionFilter
              name="brand"
              value={brand}
              options={brandOptions}
              handleSelectChange={(value: any) => updateCatalogFilters({"brand": value})}
              direction="vertical"
            />
            : null
        ),
      },
      {
        title: "Modelo",
        expanded: true,
        icon: (props: IconTypeProps) => <Motorcycle {...props} />,
        children: (
          !model
            ? <UniqueSelectionFilter
              name="model"
              value={model}
              options={modelOptions}
              handleSelectChange={(value: any) => updateCatalogFilters({"model": value})}
              direction="vertical"
            />
            : null
        ),
      },
      {
        title: "Año",
        expanded: true,
        icon: (props: IconTypeProps) => <Calendar {...props} />,
        children: (
          !year
            ? <UniqueSelectionFilter
              name="years"
              value={year}
              options={yearOptions}
              handleSelectChange={(value: any) => updateCatalogFilters({"year": parseInt(value, 10)})}
            />
            : null
        ),
      },
      {
        title: "Cilindraje",
        expanded: true,
        icon: (props: IconTypeProps) => <Motorcycle {...props} />,
        children: (
          !cylinders
            ? <UniqueSelectionFilter
              name="cylinder"
              value={cylinders}
              options={cylinderOptions}
              handleSelectChange={(value: any) => updateCatalogFilters({"cylinders": parseInt(value, 10)})}
            />
            : null
        ),
      },
      {
        title: "Kilometraje",
        expanded: true,
        icon: (props: IconTypeProps) => <Speed {...props} />,
        children: (
          !mileages
            ? <UniqueSelectionFilter
              name="mileages"
              value={mileages}
              options={mileageRangeOptions}
              direction="vertical"
              handleSelectChange={(value: any) => {
                return updateCatalogFilters({"mileages": value.split(".").map((e: string) => parseInt(e, 10))});
              }}
            />
            : null
        ),
      },
      {
        title: "Color",
        expanded: true,
        icon: (props: IconTypeProps) => <Color {...props} />,
        children: (
          !color
            ? <UniqueSelectionFilter
              name="colors"
              value={color}
              options={colorOptions}
              handleSelectChange={(value: any) => updateCatalogFilters({"color": value})}
            />
            : null
        ),
      },
      {
        title: "Precio",
        expanded: true,
        icon: (props: IconTypeProps) => <Cash {...props} />,
        children: (
          !salePrice
            ? <UniqueSelectionFilter
              name="price"
              value={salePrice}
              options={priceRangeOptions}
              direction="vertical"
              handleSelectChange={(value: any) => updateCatalogFilters({"salePrice": value.split(".").map((e: string) => parseInt(e, 10))})}
            />
            : null
        ),
      },
    ],
    [brandOptions]
  );

  return loading ? (
    <div style={{ padding: 15 }} className="w_100_per">
      <Stack spacing={1}>
        <Skeleton variant="rounded" width="100%" height={50} />
        <Skeleton variant="rounded" width="100%" height={50} />
        <Skeleton variant="rounded" width="100%" height={50} />
        <Skeleton variant="rounded" width="100%" height={50} />
        <Skeleton variant="rounded" width="100%" height={50} />
        <Skeleton variant="rounded" width="100%" height={50} />
        <Skeleton variant="rounded" width="100%" height={50} />
      </Stack>
    </div>
  ) : (
    <CustomAccordion options={acordionOptions} />
  );
};

export default InputFilters;
