import React, {
    useContext,
    useEffect,
    useCallback,
    useRef,
    useState
} from "react";
import * as yup from "yup";
import _ from "lodash";
import { FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";

import { Typography } from "design_system/src";
import { ReactComponent as Moto } from "design_system/src/static/icons/motorcycle.svg";
import NewStepper, { IStepper } from "design_system/src/organisms/NewStepper/NewStepper/NewStepper";

import { phoneRegex } from "constants/regex.constants";
import { LeadFormFieldsEnum, LeadStatusEnum, LeadStepEnum } from "models/lead/enums/lead.enums";
import { FinancialFormStatusEnum } from "models/financialForm/enums/financialForm.enums";

import {
    fetchGetTempToken,
    fetchValidateCurp,
    fetchValidateOzoner
} from "models/auth-client/controllers/auth.controller";
import { createLead, updateLead, completeLead } from "models/lead/controllers/lead.controller";

import BreadCrumb from "components/breadcrumb/Breadcrumb";
import { toLeadCreate, toLeadUpdate } from "models/lead/helpers/formatData";
import { FormApplicationContext, IStepperFormValues } from "./context/context";
import { LeadtoFormValues, FormValuesToLead } from "./helpers/transforms";

import {
    StepFour,
    StepFourAndHalf,
    StepOne,
    StepOneAndHalf,
    StepThree,
    StepThreeAndHalf,
    StepTwo,
    StepZero,
} from "./components/Steps";
import { ModalConfirmAddress } from "./components/ModalConfirmAddress/ModalConfirmAddress";
import CallToAction from "./components/CallToAction/CallToAction";

import "./FormApplication.scss";

const curpRegex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

const validationSchema = yup.object().shape({
    [LeadStepEnum.emailStep]: yup.object().shape({
        email: yup
            .string()
            .email("Ingrese un correo valido")
            .required("Campo requerido"),
        termsOfServiceAcceptance: yup.object().shape({
            acceptedAt: yup.date().required("Campo requerido"),
            termsVersion: yup.string().required("Campo requerido"),
            acceptanceMethod: yup.string().required("Campo requerido"),
        }),
        privacyPolicyAcceptance: yup.object().shape({
            acceptedAt: yup.date().required("Campo requerido"),
            privacyPolicyVersion: yup.string().required("Campo requerido"),
            acceptanceMethod: yup.string().required("Campo requerido"),
        }),
    }),
    [LeadStepEnum.personalInformationStep]: yup.object().shape({
        name: yup.string().required("Campo requerido"),
        lastName: yup.string().required("Campo requerido"),
        phone: yup
            .string()
            .matches(phoneRegex, "Número inválido")
            .required("Campo requerido"),
        curp: yup
            .string()
            .required("Campo requerido")
            .matches(curpRegex, "CURP inválido"),
    }),
    [LeadStepEnum.addressStep]: yup.object().shape({
        fullAddress: yup.object().shape({
            zipCode: yup
                .string()
                .required("Debe ingresar un codigo postal")
                .matches(/^\d{5}$/, "Debe ser un número de 5 dígitos."),
            state: yup.string().required("Campo requerido"),
            delegation: yup.string().required("Campo requerido"),
            neighborhood: yup.string().required("Campo requerido"),
            street: yup.string().required("Campo requerido"),
            extNumber: yup.string().min(0, "Minimo cero").required("Campo requerido"),
            intNumber: yup.string().min(0, "Minimo cero"),
            lat: yup.number(),
            long: yup.number(),
        }),
    }),
    [LeadStepEnum.financialInformationStep]: yup.object().shape({
        monthlyIncome: yup
            .number()
            .required("Campo requerido")
            .lessThan(100001, "Debe ser menor a $100,000.00mxn")
            .positive("Debe ser mayor a $0.00mxn")
            .integer("Debe ser mayor a $0.00mxn"),
        monthlyOutcome: yup
            .number()
            .required("Campo requerido")
            .lessThan(100001, "Debe ser menor a $100,000.00mxn")
            .positive("Debe ser mayor a  $0.00mxn")
            .integer("Debe ser mayor a $0.00mxn"),
    }),
    [LeadStepEnum.dependantsStep]: yup.object().shape({
        dependantsCount: yup
            .number()
            .required("Campo requerido"),
        childrenCount: yup
            .number()
            .required("Campo requerido"),
    }),
    [LeadStepEnum.employmentAndEducationStep]: yup.object().shape({
        workInDigitalPlatforms: yup.bool(),
        economicActivity: yup.object().shape({
            value: yup.string().required("Campo requerido"),
            description: yup.string().test("", "Campo requerido", function (value) {
                if (this.parent.value === "otro") { return value ? value?.trim().length > 0 : false; }
                return true;
            }),
        }),
        companyName: yup.string(),
        educationalLevel: yup.string().required("Campo requerido"),
    }),
    [LeadStepEnum.civilStatusStep]: yup.object().shape({
        civilStatus: yup.string().required("Campo requerido"),
        livesWith: yup.array().of(yup.string()).min(1, "Al menos uno requerido"),
    }),
    [LeadStepEnum.assetsStep]: yup.object().shape({
        assets: yup.array().of(yup.string()),
    }),
});

export type StepsKeys = keyof typeof validationSchema.fields;

export const validateStep = (
    step: StepsKeys,
    values: IStepperFormValues
) => yup.reach(validationSchema, step).isValidSync(values[step as keyof IStepperFormValues]);

export const FormApplication = () => {
    const navigate = useNavigate();
    const breakpoint = 900;
    const matchesMD = useMediaQuery(`(min-width:${breakpoint}px)`);
    const formRef = useRef<HTMLDivElement>(null);

    const { loading: loadingLead, step, lead, setLead, cleanLead } = useContext(FormApplicationContext);
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const handleClose = () => setOpenConfirmation(!openConfirmation);

    const handleActiveFinancialForm = (exist: boolean, financialFormStatus: any, email: string): boolean => {
        if (exist && financialFormStatus === FinancialFormStatusEnum.REJECTED) {
            navigate("/rechazo-solicitud", {state:{ email }});
            return false;
        }
        if (exist && financialFormStatus === FinancialFormStatusEnum.PENDING) {
            navigate(`/documentos-en-proceso?email=${encodeURIComponent(email)}`);
            return false;
        }
        if (exist && financialFormStatus && financialFormStatus !== FinancialFormStatusEnum.PENDING) {
            navigate("/solicitud-en-proceso", {state:{
            email,
            status: financialFormStatus
            }});
            return false;
        }
        return true;
    };

    const onSubmitForm = async (values: IStepperFormValues) => {
        const id = lead._id!;
        const leadData = FormValuesToLead(values, {
            ...lead,
            status: LeadStatusEnum.completed
        });

        try {
            const { ozoner, financialForm } = await completeLead(
                id,
                toLeadUpdate(leadData)
            );
            cleanLead();
            if (financialForm.status === FinancialFormStatusEnum.REJECTED) {
                navigate("/rechazo-solicitud", {state:{ email: leadData.email }});
                return;
            }
            fetchGetTempToken(ozoner.email).then((data) => {
                if (data.financialFormId) {
                    navigate(`/financia-tu-moto/documentos/${data.financialFormId}`);
                }
            });

        } catch (e: any) {
            if (e.statusCode === 409) {
                const status = e.message.split(" ")[0];
                if (Object.values(FinancialFormStatusEnum).includes(status)) {
                    handleActiveFinancialForm(true, status, leadData.email!);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const form = useFormik<IStepperFormValues>({
        initialValues: LeadtoFormValues(lead),
        validationSchema,
        onSubmit: onSubmitForm,
        enableReinitialize: true,
    });

    const { values } = form;

    const createLeadFunction = async () => {
        setLoading(true);
        let leadData = FormValuesToLead(values, lead);

        if (lead._id) {
            leadData = await updateLead(lead._id, toLeadUpdate(leadData));
        } else {
            const newLeadData = await createLead(toLeadCreate(leadData));
            if (
                (leadData.loanDuration && leadData.loanDuration !== newLeadData.loanDuration) ||
                (leadData.vehicle && leadData.vehicle !== newLeadData.vehicle) ||
                (leadData.advancedMoney && leadData.advancedMoney !== newLeadData.advancedMoney)
            ) {
                leadData = await updateLead(newLeadData._id!, toLeadUpdate(leadData));
            } else {
                leadData = newLeadData;
            }
        }
        setLead(leadData);
        setLoading(false);
    };

    const updateLeadFunction = async () => {
        setLoading(true);
        let leadData = FormValuesToLead(values, lead);
        leadData = await updateLead(lead._id!, toLeadUpdate(leadData));
        setLead(leadData);
        setLoading(false);
    };

    const checkinFunction = async () => {
        setLoading(true);
        const {exist, financialFormStatus} = await fetchValidateOzoner({
            email: _.get(values, LeadFormFieldsEnum.EMAIL)!
        });
        const toContinue = handleActiveFinancialForm(
            exist,
            financialFormStatus,
            _.get(values, LeadFormFieldsEnum.EMAIL)!
        );
        if (toContinue) {
            await createLeadFunction();
            setLoading(false);
        }
    };

    const curpFunction = async () => {
        setLoading(true);
        await updateLeadFunction();
        const exist = await fetchValidateCurp(_.get(values, LeadFormFieldsEnum.CURP)!);
        setLoading(false);
        return exist;
    };

    const stepComponentsArr: Array<IStepper>[] = [
        [
            {
                component: <StepZero />,
                stepTitle: "Inicia tu proceso",
                validationKey: LeadStepEnum.emailStep,
                functionStep: checkinFunction
            }
        ],
        [
            {
                component: <StepOne />,
                stepTitle: "Información personal",
                validationKey: LeadStepEnum.personalInformationStep,
                functionStep: curpFunction
            },
            {
                component: <StepOneAndHalf />,
                validationKey: LeadStepEnum.addressStep,
                stepModalConfig: {
                    widthDesktop: 50,
                    heightDesktop: 90,
                    icon: <Moto />,
                    handleClose,
                    title: <Typography scale="medium" weight="400" textColor="primary_300">
                        Confirmar Ubicación
                    </Typography>,
                    subtitle: <ModalConfirmAddress />,
                },
                functionStep: updateLeadFunction
            },
        ],
        [
            {
                component: <StepTwo />,
                stepTitle: "Ingresos y gastos",
                validationKey: LeadStepEnum.financialInformationStep,
                functionStep: updateLeadFunction
            },
        ],
        [
            {
                component: <StepThree />,
                stepTitle: "Dependientes y empleo",
                validationKey: LeadStepEnum.dependantsStep,
                functionStep: updateLeadFunction
            },
            {
                component: <StepThreeAndHalf />,
                validationKey: LeadStepEnum.employmentAndEducationStep,
                functionStep: updateLeadFunction
            },
        ],
        [
            {
                component: <StepFour />,
                stepTitle: "Estado civil y Activos",
                validationKey: LeadStepEnum.civilStatusStep,
                functionStep: updateLeadFunction
            },
            {
                component: <StepFourAndHalf />,
                validationKey: LeadStepEnum.assetsStep
            },
        ],
    ];

    const scrollToForm = () => {
        if (formRef && formRef.current) {
            window.scrollTo({ top: formRef.current.getBoundingClientRect().top - 50, behavior: "smooth" });
        }
    };

    useEffect(() => {
        if (!loadingLead) {
            const activeStepArray = stepComponentsArr
                .filter((mainStep) => mainStep.filter(
                    (insideStep, _index) => insideStep.validationKey === step).length > 0)
                .map((value) => stepComponentsArr.indexOf(value));

            setActiveStep(activeStepArray.length > 0 ? activeStepArray[0] : 0);
        }
    }, [loadingLead]);

    useEffect(() => {
        setLead({
            ...lead,
            step: stepComponentsArr[activeStep][0].validationKey as LeadStepEnum
        });
    }, [activeStep]);

    return (
        <div className="dso_container">
            <BreadCrumb
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                stepperComponents={stepComponentsArr}
            />
            <FormikProvider value={form}>
                <div
                    style={{
                        flexDirection: matchesMD ? "row" : "column-reverse"
                    }}
                    className={
                        `display_flex flex_align_center form-application m_y_xxl${!matchesMD && activeStep > 0 ? " flex_gap_xxl" : " m_y_xl flex_gap_md"}`
                    }
                >
                    <CallToAction breakpoint={breakpoint} onClick={() => scrollToForm()} />

                    <div className="card-container dso_card" ref={formRef}>
                        <NewStepper
                            validateStep={validateStep}
                            stepperComponents={stepComponentsArr}
                            onSubmitForm={form.submitForm}
                            loading={loading}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            startTextButton="Comenzar"
                        />
                        <div className="p_y_xs p_x_md">
                            <Typography
                            scale="xsmall"
                            weight="400"
                            className="text_center"
                            >
                            *Sí detectamos información{" "}
                            <span className="text_red_300">falsa</span> o{" "}
                            <span className="text_red_300">engañosa</span> tu solicitud
                            será inmediatamente{" "}
                            <span className="text_red_300">rechazada</span>.
                            </Typography>
                        </div>
                    </div>
                </div>
            </FormikProvider>
        </div>
    );
};
