/* eslint-disable no-unused-vars */
import React, {useEffect} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";

import { RootState } from "store/index";

// components
import "./footer.scss";
// imgs
import { ReactComponent as FA } from "design_system/src/static/icons/facebook.svg";
import { ReactComponent as INS } from "design_system/src/static/icons/instagram.svg";
import { ReactComponent as TW } from "design_system/src/static/icons/twitter.svg";
import ozon from "static/logos/ozon.svg";

import { ReactComponent as WA } from "design_system/src/static/icons/whatsapp.svg";
import { ReactComponent as EMAIL } from "design_system/src/static/icons/email.svg";

import useVehicles from "hooks/useVehicles";

import { SOCIAL_MEDIA } from "constants/media.constants";
import { Typography } from "design_system/src";
import { ReactComponent as TIKTOK } from "./tiktok.svg";

export const Footer = () => {
  const location = useLocation();
  const catalogPathName = "/catalogo";

  const {
    loading: vehiclesLoading,
    data: { homeBrands },
  } = useVehicles();

  const handleEventOzocio = () => {
    ReactGA.event("CTA_ozocio_footer", {
      category: "Ozocio",
      label: "click ozocio from footer",
    });
  };

  const handleNet = (social: string) => {
    switch (social) {
      case "facebook":
        window.open(SOCIAL_MEDIA.MX.facebook, "_blank");
        break;
      case "twitter":
        window.open(SOCIAL_MEDIA.MX.twitter, "_blank");
        break;
      case "instagram":
        window.open(SOCIAL_MEDIA.MX.instagram, "_blank");
        break;
      case "tiktok":
        window.open(SOCIAL_MEDIA.MX.tiktok, "_blank");
        break;

      default:
        break;
    }
  };

  const handleLink = (type: string) => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=5215572356667&text=Hola%20me%20interesa%20saber%20sobre%20Ozon%20";
    if (type !== "mail") {
      window.open(whatsappLink, "_blank");
    }
  };

  const dispatch = useDispatch();

  return (
    <div className=" bg_neutral_900">
      <section className="dso_container p_xl">
        <div className="display_flex flex_row_desktop flex_col_mobile">
          <div className="m_x_md p_x_md_desktop m_y_xs_mobile p_y_xs_mobile">
            <Typography
              scale="medium"
              weight="600"
              className="text_neutral_600 m_b_md m_none"
              component="h4"
            >
              Canales de soporte
            </Typography>
            <address>
              <Typography
                scale="xsmall"
                weight="400"
                className="cursor_pointer display_flex flex_row flex_align_center text_neutral_0 custom_a"
                style={{ wordWrap: "break-word" }}
                // onClick={ () => handleLink('mail') }
                onClick={() => {
                  window.location.href = "mailto:soportemx@ozon.mobi";
                }}
                component="a"
              >
                <div className="display_flex flex_center p_y_xs p_r_xs">
                  <EMAIL className="dim_lg text_neutral_0" />
                </div>
                <div>soportemx@ozon.mobi</div>
              </Typography>
              <Typography
                scale="xsmall"
                weight="600"
                className="cursor_pointer display_flex flex_row flex_align_center text_neutral_0 custom_a"
                onClick={() => handleLink("wsp")}
                component="a"
              >
                <div className="display_flex flex_center p_y_xs p_r_xs">
                  <WA className="dim_lg text_green_300" />
                </div>
                <div>+521 5572356667</div>
              </Typography>
            </address>
          </div>
          <div className="m_x_md fcards p_x_xxl_desktop m_y_xs_mobile p_y_xs_mobile">
            <Typography
              scale="medium"
              weight="600"
              className="text_neutral_600 m_b_lg"
            >
              Ozoner
            </Typography>
            <Link to="/vende-tu-moto" className="custom_a overflow_ellipsis">
              <Typography
                scale="xsmall"
                weight="600"
                className="cursor_pointer text_neutral_0 m_b_lg"
                onClick={handleEventOzocio}
              >
                Vende tu moto
              </Typography>
            </Link>

            <Link to={catalogPathName} className="custom_a overflow_ellipsis">
              <Typography
                scale="xsmall"
                weight="600"
                className="cursor_pointer text_neutral_0 "
              >
                Compra tu moto
              </Typography>
            </Link>
          </div>
          {!vehiclesLoading && homeBrands.length > 0 && location.pathname !== catalogPathName && <div className="m_x_md fcards p_x_xxl_desktop m_y_xs_mobile p_y_xs_mobile">
            <Typography
              scale="medium"
              weight="600"
              className="text_neutral_600 m_b_lg"
            >
              Marcas
            </Typography>
            <div className="footer_brands">
              { homeBrands.map((brand) => <Link key={brand} to={`${catalogPathName}?brand=${brand}`} className="custom_a">
                <Typography
                  scale="xsmall"
                  weight="600"
                  className="footer_brand cursor_pointer text_neutral_0"
                  onClick={handleEventOzocio}
                >
                  {brand}
                </Typography>
              </Link>)
              }
            </div>
          </div>}

          <div className="display_flex flex_justify_end w_60_per display_none_mobile">
            <figure className="m_none">
              <img src={ozon} alt="ozon" className="w_200_px" />
            </figure>
          </div>
        </div>
      </section>
      <footer className="bg_neutral_1000 w_100_per p_y_md">
        <div className="dso_container display_flex flex_row_desktop flex_justify_between_desktop flex_col_mobile m_y_md_mobile">
          <div className="display_flex flex_center_mobile">
            <Link to="/terminos-y-condiciones" className="custom_a">
              <Typography
                scale="xsmall"
                weight="600"
                className="cursor_pointer text_neutral_0 p_x_md_desktop p_x_lg_mobile display_flex flex_center"
              >
                Términos y Condiciones
              </Typography>
            </Link>

            <Link to="/aviso-de-privacidad" className="custom_a">
              <Typography
                scale="xsmall"
                weight="600"
                style={{ borderLeft: "1px solid #424242" }}
                className="cursor_pointer text_neutral_0 p_x_md_desktop p_x_lg_mobile display_flex flex_center"
              >
                Aviso de Privacidad
              </Typography>
            </Link>
          </div>
          <div className="display_flex flex_center m_y_md_mobile">
            <Typography
              scale="xsmall"
              weight="600"
              // className="bg_neutral_1000 p_y_xl text_center"
              className="text_neutral_600"
            >
              OZON® Todos los derechos reservados. {new Date().getFullYear()}
            </Typography>
          </div>
          <div className="display_flex flex_align_center_mobile flex_justify_between_mobile m_y_md_mobile">
            <nav className="display_flex flex_row">
              <div
                className="flex_center br_md  dim_xl m_x_md cursor_pointer "
                onClick={() => handleNet("facebook")}
              >
                <FA className="dim_lg_mobile text_neutral_0" />
              </div>
              <div
                className="flex_center br_md  dim_xl m_x_md cursor_pointer "
                onClick={() => handleNet("twitter")}
              >
                <TW className="dim_lg_mobile text_neutral_0" />
              </div>
              <div
                className="flex_center br_md dim_xl m_x_md cursor_pointer"
                onClick={() => handleNet("instagram")}
              >
                <INS className="dim_lg_mobile text_neutral_0" />
              </div>
              <div
                className="flex_center br_md dim_xl m_x_md cursor_pointer"
                onClick={() => handleNet("tiktok")}
              >
                <TIKTOK className="dim_lg_mobile dim_20_px_desktop text_neutral_0" />
              </div>
            </nav>
            <div className="display_none_desktop">
              <figure className="m_none">
                <img src={ozon} alt="ozon" className="w_100_px" />
              </figure>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
