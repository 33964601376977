/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
} from "design_system/src";
import { ReactComponent as Motocycle } from "design_system/src/static/icons/Motocycle.svg";

import useLocalStorage from "hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import ResetButton from "components/ResetButton/ResetButton";
import { TVehicle } from "models/inventory/interfaces/vehicle.interface";
import "./LoanApplicationApproved.scss";
import { mockVehicleList } from "models/inventory/mocks/vehicle.mock";
import { prices } from "helpers/prices";
import { formatPrice } from "helpers/formatPrice";

const LoanApplicationApproved = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [storedValue, setValue] = useLocalStorage<any>("loanApplication", null);
  const [maxPaymentWeek, setMaxPaymentWeek] = useState<number>(600);
  const [vehicle, setVehicle] = useState<TVehicle | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (storedValue === null && !loading) {
      navigate("/solicitud-credito");
    } else {
      setLoading(false);
      if (storedValue !== null && storedValue.vehicle) {
        setVehicle(mockVehicleList.filter(({ internalId }) => storedValue.vehicle.internalId === internalId)[0]);
      }
    }
  }, [storedValue, loading]);

  return (
    <div className="dso_container" style={{ minHeight: "75vh" }}>
      {!loading && <>
        <div className="m_y_lg_mobile m_y_xxxl_desktop m_x_auto w_400_px_desktop">
          <Typography className="m_y_none m_b_md text_center" weight="600" scale="heading3" scaleMobile="heading4" component="h1">
            Tienes un aprobado por <br />{maxPaymentWeek} MXN semanal
          </Typography>
          <Typography className="m_y_none m_b_md text_center" weight="400" scale="medium" scaleMobile="medium" component="p">
            Este valor esta sujerto a cambios según las validaciones en credito.
          </Typography>

          {!vehicle && <div>
            <div className="m_y_md bg_neutral_200 border_solid border_1 border_neutral_500 p_xl">
              <div className="display_flex flex_justify_start flex_align_center">
                <Motocycle className="custom-icon"/>
                <Typography className="m_l_md flex_grow_1" weight="400" scale="small" scaleMobile="small" component="p">
                  Aún no has seleccionado una moto.
                </Typography>
                <Button
                  scale="small"
                  color="neutral"
                  onClick={() => navigate("/solicitud-credito/vehiculos")}
                >
                  Escoger una
                </Button>
              </div>
            </div>
            <div className="display_flex flex_col">
              <Typography className="m_x_md text_center" weight="400" scale="small" scaleMobile="small" component="p">
                También puedes ir llenando los documentos requeridos para la validación final.
              </Typography>
              <Button
                scale="small"
                onClick={() => navigate("/solicitud-credito/documentos")}
              >
                Subir documentos
              </Button>
            </div>
          </div>}

          {vehicle && <div>
            <div className="m_y_md bg_green_200 border_solid border_1 border_green_300 p_xl">
              <div className="display_flex flex_justify_start flex_align_center">
                <Motocycle className="custom-icon"/>
                <div className="m_l_md flex_grow_1">
                  <Typography className="m_y_none" weight="600" scale="medium" scaleMobile="medium" component="p">
                    {vehicle.brand.name} {vehicle.model.name} {vehicle.cylindersCapacity} CC
                  </Typography>
                  <Typography className="m_y_none"  weight="400" scale="small" scaleMobile="small" component="p">
                    {vehicle.year} | {vehicle.mileage} Km
                  </Typography>
                  <Typography className="m_b_none" weight="600" scale="heading4" scaleMobile="heading4" component="p">
                    ${formatPrice(prices(vehicle?.getWeeklyPrice(78)), "")}
                    <span style={{ fontSize: "12px"}}> / semana</span>
                  </Typography>
                </div>
                <Button
                  scale="small"
                  color="neutral"
                  variant="ghost"
                  onClick={() => navigate("/solicitud-credito/vehiculos")}
                >
                  Cambiar
                </Button>
              </div>
            </div>
            <div className="display_flex flex_col m_y_md">
              <Typography className="m_x_md text_center" weight="400" scale="small" scaleMobile="small" component="p">
                Para continuar debes completar la siguiente documentación.
              </Typography>
              <Button
                scale="small"
                onClick={() => navigate("/solicitud-credito/documentos")}
              >
                Subir documentos
              </Button>
            </div>
          </div>}
        </div>
        <ResetButton />
      </>}
    </div>
  );
};

export default LoanApplicationApproved;
