/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from "react";
import {Typography} from "design_system/src";
import Button from "@mui/material/Button";

type AssetCardProps = {
  item: any;
  assetsInfo: any;
  setAssetsInfo: any;
}

const AssetCard: React.FC<AssetCardProps> = ({
  item,
  assetsInfo,
  setAssetsInfo
}) => {
  const {isActive, text, icon}= item;
  const assetCardStyle = {
    padding: 2,
    marginTop: 2,
    marginRight: 2,
    border: "0px solid ",
    borderRadius: 3,
    backgroundColor: isActive ? "orange" : "white",
    boxShadow: "2px 4px 8px rgba(34, 38, 42, 0.05), 4px 8px 24px rgba(34, 38, 42, 0.1)",
    "&:hover": {
      border: "0px solid ",
      backgroundColor: "#fe8a02",
      opacity: [0.9, 0.8, 0.7],
      cursor: "pointer",
      color: "white"
    },
  };

  return (
    <Button
      color="info"
      onClick={() => setAssetsInfo(!item.isActive)}
      variant={isActive? "contained":"outlined"}
      sx={assetCardStyle}
      disableElevation={!!isActive}
      className="text_capitalize"
    >
      <div className="w_60_px h_100_per display_flex flex_align_center flex_justify_end" style={{display:"flex", flexDirection:"column"}}>
        <img src={icon} alt="cardImage" width="47" />
        <Typography
          scale="small"
          weight="600"
          className=" m_t_xs"
          textColor={isActive ? "neutral_100": "neutral_900"}
        >
          {text}
        </Typography>
      </div>
    </Button>
  );
};

export default AssetCard;
