/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { FormikProvider, useFormik } from "formik";
import _ from "lodash";

import { Button, Modal, Typography } from "design_system/src";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import ResetButton from "components/ResetButton/ResetButton";
import useLocalStorage from "hooks/useLocalStorage";
import { initialValues, StepEnum, StepFormValues, StepType, validationSchema } from "./constants";
import { StepperItem, StepperItemProps } from "./components/stepperItem/StepperItem";
import { AddressStep, IneStep, ReferencesStep, SelfieStep } from "./steps";

const documentSchema = (): StepperItemProps[] => [
  {
      step: StepEnum.INDENTITY_STEP,
      title: "INE legible por ambas caras",
      completed: true,
      active: false,
      enabled: true,
      documentsRequired: ["ine_front", "ine_back"],
      documents: [],
      component: <IneStep />
  },
  {
      step: StepEnum.SELFIE_STEP,
      title: "Selfie",
      completed: true,
      active: false,
      enabled: true,
      documentsRequired: ["selfie"],
      documents: [],
      component: <SelfieStep />
  },
  {
      step: StepEnum.ADDRESS_STEP,
      title: "Comprobante de domicilio",
      completed: true,
      active: false,
      enabled: true,
      documentsRequired: ["proof_of_address"],
      documents: [],
      component: <AddressStep />
  },
  {
      step: StepEnum.REFERENCES_STEP,
      title: "Referencias",
      completed: false,
      active: false,
      enabled: true,
      component: <ReferencesStep />
  },
];

const LoanApplicationDocuments = () => {
  const navigate = useNavigate();
  const matchesMD = useMediaQuery("(min-width:924px)");
  const matchesXS = useMediaQuery("(min-width:600px)");
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<StepType | null>(null);

  const [documentSection, setDocumentSection] = useState<StepperItemProps[]>(() => documentSchema());
  const [completed, setCompleted] = useState(false);
  const [stepBody, setStepBody] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [storedValue, setStoredValue] = useLocalStorage<any>("loanApplication", null);

  useEffect(() => {
    if (storedValue === null && !loading) {
      navigate("/solicitud-credito");
    } if (storedValue !== null && storedValue.loanApplicationDocuments && !loading) {
      navigate("/solicitud-credito/final");
    } else {
      setLoading(false);
    }
  }, [storedValue, loading]);

  const handleActiveSection = (value: StepType | null) => {
      setActiveSection(value);
  };

  useEffect(() => {
      setOpenDetail(matchesMD);
      if (matchesMD) {
          handleActiveSection(StepEnum.INDENTITY_STEP);
      }
  }, [matchesMD]);

  useEffect(() => {
      setDocumentSection(documentSection.map((e) => {
          e.active = e.step === activeSection;
          return e;
      }));
      setStepBody(documentSection
          .filter(({step}) => step === activeSection)
          .map(({component}) => component)[0]
      );

      if (!matchesMD) {
          setOpenDetail(activeSection !== null);
      }
  }, [activeSection]);

  const form = useFormik<StepFormValues>({
      initialValues,
      validationSchema,
      onSubmit: () => {},
  });

  useEffect(() => {
      const refErrors = _.get(form.errors, StepEnum.REFERENCES_STEP);
      setDocumentSection(documentSection.map((e) => {
          if (e.step === StepEnum.REFERENCES_STEP) {
              e.completed = refErrors === undefined;
          }
          return e;
      }));
  }, [form.errors]);

  const handleBackButton = () => {
      window.scrollTo(0, 0);
      setActiveSection(null);
  };

  const handleContinueButton = async () => {
      if (activeSection) {
          const errors = _.get(form.errors, activeSection);
          if (!errors) {
              if (activeSection === StepEnum.REFERENCES_STEP && form.isValid) {
                setCompleted(true);
              }

              let currentIndex = 0;
              documentSection.forEach((e, index) => {
                  if (e.step === activeSection) {
                      currentIndex = index;
                  }
              });
              currentIndex = currentIndex + 1 < documentSection.length ? currentIndex : -1;
              handleActiveSection(documentSection[currentIndex + 1].step as StepType);
          } else {
              form.setTouched({
                  [activeSection]: errors
              }, true);
          }
      }
  };

  const onContinue = () => {
    setStoredValue({
      ...storedValue,
      ...form.values,
      loanApplicationDocuments: true
    });
  };

  return (
    <div className="dso_container" style={{ minHeight: "75vh" }}>
      {!loading && <>
        <div className="m_y_lg_mobile m_y_xxxl p_y_xxl_mobile display_flex_desktop">
          {(!openDetail || matchesMD) && <div
              className={`w_100_per_mobile ${matchesXS && !matchesMD ? " w_300_px m_x_auto" : ""}${matchesMD ? " w_400_px" : ""}`}
          >
              <div className="p_t_md_desktop">
                  <Typography className="m_y_none m_b_md text_center" weight="600" scale="heading3" scaleMobile="heading4" component="h1">
                    Última validación: documentos y referencias
                  </Typography>
                  <Typography className="m_y_none text_center_mobile" weight="400" scale="medium">
                    Para completar tu solicitud, necesitamos que subas tus documentos y agregues tus referencias. Este último paso nos ayudará a avanzar a la entrega de tu moto.
                  </Typography>
              </div>
              <div className="m_y_lg">
                  {documentSection.map((item, index) => {
                      return <StepperItem
                          key={index}
                          props={item}
                          position={{
                              index,
                              lastPosition: index === documentSection.length - 1,
                              action: (value: StepType) => handleActiveSection(value)
                          }}
                      />;
                  })}
              </div>
          </div>}
          {openDetail && <div className="w_100_per_mobile flex_grow_1_desktop">
              <div className="shadow_hard_desktop br_xs_desktop m_l_sm_desktop p_x_xl_desktop p_y_xxxl_desktop">
                  <FormikProvider value={form}>
                      {stepBody}
                      <div className="w_100_per_mobile w_300_px_desktop m_b_md m_x_auto">
                          <Button className="w_100_per" scale="small" onClick={handleContinueButton}>
                              <Typography weight="600" scale="medium" scaleMobile="medium">
                                  {(form.isValid && activeSection === StepEnum.REFERENCES_STEP) ? "Terminar" : "Continuar"}
                              </Typography>
                          </Button>
                      </div>
                      {!matchesMD && <Button className="w_100_per p_y_none" scale="small" variant="ghost" onClick={handleBackButton}>
                          <Typography weight="600" scale="medium" scaleMobile="medium" textColor="neutral_700">
                              Volver
                          </Typography>
                      </Button>}
                  </FormikProvider>
              </div>
          </div>}
        </div>
        <Modal open={completed} setOpen={setCompleted} className="">
            <Typography scale="large" weight="400" className="m_b_sm text_center">
                ¡Felicidades! Has completado la documentación necesaria
            </Typography>
            <Typography scale="small" weight="400" className="m_b_xl text_center">
                Estamos revisando los documentos que nos enviaste. Te daremos una respuesta muy pronto.
            </Typography>
            <Button
              variant="principal"
              scale="small"
              className="w_100_per"
              onClick={onContinue}
            >
              Continuar
            </Button>
        </Modal>
        <ResetButton />
      </>}
    </div>
  );
};

export default LoanApplicationDocuments;
