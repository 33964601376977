/* eslint-disable no-unused-vars */
import { TVehicle } from "models/inventory/interfaces/vehicle.interface";
import { TFinancialForm } from "models/financialForm/interfaces/financialForm.interface";
import { EvidenceDocument } from "models/shared/interfaces/shared.interface";
import { checkVehicleAvailability, getFinancialForm } from "models/financialForm/controllers/financialForm.controller";
import { types } from "../types/types";


export const getFinancialFormStart = () => ({
  type: types.financialFormStart
});

export const setFinancialFormSucceed = (financialForm: TFinancialForm | null) => ({
  type: types.financialForm,
  payload: { financialForm }
});

export const updateDocumentStart = () => ({
  type: types.financialFormUpdateDocumentStart
});

export const updateDocumentSucceed = (documents: EvidenceDocument[]) => ({
  type: types.financialFormUpdateDocument,
  payload: { documents }
});

export const getVehicleStart = () => ({
  type: types.financialFormVehicleStart
});

export const getVehicleSucceed = (vehicle: TVehicle | null) => ({
  type: types.financialFormVehicle,
  payload: { vehicle }
});

export const getVehicleAvailabilityStart = () => ({
  type: types.financialFormVehicleAvailabilityStart
});

export const getVehicleAvailabilitySucceed = (vehicleAvailability: boolean) => ({
  type: types.financialFormVehicleAccesibility,
  payload: { vehicleAvailability }
});

export const removeFinancialForm = () => ({
  type: types.financialFormRemove
});


const fetchFinancialForm = (id: string) => async (dispatch: any) => {
  dispatch(getFinancialFormStart());
  const result = await getFinancialForm(id);

  if (result) {
    const financialForm = new TFinancialForm(result);
    dispatch(setFinancialFormSucceed(financialForm));

    if (result.vehicle) {
      const vehicle = new TVehicle(result.vehicle);
      dispatch(getVehicleSucceed(vehicle));
    }

    const documents = [
      ...financialForm.ozoner.documents,
      ...financialForm.documents
    ];
    dispatch(updateDocumentSucceed(documents));

    return financialForm;
  }

  dispatch(setFinancialFormSucceed(null));
  return null;
};


const fetchVehicleAvailability = (id: string) => async (dispatch: any) => {
  try {
    dispatch(getVehicleAvailabilityStart());
    const result = await checkVehicleAvailability(id);

    if (result) {
      dispatch(getVehicleAvailabilitySucceed(true));
    }

  } catch (e) {
    dispatch(getVehicleAvailabilitySucceed(false));
  }
};


export { fetchFinancialForm, fetchVehicleAvailability };
