import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { Typography, Button } from "design_system/src";
import { ReactComponent as Rigth } from "design_system/src/static/icons/Rigth.svg";
import Moto1 from "static/animation/moto_1.png";
import Moto2 from "static/animation/moto_2.png";
import Moto3 from "static/animation/moto_3.png";
import Moto4 from "static/animation/moto_4.png";
import Moto5 from "static/animation/moto_5.png";
import Moto6 from "static/animation/moto_6.png";
import Moto7 from "static/animation/moto_7.png";
import Moto8 from "static/animation/moto_8.png";

import "./CallToAction.scss";

const CallToAction: React.FC<{ breakpoint: number, onClick: any }> = ({ breakpoint, onClick }) => {
    const matchesMD = useMediaQuery(`(min-width:${breakpoint}px)`);
    const minHeightGIF = matchesMD ? 400 : 250;
    const scaleFactor = matchesMD ? 0.2 : 0.1;
    const motos  = [
        Moto1,
        Moto2,
        Moto3,
        Moto4,
        Moto5,
        Moto6,
        Moto7,
        Moto8,
    ];

    return (
        <div className="CallToAction_section">
            <div className="CallToAction-animation">
                {motos.map((img, index) => (
                    <div
                        key={index}
                        className={`CallToAction-animation__image CallToAction-animation__image--${index+1}`}
                        data-image={img}
                        style={{
                            minHeight: `${minHeightGIF}px`,
                            maxHeight: `${minHeightGIF * (1 + scaleFactor)}px`,
                            marginTop: `-${minHeightGIF * scaleFactor}px`,
                            backgroundImage: `url(${img})`
                        }}
                    />
                ))}
                <div
                    className="display_flex flex_align_center flex_col"
                    style={{
                        paddingTop: `${minHeightGIF * (1 - scaleFactor)}px`
                    }}
                >
                    <Typography
                        scale="heading3"
                        weight="600"
                        className="text_center"
                    >
                        ¡Da el primer paso hacia tu <span className="text_primary_300">nueva motocicleta!</span>
                    </Typography>
                    <Typography
                        scale="large"
                        weight="400"
                        className="text_center w_80_per_desktop"
                    >
                        Completa el formulario y comienza tu solicitud de crédito hoy mismo.
                    </Typography>
                    <div
                        className="display_flex flex_align_center flex_justify_center p_t_md CallToAction_btn"
                    >
                        <Typography scale="medium" weight="600">
                            Te tomará <span style={{ fontWeight: "bold" }}>solo 5 minutos, ¿qué esperas?</span>
                        </Typography>
                        <Rigth
                            className="text_primary_300 m_x_xs"
                            onClick={() => onClick()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CallToAction;
