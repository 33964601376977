/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum ValidationIdentityFormEnum {
    INDENTITY_STEP="identityVerificationStep",
    SELFIE_STEP="selfieVerificationStep",
    ADDRESS_STEP="validationAddressStep",
    CERTIFICATES_STEP="validationDocumentsStep",
    REFERENCES_STEP="validationReferencesStep",
    RESERVATION_RECEIPT_STEP="reservationReceiptStep",

    INE_FRONT="identityVerificationStep.ine_front",
    INE_BACK="identityVerificationStep.ine_back",
    SELFIE="selfieVerificationStep.selfie",
    PROOF_OF_ADDRESS = "validationAddressStep.proof_of_address",
    BANK_CERTIFICATE_1 = "validationDocumentsStep.bank_certificate_1",
    BANK_CERTIFICATE_2 = "validationDocumentsStep.bank_certificate_2",
    BANK_CERTIFICATE_3 = "validationDocumentsStep.bank_certificate_3",

    REFERENCE_1 = "validationReferencesStep.personalReference1",
    REFERENCE_1_NAME = "validationReferencesStep.personalReference1.name",
    REFERENCE_1_PHONE = "validationReferencesStep.personalReference1.phone",
    REFERENCE_1_RELATION = "validationReferencesStep.personalReference1.relationship",
    REFERENCE_2 = "validationReferencesStep.personalReference2",
    REFERENCE_2_NAME = "validationReferencesStep.personalReference2.name",
    REFERENCE_2_PHONE = "validationReferencesStep.personalReference2.phone",
    REFERENCE_2_RELATION = "validationReferencesStep.personalReference2.relationship",

    RESERVATION_RECEIPT = "reservationReceiptStep.layawayReceipt",
}
