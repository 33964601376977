import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import { Button, Typography } from "design_system/src";
import { FinancialFormStatusEnum } from "models/financialForm/enums/financialForm.enums";

import imageVehicle from "../images/image-moto.png";
import imageLogo from "../images/ozon-logo.png";
import imageMoney from "../images/money.svg";
import imageComu from "../images/comunication.svg";
import imageIn24 from "../images/in24.svg";

type State = {
  email: string;
  status: FinancialFormStatusEnum.IN_VALIDATION | FinancialFormStatusEnum.APPROVED | FinancialFormStatusEnum.SCHEDULED;
}

export const SummaryView = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!(state && (
        state.status === FinancialFormStatusEnum.IN_VALIDATION ||
        state.status === FinancialFormStatusEnum.APPROVED ||
        state.status === FinancialFormStatusEnum.SCHEDULED ||
        state.status === FinancialFormStatusEnum.COMPLETED))) {
      navigate("/");
    } else {
      ReactGA.event("VIEW_Financial_completed", {
        category: "Emails",
        label: "page view for ozoners with completed financial form",
      });
    }
  }, []);

  return (
    <div className="dso_container" style={{ minHeight: "50vh" }}>
      <div className="display_flex flex_gap_xs_desktop flex_gap_md_mobile flex_col m_y_xl">
        <div className="w_100_per p_t_xl_desktop p_t_md_mobile">
          <Typography
            scale="heading3"
            scaleMobile="large"
            weight="600"
            className="text_center"
          >
            Hola, parece que ya tienes una solicitud en proceso
          </Typography>
        </div>
        <div className="w_100_per">
          <Typography
            scale="large"
            scaleMobile="medium"
            weight="400"
            className="text_center display_flex_mobile flex_center_mobile flex_gap_xs_mobile flex_col_mobile"
          >
            Para hacer{" "}
            <span className="text_primary_300" style={{ fontWeight: 600 }}>
            seguimiento a tu solicitud
            </span>
            {" "}puedes ingresar a Mi Cuenta
          </Typography>
          <div className="w_100_per flex_center p_y_md">
            <Button
              orientation="right"
              onClick={() => navigate("/mi-cuenta")}
              className="display_none_mobile"
              variant="outline"
            >
              Ir a Mi Cuenta
            </Button>
            <Button
              scale="small"
              orientation="right"
              onClick={() => navigate("/mi-cuenta")}
              className="display_none_desktop"
              variant="outline"
            >
              Ir a Mi Cuenta
            </Button>
          </div>
        </div>
        <div className="dso_card w_100_per display_flex flex_gap_md_desktop flex_gap_lg_desktop flex_col_mobile bg_neutral_100 m_y_lg_desktop">
          <div className="flex_1_desktop w_100_per_mobile display_flex flex_col p_l_xl_desktop p_t_xl p_x_xl_mobile">
            <div className="display_flex flex_gap_md p_b_md_desktop">
              <div className="display_flex flex_align_center">
                <img src={imageLogo} alt="ozon-logo" />
              </div>
              <div className="display_flex flex_align_center">
                <Typography
                  scale="large"
                  scaleMobile="medium"
                  weight="600"
                  className="text_neutral_500"
                >
                  ¿Por qué comprar con nosotros?
                </Typography>
              </div>
            </div>
            <div className="display_flex flex_center_mobile flex_gap_xs_desktop flex_col_mobile">
              <Typography
                scale="heading3"
                weight="600"
                className=""
                scaleMobile="large"
              >
                Más de
              </Typography>
              <Typography
                scale="heading2"
                scaleMobile="heading1"
                weight="600"
                className="text_primary_300"
              >
                3000
              </Typography>
              <Typography
                scale="heading3"
                weight="600"
                className=""
                scaleMobile="large"
              >
                Motos Financiadas!
              </Typography>
            </div>
            <div className="p_b_xl_desktop">
              <Typography
                scale="large"
                weight="400"
                className="text_center_mobile"
                scaleMobile="medium"
              >
                Conoce todos los Beneficios OZON que cuentas al comprar tu
                siguiente moto con nosotros:
              </Typography>
            </div>
            <div className="display_none_mobile display_flex_desktop flex_gap_md flex_center">
              <div className="flex_1 display_flex flex_align_center flex_col h_100_per">
                <div>
                  <img src={imageIn24} alt="in-24" />
                </div>
                <div>
                  <Typography
                    scale="medium"
                    weight="600"
                    className="text_center"
                  >
                    Fácil y Rápido
                  </Typography>
                </div>
                <div>
                  <Typography
                    scale="medium"
                    weight="400"
                    className="text_center"
                  >
                    En 24 horas.
                  </Typography>
                </div>
              </div>
              <div className="flex_1 display_flex flex_align_center flex_col h_100_per">
                <img src={imageComu} alt="comunication" />
                <div>
                  <Typography
                    scale="medium"
                    weight="600"
                    className="text_center"
                  >
                    Soporte Personalizado
                  </Typography>
                </div>
                <div>
                  <Typography
                    scale="medium"
                    weight="400"
                    className="text_center"
                  >
                    Comunicación con agentes especializados.
                  </Typography>
                </div>
              </div>
              <div className="flex_1 display_flex flex_align_center flex_col h_100_per">
                <img src={imageMoney} alt="money" />
                <div>
                  <Typography
                    scale="medium"
                    weight="600"
                    className="text_center"
                  >
                    Compra Financiada
                  </Typography>
                </div>
                <div>
                  <Typography
                    scale="medium"
                    weight="400"
                    className="text_center"
                  >
                    No necesitas de enganche ni historial crediticio.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="flex_1_desktop w_100_per display_flex flex_center">
            <img
              src={imageVehicle}
              alt="promo"
              className="w_100_per_mobile w_80_per"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
