import React, { FC } from 'react';
import './styles.scss';

interface Props {}

const StepperBody: FC<Props> = ({ children }) => (
	<div className="stepper-body">{children}</div>
);

export default StepperBody;
