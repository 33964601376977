import React, { useState, useEffect } from "react";
import { FormikProvider, useFormik } from "formik";
import { useMediaQuery } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { indexOf } from "lodash";
import moment from "moment";

import { Button, Typography } from "design_system/src";

import { store } from "store";
import { useFinancialForm } from "hooks/useFinancialForm";
import useLocalStorage from "hooks/useLocalStorage";
import { FinancialFormStatusEnum, FinancialFormDocsEnum } from "models/financialForm/enums/financialForm.enums";

import { StepperItemProps, StepperItem, descriptionItemProps } from "../LoanApplicationDocuments/components/stepperItem/StepperItem";
import { LoanValidationStep, ReservationStep, SchedulingStep, DeliveryDocumentsStep } from "./steps";

import { initialValues, step1, step2, step3, step4, StepFormValues, validationSchema, validateStep } from "./constants";

const initialSchema: StepperItemProps[] = [
    {
        step: step1,
        title: "Estado de la validación",
        completed: false,
        active: true,
        enabled: true,
        component: <LoanValidationStep/>
    },
    {
        step: step2,
        title: "Aparta tu moto",
        completed: false,
        active: false,
        enabled: false,
        component: <ReservationStep/>
    },
    {
        step: step3,
        title: "Agenda la entrega",
        completed: false,
        active: false,
        enabled: false,
        component: <SchedulingStep/>
    },
    {
        step: step4,
        title: "Sube los documentos finales",
        completed: false,
        active: false,
        enabled: false,
        component: <DeliveryDocumentsStep/>
    },
];

const LoanApplicationFinal = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [storedValue, setStoredValue] = useLocalStorage<any>("loanApplication", null);

    useEffect(() => {
        if (storedValue === null && !loading) {
            navigate("/solicitud-credito");
        } else {
            setLoading(false);
        }
    }, [storedValue, loading]);

    const matchesMD = useMediaQuery("(min-width:924px)");
    const matchesXS = useMediaQuery("(min-width:600px)");
    const [openDetail, setOpenDetail] = useState<boolean>(matchesMD);

    useEffect(() => {
        setOpenDetail(matchesMD);
    }, [matchesMD]);

    const [currentStep, setCurrentStep] = useState<string | null>(step1);
    const [stepSchema, setStepSchema] = useState<StepperItemProps[]>(initialSchema);
    const [showContinueBtn, setShowContinueBtn] = useState<boolean>(true);

    const formik = useFormik<StepFormValues>({
        initialValues,
        validationSchema,
        onSubmit: () => {},
    });

    const { values } = formik;

    useEffect(() => {
        const newStepSchema: StepperItemProps[] = [];
        stepSchema.forEach((item, index) => {
            newStepSchema.push({
                ...item,
                enabled: index === 0 || newStepSchema[index - 1].completed,
                completed: (index === 0 || newStepSchema[index - 1].completed) && validateStep(item.step, values),
            });
        });
        setStepSchema(newStepSchema);
    }, [values]);

    const handleContinueButton = () => {
        const index = indexOf(stepSchema.map((e) => e.step), currentStep);
        if (stepSchema[index + 1]) {
            window.scrollTo(0, 0);
            setCurrentStep(stepSchema[index + 1].step);
        }
    };

    useEffect(() => {
        setStepSchema(stepSchema.map((item) => {
            return {
                ...item,
                active: item.step === currentStep
            };
        }));
    }, [currentStep]);

    const handleBackButton = () => {
        window.scrollTo(0, 0);
        setCurrentStep(null);
    };

    const isValid = () => {
        return currentStep && validateStep(currentStep, values);
    };

    return <div className="dso_container" style={{ minHeight: "75vh" }}>
        <div className="m_y_lg_mobile m_y_xxxl p_y_xxl_mobile display_flex_desktop">
            {(!openDetail || matchesMD) && <div className={`w_100_per_mobile ${matchesXS && !matchesMD ? " w_300_px m_x_auto" : ""}${matchesMD ? " w_400_px" : ""}`}>
                <div className="p_t_xxxl_desktop">
                    <Typography className="m_y_none m_b_md text_center" weight="600" scale="heading3" scaleMobile="heading4" component="h1">
                        Últimos pasos hacia tu crédito
                    </Typography>
                    <Typography className="m_y_none text_center_mobile" weight="400" scale="medium">
                        Solo faltan algunos detalles para finalizar. Sigue cada uno de los pasos para llegar a la moto de tus sueños. ¡Casi lo logramos!
                    </Typography>
                </div>
                <div className="m_y_lg">
                    {stepSchema.map((item, index) => {
                        return <StepperItem
                            key={index}
                            props={item}
                            position={{
                                index,
                                lastPosition: index === stepSchema.length - 1,
                                action: (value: string) => setCurrentStep(value)
                            }}
                        />;
                    })}
                </div>
            </div>}
            {openDetail && <div className="w_100_per_mobile flex_grow_1_desktop ">
                <div className="shadow_hard_desktop br_xs_desktop m_l_sm_desktop p_x_xl_desktop p_y_xxxl_desktop">
                    {!matchesMD && <div>
                        <Typography className="m_y_none m_b_md text_center" weight="600" scale="heading3" scaleMobile="heading4" component="h1">
                            Últimos pasos hacia tu crédito
                        </Typography>
                        <Typography className="m_y_none text_center_mobile" weight="400" scale="medium">
                            Solo faltan algunos detalles para finalizar. Sigue cada uno de los pasos para llegar a la moto de tus sueños. ¡Casi lo logramos!
                        </Typography>
                    </div>}
                    <FormikProvider value={formik}>
                        {stepSchema.filter((item) => item.step === currentStep)[0].component}

                        <div className="w_100_per_mobile w_300_px_desktop m_b_md m_x_auto">
                            <Button
                                disabled={!isValid()}
                                className="w_100_per"
                                scale="small"
                                onClick={handleContinueButton}
                            >
                                <Typography weight="600" scale="medium" scaleMobile="medium">
                                    Continuar
                                </Typography>
                            </Button>
                        </div>
                        {!matchesMD && <Button className="w_100_per p_y_none" scale="small" variant="ghost" onClick={handleBackButton}>
                            <Typography weight="600" scale="medium" scaleMobile="medium" textColor="neutral_700">
                                Volver
                            </Typography>
                        </Button>}
                    </FormikProvider>
                </div>
            </div>}
        </div>
    </div>;
};

export default LoanApplicationFinal;
