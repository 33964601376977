import React,{ FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClickAwayListener, useMediaQuery } from "@material-ui/core";
import { ReactComponent as Rigth } from "design_system/src/static/icons/right-lg.svg";
import  { Button } from "design_system/src";
import { IStepper } from "design_system/src/organisms/NewStepper/NewStepper/NewStepper";

interface IBreadcrumbItem {
    value: number;
    title: string;
}

interface IBreadcrumbProps {
    stepperComponents: Array<IStepper>[]
    activeStep: any;
    setActiveStep: Function;
}

const BreadCrumb: FC<IBreadcrumbProps> = ({
    stepperComponents, activeStep, setActiveStep
}) => {
    const navigate = useNavigate();
    const matchesXS = useMediaQuery("(min-width:600px)");
    const [openBc, setOpenBc] = useState(false);

    const BreadcrumList: IBreadcrumbItem[] = stepperComponents
        .filter((element) => (element[0].stepTitle != null && element[0].validationKey != null))
            .map((element, index) => (
            {
                value: index,
                title: element[0].stepTitle ?? ""
            })
    );

    const BreadcrumMainList = BreadcrumList
        .filter(({value}) => (value === activeStep || value === activeStep - 1));

    const BreadcrumSubList = BreadcrumList
        .filter(({value}) => (value < activeStep - 1));

    const renderDesktopBreadcrumb = () => (
        <div className="display_flex flex_align_center flex_justify_start flex_grow_0 p_y_md">
        <div>
            <Button
            scale="small"
            className="w_100_per"
            variant="link"
            onClick={() => navigate(-1)}
            >Inicio</Button>
        </div>
        {BreadcrumList.filter(({value}) => (value <= activeStep)).map(({value, title}, index) => (
            <div className="display_flex flex_align_center" key={index}>
                <Rigth className="text_neutral_700 w_md" />
                <Button
                    scale="small"
                    className="w_100_per"
                    variant="link"
                    onClick={() => {
                    if (activeStep !==  value) {setActiveStep(value);}
                    }}
                >{title}</Button>
            </div>
        ))}
        </div>
    );

    const renderBreadcrumb = () => (
        <div className="display_flex flex_align_center flex_justify_start flex_grow_0 p_y_md">
        {BreadcrumSubList.length === 0 && <div>
            <Button
            scale="small"
            className="w_100_per"
            variant="link"
            onClick={() => navigate(-1)}
            >Inicio</Button>
        </div>}
        {BreadcrumSubList.length > 0 && <div className="pos_relative">
            <Button
            scale="small"
            className="w_100_per"
            variant="link"
            onClick={() => setOpenBc(!openBc)}
            >...</Button>
            <div className="pos_absolute dso_card_small z_index_1 bg_neutral_0 m_y_xs" style={{display: openBc ? "block" : "none"}}>
            { openBc && <ClickAwayListener onClickAway={() => setOpenBc(!openBc)}>
                <div>
                <div>
                    <Button
                    scale="small"
                    className="text_start"
                    variant="link"
                    onClick={() => {
                        setOpenBc(false);
                        navigate(-1);
                    }}
                    >Inicio</Button>
                </div>
                {BreadcrumSubList.filter(({value}) => (value <= activeStep)).map(({value, title}, index) => (
                    <div className="display_flex flex_align_center" key={index}>
                        <Button
                            scale="small"
                            variant="link"
                            onClick={() => {
                            setOpenBc(false);
                            setActiveStep(value);
                            }}
                        >{title}</Button>
                    </div>
                ))}
                </div>
            </ClickAwayListener>}
            </div>
        </div>}
        {BreadcrumMainList.filter(({value}) => (value <= activeStep)).map(({value, title}, index) => (
            <div className="display_flex flex_align_center" key={index}>
            <Rigth className="text_neutral_700 w_md" />
            <Button
                scale="small"
                className="w_100_per"
                variant="link"
                onClick={() => {
                if (activeStep !==  value) {
                    setActiveStep(value);
                    setOpenBc(false);
                }
                }}
            >{title}</Button>
            </div>
        ))}
        </div>
    );

    return (matchesXS ? renderDesktopBreadcrumb() : renderBreadcrumb());
};

export default BreadCrumb;
