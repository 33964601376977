/* eslint-disable no-restricted-globals */
import React, { ReactElement, useState, useEffect } from "react";
import { FormikProvider, useFormik } from "formik";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

import {
  Typography,
  Button,
  ModalConfirmation,
} from "design_system/src";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useLocalStorage from "hooks/useLocalStorage";

import ResetButton from "components/ResetButton/ResetButton";
import { ReactComponent as Chat } from "design_system/src/static/icons/chat-circle-plain.svg";
import { Step1, Step2, Step3, Step4, Step5, Step6, Step6Complement } from "./steps";
import {
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step,
  StepFormValues,
  initialValues,
  validationSchema,
  validateStep,
  StepEnum,
} from "./constants";
import { ModalAddress } from "./ModalAddress";


const LoanApplication = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [storedValue, setStoredValue] = useLocalStorage<any>("loanApplication", null);
  const [currentStep, setCurrentStep] = useState<string>(step1);
  const [workInDigitalPlatforms, setWorkInDigitalPlatforms] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (storedValue !== null && !loading) {
      navigate("/solicitud-credito/comprobantes-de-ingreso");
    } else {
      setLoading(false);
    }
  }, [storedValue, loading]);

  const onSubmitForm = (values: any) => {
    setCompleted(true);
  };

  const formik = useFormik<StepFormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onSubmitForm(values);
    },
    enableReinitialize: true,
  });

  const { values } = formik;

  useEffect(() => {
    if (storedValue) {
      formik.setValues(storedValue);
    }
  }, [storedValue]);

  useEffect(() => {
    const value = _.get(values, StepEnum.workInDigitalPlatforms);
    if (value !== undefined) setWorkInDigitalPlatforms(value);
  }, [values]);

  const getNextStep = (): string | null => {
    switch (currentStep) {
      case step1:
        return _.get(values, StepEnum.palenca) !== true ? step2 : step3;
      case step2:
        return step3;
      case step3:
        return step4;
      case step4:
        if (workInDigitalPlatforms) return step5;
        return step6;
      default:
        return null;
    }
  };

  const phoneVerification = () => {
    formik.setFieldValue(StepEnum.phoneValidated, true);
    formik.setFieldValue(StepEnum.buroScore, 500);
    return true;
  };

  const steps: step[] = [
    {
      component: <Step1 />,
      name: step1,
      title: "Tu moto está más cerca",
      modalConfig: {
        title: <Typography scale="medium" weight="400" textColor="primary_300">
          Confirmar Ubicación
        </Typography>,
        component: <ModalAddress/>,
        widthDesktop: 40,
        heightDesktop: 100
      },
      button: {
        text: "Iniciar mi solicitud"
      }
    },
    {
      component: <Step2 />,
      name: step2,
      title: "Verifica tu número",
      validationFn: phoneVerification,
      button: {
        text: "Validar"
      }
    },
    {
      component: <Step3 />,
      name: step3,
      title: "Tu contexto familiar y financiero",
      button: {
        text: "Siguiente"
      }
    },
    {
      component: <Step4 />,
      name: step4,
      title: "¿Eres repartidor?",
    },
    {
      component: <Step5 />,
      name: step5,
      title: "Valida tus ingresos con palenca",
      button: {
        color: "secondary"
      }
    },
    {
      component: <Step6 />,
      name: step6,
      title: "¿Compruebas ingresos?",
      complementComponent: <Step6Complement />
    },
  ];

  const renderStepComponent = (): ReactElement<any, any> => {
    return steps.filter(({ name }) => name === currentStep).map(({ component }) => component)[0];
  };

  const renderStepComplomentComponent = (): ReactElement<any, any> | undefined => {
    return steps.filter(({ name }) => name === currentStep).map(({ complementComponent }) => complementComponent)[0];
  };

  const renderStepButtonText = (): string => {
    return steps.filter(({ name }) => name === currentStep).map(({ button }) => button?.text ?? "Continuar")[0];
  };

  const renderStepButtonColor = (): string => {
    return steps.filter(({ name }) => name === currentStep).map(({ button }) => button?.color ?? "primary")[0];
  };

  const renderStepTitle = (): string => {
    return steps.filter(({ name }) => name === currentStep).map(({ title }) => title)[0];
  };

  const getFunctionStep = (): any => {
    return steps.filter(({ name }) => name === currentStep).map(({ validationFn }) => validationFn)[0];
  };

  const getModalConfig = (): any => {
    return steps.filter(({ name }) => name === currentStep).map(({ modalConfig }) => modalConfig)[0];
  };

  const isValid = () => {
    return validateStep(currentStep, values);
  };

  const handleNext = async () => {
    const nextStep = getNextStep();
    if (!nextStep) {
      formik.submitForm();
			return;
    }

    let shouldContinue = true;
    const validateFn = getFunctionStep();

    if (validateFn) shouldContinue = await validateFn();
    if (shouldContinue) setCurrentStep(nextStep);
	};

  const handleOnclickBehaivor = async () => {
    const modalConfig = getModalConfig();
    if (modalConfig) {
      setOpenModal(true);
      return;
    }

    handleNext();
	};

  const onContinue = () => {
    setStoredValue({
      ...values,
      loanApplication: true
    });
  };

  return (
    <div className="dso_container" style={{ minHeight: "75vh" }}>
      <div className="m_y_lg_mobile m_y_xxxl_desktop m_x_auto w_400_px_desktop">
        {!completed && <div className="m_y_md">
          <Typography className="m_y_none m_b_md text_center" weight="600" scale="heading3" scaleMobile="heading4" component="h1">
            {renderStepTitle()}
          </Typography>
          <FormikProvider value={formik}>
            {renderStepComponent()}
            <Button
              className="w_100_per"
              orientation="right"
              scale="small"
              icon={<ArrowForwardIcon />}
              disabled={!isValid()}
              onClick={handleOnclickBehaivor}
              color={renderStepButtonColor() as "primary" | "secondary" | "neutral" | "green" | "red" | "gradient" | "grey" | "light_grey" | "primary_dark" | "white" | undefined}
            >
              {renderStepButtonText()}
            </Button>
            {renderStepComplomentComponent()}

            {openModal && getModalConfig() && (
              <ModalConfirmation
                widthDesktop={getModalConfig().widthDesktop}
                heightDesktop={getModalConfig().heightDesktop}
                handleClose={() => setOpenModal(false)}
                openConfirmation={openModal}
                onConfirmationCallBack={() => handleNext()}
                isConfirmationLoading={false}
                title={getModalConfig().title}
                subtitle={getModalConfig().component}
                copyReject="No, regrésame"
                copyConfirm="Si, seguro"
              />
            )}
          </FormikProvider>
        </div>}
        {completed && <div className="m_y_md">
          <Typography className="m_y_none m_b_md text_center" weight="600" scale="heading3" scaleMobile="heading4" component="h1">
            ¡Formulario completado!
          </Typography>
          <Typography className="m_y_none m_b_md text_center" weight="400" scale="medium" scaleMobile="medium" component="p">
            ¡Buen trabajo! Es hora de cargar tus comprobantes de ingreso. Si surge alguna pregunta, no dudes en consultarla con nuestro chatbot.
          </Typography>
          <br />
          <div className="display_flex flex_justify_center">
            <Button
              scale="small"
              onClick={onContinue}
            >
              Subir comprobantes de ingreso
            </Button>
          </div>
          <br />
          <br />
          <div className="m_y_md bg_neutral_200 border_solid border_1 border_neutral_500 p_xl">
            <div className="display_flex flex_justify_start flex_align_center">
              <div>
                <Chat className="custom-icon w_50_px"/>
              </div>
              <div className="flex_grow_1">
                <Typography className="m_l_md" weight="400" scale="small" scaleMobile="small" component="div">
                  Consulta tus dudas sobre ozon en nuestro chatbot mientras terminas de gestionar tu solicitud.{" "}
                  <a
                    className="text_primary_300"
                    style={{ fontWeight: 600 }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://t.me/techOzon_bot"
                  >@TECHOZON_BOT</a>
                </Typography>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default LoanApplication;
