/* eslint-disable consistent-return */
import _ from "lodash";
import { getQueryParams, QueryParams } from "helpers/queryParams";
import useVehicles from "hooks/useVehicles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchVehicles, setSelectedFilters, updateSelectedFilters } from "store/actions/vehicles";
import { SelectedFilters } from "store/reducers/vehiclesReducer";
import { VehicleSortTypeEnum } from "models/inventory/enums/vehicle.enums";

/* eslint-disable no-shadow */
export enum CatalogFiltersEnum {
    BRAND="brand",
    MODEL="model",
    YEAR="year",
    CYLINDERS="cylinders",
    MILEAGES="mileages",
    MIN_MILEAGES="minMileages",
    MAX_MILEAGES="maxMileages",
    COLOR="color",
    SALE_PRICE="salePrice",
    MIN_SALE_PRICE="minSalePrice",
    MAX_SALE_PRICE="maxSalePrice",
    NEW_VEHICLE="isNewVehicle",
    PAGE="page",
    SORT="sort",
}

interface FilterQueryParams {
    [CatalogFiltersEnum.BRAND]?: string;
    [CatalogFiltersEnum.MODEL]?: string;
    [CatalogFiltersEnum.YEAR]?: number;
    [CatalogFiltersEnum.CYLINDERS]?: number;
    [CatalogFiltersEnum.MILEAGES]?: number[];
    [CatalogFiltersEnum.MIN_MILEAGES]?: string;
    [CatalogFiltersEnum.MAX_MILEAGES]?: string;
    [CatalogFiltersEnum.COLOR]?: string;
    [CatalogFiltersEnum.SALE_PRICE]?: number[];
    [CatalogFiltersEnum.MIN_SALE_PRICE]?: string;
    [CatalogFiltersEnum.MAX_SALE_PRICE]?: string;
    [CatalogFiltersEnum.NEW_VEHICLE]?: string;
    [CatalogFiltersEnum.PAGE]?: number;
}


const getFiltersByQueryParams = (queryParams: QueryParams): FilterQueryParams => {
    const transformedObj: FilterQueryParams = {};

    Object.keys(queryParams).forEach((key) => {
        const values: any[] = [];
        if (Object.values(CatalogFiltersEnum).includes(key as keyof FilterQueryParams)) {
            queryParams[key]?.split(";").forEach((value) => values.push(value));
        }
        /* eslint-disable prefer-destructuring */
        if (values.length > 0) {
            transformedObj[key as keyof FilterQueryParams] = values[0];
            if (key === CatalogFiltersEnum.PAGE) {
                transformedObj[CatalogFiltersEnum.PAGE] = parseInt(transformedObj[key as keyof FilterQueryParams]!.toString(), 10);
            }
            if (key === CatalogFiltersEnum.YEAR) {
                transformedObj[CatalogFiltersEnum.YEAR] = parseInt(transformedObj[key as keyof FilterQueryParams]!.toString(), 10);
            }
        }
    });

    if (transformedObj[CatalogFiltersEnum.MIN_MILEAGES]) {
        transformedObj[CatalogFiltersEnum.MILEAGES] = [parseInt(transformedObj[CatalogFiltersEnum.MIN_MILEAGES], 10)];
        if (transformedObj[CatalogFiltersEnum.MAX_MILEAGES]) {
            transformedObj[CatalogFiltersEnum.MILEAGES].push(parseInt(transformedObj[CatalogFiltersEnum.MAX_MILEAGES], 10));
        }
    }
    transformedObj[CatalogFiltersEnum.MIN_MILEAGES] = undefined;
    transformedObj[CatalogFiltersEnum.MAX_MILEAGES] = undefined;

    if (transformedObj[CatalogFiltersEnum.MIN_SALE_PRICE]) {
        transformedObj[CatalogFiltersEnum.SALE_PRICE] = [parseInt(transformedObj[CatalogFiltersEnum.MIN_SALE_PRICE], 10)];
        if (transformedObj[CatalogFiltersEnum.MAX_SALE_PRICE]) {
            transformedObj[CatalogFiltersEnum.SALE_PRICE].push(parseInt(transformedObj[CatalogFiltersEnum.MAX_SALE_PRICE], 10));
        }
    }
    transformedObj[CatalogFiltersEnum.MIN_SALE_PRICE] = undefined;
    transformedObj[CatalogFiltersEnum.MAX_SALE_PRICE] = undefined;

    return transformedObj;
};


const transformSelectedFilters = (selectedFilters: SelectedFilters) => {
    const filters: any = {};
    filters[CatalogFiltersEnum.MIN_MILEAGES] = undefined;
    filters[CatalogFiltersEnum.MAX_MILEAGES] = undefined;
    filters[CatalogFiltersEnum.MIN_SALE_PRICE] = undefined;
    filters[CatalogFiltersEnum.MAX_SALE_PRICE] = undefined;

    Object.keys(selectedFilters).forEach((key) => {
        filters[key] = selectedFilters[key as keyof SelectedFilters];

        if (key === CatalogFiltersEnum.MILEAGES) {
            filters[CatalogFiltersEnum.MIN_MILEAGES] = selectedFilters[key][0];
            if (selectedFilters[key][1]) {
                filters[CatalogFiltersEnum.MAX_MILEAGES] = selectedFilters[key][1];
            }
            filters[CatalogFiltersEnum.MILEAGES] = undefined;
        }

        if (key === CatalogFiltersEnum.SALE_PRICE) {
            filters[CatalogFiltersEnum.MIN_SALE_PRICE] = selectedFilters[key][0];
            if (selectedFilters[key][1]) {
                filters[CatalogFiltersEnum.MAX_SALE_PRICE] = selectedFilters[key][1];
            }
            filters[CatalogFiltersEnum.SALE_PRICE] = undefined;
        }
    });

    return filters;
};

export const useCatalogFilters = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [catalogFilters, setCatalogFilters] = useState<Partial<SelectedFilters> | undefined>(undefined);
    const [partial, setPartial] = useState<boolean | undefined>();
    const [count, setCount] = useState<number>(0);
    const [initQueryParams, setInitQueryParams] = useState<FilterQueryParams | undefined>();
    const { selectedFilters } = useVehicles();

    useEffect(() => {
        if (catalogFilters) {
            if (!catalogFilters.sort) {
                catalogFilters.sort = VehicleSortTypeEnum.PROMOTION;
            }
            dispatch(setSelectedFilters(catalogFilters));
            setCount(count + 1);
        }
    }, [catalogFilters]);

    useEffect(() => {
        if (partial !== undefined && selectedFilters && count < 2) {
            if (partial) {
                dispatch(fetchVehicles());
                const queryParams = _.omitBy({
                    ...initQueryParams,
                    ...transformSelectedFilters(selectedFilters)
                }, _.isUndefined);

                const keys = Object.keys(queryParams).filter((key) => key !== "city" && key !== "limit");
                navigate(`/catalogo?${keys.map((key) => `${key}=${queryParams[key as keyof FilterQueryParams]}`).join("&")}`);
            } else if (count === 1) {
                dispatch(fetchVehicles());
            } else {
                setCatalogFilters(_.omitBy({
                    ...transformSelectedFilters(selectedFilters),
                    ...initQueryParams
                }, _.isUndefined));
            }
        }
    }, [selectedFilters]);

    const updateCatalogFilters = (
        selectedFilters: Partial<SelectedFilters> | undefined,
        isPartial: boolean = true
    ) => {
        const queryParams = getQueryParams(window.location.search);
        const initQueryParams = getFiltersByQueryParams(queryParams);

        setCount(0);
        setPartial(isPartial);
        setInitQueryParams(initQueryParams);

        if (selectedFilters) {
            if (isPartial) {
                Object.entries(selectedFilters).forEach(
                    ([key, value]) => {
                        if (key !== CatalogFiltersEnum.PAGE) {
                            dispatch(updateSelectedFilters(CatalogFiltersEnum.PAGE, 1));
                        }
                        dispatch(updateSelectedFilters(key as keyof SelectedFilters, value));
                        setInitQueryParams({
                            ...initQueryParams,
                            [key]: value
                        });
                    }
                );
            } else {
                dispatch(setSelectedFilters(selectedFilters));
            }
        }
    };

    return [updateCatalogFilters] as const;
};
