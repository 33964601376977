/* eslint-disable no-shadow */
export enum OrderStatus {
    pending = "pending",
    paid = "paid",
    deleted = "deleted",
}

export const OrderStatusValues = {
    [OrderStatus.paid]: {
        title: "Pagado",
        color: "green_300",
    },
    [OrderStatus.pending]: {
        title: "Pendiente",
        color: "secondary_500",
    },
    [OrderStatus.deleted]: {
        title: "Anulado",
        color: "neutral_400",
    },
};