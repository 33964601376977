import { VehicleStatusEnum } from "../enums/vehicle.enums";
import { ITVehicle, TVehicle } from "../interfaces/vehicle.interface";

export const data: ITVehicle[] = [
  {
    "_id": "66bcdec88e15d8005e550e17",
    "brand": {
      "_id": "6697fc7cc3aed627ff2cd3a6",
      "name": "ITALIKA",
      "models": []
    },
    "model": {
      "_id": "62a26168cc8bdce214670dec",
      "name": "VORT-X",
      "cylinderCapacity": []
    },
    "cylindersCapacity": 300,
    "purchaseDate": "2024-08-14T00:00:00.000Z",
    "color": "red",
    "purchaseCost": 26440,
    "brakeType": "disc",
    "engineSN": "LC178MN269112Q3",
    "vehicleSN": "LLCK5GLK2RA101430",
    "images": [
      {
        "name": "Foto1723854236513",
        "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/a0558fc6-ab30-4ea1-95c0-bd1a88d16af6/DSC33522.jpg",
        "formats": {
          "thumbnail": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/a0558fc6-ab30-4ea1-95c0-bd1a88d16af6/thumb.DSC33522.jpg",
            "size": 3612,
            "path": "uploads/a0558fc6-ab30-4ea1-95c0-bd1a88d16af6/thumb.DSC33522.jpg",
            "hash": "22e16b12603c089f9f886b2e53fc0d460ab95e63128d35e3bf94daca562e0304",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 132,
            "height": 132,
            "_id": "66bfed9a469112dcb199875c"
          },
          "small": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/a0558fc6-ab30-4ea1-95c0-bd1a88d16af6/small.DSC33522.jpg",
            "size": 14795,
            "path": "uploads/a0558fc6-ab30-4ea1-95c0-bd1a88d16af6/small.DSC33522.jpg",
            "hash": "7a294cb7ccc153365b3e2bfc45e25dd85c7077d9ea5788f0972cce06ef9d2f33",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 320,
            "height": 240,
            "_id": "66bfed9a469112dcb199875d"
          },
          "_id": "66bfed9a469112dcb199875b"
        },
        "_id": "66f49fe8abfbb2a117d48eac"
      },
      {
        "name": "Foto1723854236613",
        "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/1/DSC3355.jpg",
        "formats": {
          "thumbnail": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/1/thumb.DSC3355.jpg",
            "size": 3098,
            "path": "uploads/1/thumb.DSC3355.jpg",
            "hash": "97756194ef5a3329bb19af821eed7e3f1c079361b960e525bc235527024bf279",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 132,
            "height": 132,
            "_id": "66bfed9a469112dcb1998760"
          },
          "small": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/1/small.DSC3355.jpg",
            "size": 12565,
            "path": "uploads/1/small.DSC3355.jpg",
            "hash": "7ea40ec334984210d0dad53f7f6b5ed8afb621c7345bd4331b46aa2ab46dc7d8",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 320,
            "height": 240,
            "_id": "66bfed9a469112dcb1998761"
          },
          "_id": "66bfed9a469112dcb199875f"
        },
        "_id": "66f49fe8abfbb2a117d48eb0"
      },
      {
        "name": "Foto1723854236713",
        "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/2/DSC33562.jpg",
        "formats": {
          "thumbnail": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/2/thumb.DSC33562.jpg",
            "size": 3803,
            "path": "uploads/2/thumb.DSC33562.jpg",
            "hash": "7e922179f268bb0b6806e6a0b384fa9da69ae76dac5f89c9b33da16f7b925e09",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 132,
            "height": 132,
            "_id": "66bfed9a469112dcb1998764"
          },
          "small": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/2/small.DSC33562.jpg",
            "size": 15214,
            "path": "uploads/2/small.DSC33562.jpg",
            "hash": "4d82226bd6ef1fabfbc5fdce7ddad5e84ab98535725ed176b00f84464b1e2770",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 320,
            "height": 240,
            "_id": "66bfed9a469112dcb1998765"
          },
          "_id": "66bfed9a469112dcb1998763"
        },
        "_id": "66f49fe8abfbb2a117d48eb4"
      }
    ],
    "internalId": "OMX2156",
    "discounts": [],
    "status": VehicleStatusEnum.AVAILABLE,
    "isPresale": false,
    "maintenanceDays": 8,
    "documents": [],
    "documentalStatus": "digital",
    "visualStatus": "good",
    "hasTileDevice": false,
    "year": 2024,
    "mileage": 11922,
    "isNewVehicle": false,
    "shortDescription": "Es una motocicleta de estilo naked con un diseño agresivo y minimalista.",
    "description": "Es una motocicleta de estilo naked diseñada para aquellos que buscan una experiencia de conducción emocionante y minimalista. Con un diseño agresivo y minimalista, esta moto cuenta con una potencia impresionante y una maniobrabilidad excepcional, lo que la hace ideal para la conducción en ciudad y carreteras sinuosas. Además, cuenta con características modernas como un panel de instrumentos modernos.",
    "location": {
      "hub": {
        "_id": "62c86779af7d182793b9dd48",
        "name": "Anahuac"
      },
      "city": {
        "_id": "602479bab88ce81d7ea7a275",
        "name": "Ciudad de México"
      },
      "country": {
        "_id": "60247963b88ce81d7ea7a272",
        "tax_name": "IVA",
        "tax": 0.16,
        "currency": "MXN",
        "iso": "MX",
        "deposit": 150,
        "insurance": 0.2,
        "deliveryPrice": 150,
        "name": "México"
      }
    },
    "extraCosts": {
      "basicExtraCost": 0,
      "repairsCost": 0,
      "debtsCost": 0,
      "gpsHardwareCost": 599,
      "gpsMonthlySoftwareCost": 50
    },
    "marketPrice": 42000,
    "maintenanceColor": "black",
    "isPlatedVehicle": false,
    "salePrices": [
      {
        "loanAmount": 76398,
        "weeks": 78,
        "percentageIncrease": 81.9,
        "paymentWeek": 979.4615384615385,
        "earn": 34398,
        "capital": 338.97435897435895,
        "interest": 640.4871794871794,
        paymentWeekBeforeDiscount: null,
        loanAmountBeforeDiscount: null
      },
      {
        "loanAmount": 89040,
        "weeks": 104,
        "percentageIncrease": 112,
        "paymentWeek": 856.1538461538462,
        "earn": 47040,
        "capital": 254.23076923076923,
        "interest": 601.9230769230769,
        paymentWeekBeforeDiscount: null,
        loanAmountBeforeDiscount: null
      },
      {
        "loanAmount": 115458,
        "weeks": 156,
        "percentageIncrease": 174.9,
        "paymentWeek": 740.1153846153846,
        "earn": 73458,
        "capital": 169.48717948717947,
        "interest": 570.6282051282052,
        paymentWeekBeforeDiscount: null,
        loanAmountBeforeDiscount: null
      }
    ],
    "activeDiscount": null
  },
  {
    "_id": "66bcddec8e15d8005e54f83c",
    "brand": {
      "_id": "6697fc7dc3aed627ff2cd3be",
      "name": "BAJAJ",
      "models": []
    },
    "model": {
      "_id": "629e70e01b6f87e65fae3e1f",
      "name": "PULSAR",
      "cylinderCapacity": []
    },
    "cylindersCapacity": 250,
    "purchaseDate": "2024-08-14T00:00:00.000Z",
    "color": "black",
    "purchaseCost": 24346.4,
    "brakeType": "disc",
    "engineSN": "PDXCPE77295",
    "vehicleSN": "MD2B97FX4RCF00229",
    "images": [
      {
        "name": "Foto1724000736350",
        "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/82c68fe8-6f3a-4adf-92ec-82fd5ef36725/DSC3385.jpg",
        "formats": {
          "thumbnail": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/82c68fe8-6f3a-4adf-92ec-82fd5ef36725/thumb.DSC3385.jpg",
            "size": 3915,
            "path": "uploads/82c68fe8-6f3a-4adf-92ec-82fd5ef36725/thumb.DSC3385.jpg",
            "hash": "e3fa1a64c588bc311b4d354a5d347f7bba8010b2e03f96ed38ca63cf7074bd07",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 132,
            "height": 132,
            "_id": "66c229e2469112dcb1d0aa92"
          },
          "small": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/82c68fe8-6f3a-4adf-92ec-82fd5ef36725/small.DSC3385.jpg",
            "size": 16319,
            "path": "uploads/82c68fe8-6f3a-4adf-92ec-82fd5ef36725/small.DSC3385.jpg",
            "hash": "6e91b73020c9af642876a0625ea2b007e487fe1c343ea33d5008bf57d96c4870",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 320,
            "height": 240,
            "_id": "66c229e2469112dcb1d0aa93"
          },
          "_id": "66c229e2469112dcb1d0aa91"
        },
        "_id": "66f4a091abfbb2a117d492b7"
      },
      {
        "name": "Foto1724000736450",
        "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/1/DSC3387.jpg",
        "formats": {
          "thumbnail": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/1/thumb.DSC3387.jpg",
            "size": 3562,
            "path": "uploads/1/thumb.DSC3387.jpg",
            "hash": "c7604036f512d7b245378c643b0919f87b9cfb0d13109ee77851bfedaf85c287",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 132,
            "height": 132,
            "_id": "66c229e2469112dcb1d0aa96"
          },
          "small": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/1/small.DSC3387.jpg",
            "size": 15006,
            "path": "uploads/1/small.DSC3387.jpg",
            "hash": "20074155d1ecb0d30210d8c005683cf5783c653fae01c44ca44caaea1131a4af",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 320,
            "height": 240,
            "_id": "66c229e2469112dcb1d0aa97"
          },
          "_id": "66c229e2469112dcb1d0aa95"
        },
        "_id": "66f4a091abfbb2a117d492bb"
      },
      {
        "name": "Foto1724000736550",
        "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/2/DSC3388.jpg",
        "formats": {
          "thumbnail": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/2/thumb.DSC3388.jpg",
            "size": 3839,
            "path": "uploads/2/thumb.DSC3388.jpg",
            "hash": "a4aa44a39224110b3863792db7489c92998c77bbb73858e05b13e16e7c3bc51d",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 132,
            "height": 132,
            "_id": "66c229e2469112dcb1d0aa9a"
          },
          "small": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/2/small.DSC3388.jpg",
            "size": 16107,
            "path": "uploads/2/small.DSC3388.jpg",
            "hash": "07ae3dbd3d5e237ce733865954661dd0411df8fd1825fcb11e390452eab704f1",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 320,
            "height": 240,
            "_id": "66c229e2469112dcb1d0aa9b"
          },
          "_id": "66c229e2469112dcb1d0aa99"
        },
        "_id": "66f4a091abfbb2a117d492bf"
      }
    ],
    "internalId": "OMX2158",
    "discounts": [
      {
        "id": "172439673509300000000000",
        "status": "inactive",
        "type": "percentage",
        "value": 0.07,
        "createdAt": "2024-08-23T07:05:35.093Z"
      },
      {
        "id": "172729724704900000000000",
        "status": "inactive",
        "type": "percentage",
        "value": 0.05,
        "createdAt": "2024-09-25T20:47:27.049Z"
      }
    ],
    "status": VehicleStatusEnum.AVAILABLE,
    "isPresale": false,
    "maintenanceDays": 8,
    "documents": [],
    "documentalStatus": "digital",
    "visualStatus": "good",
    "hasTileDevice": false,
    "year": 2023,
    "mileage": 9011,
    "isNewVehicle": false,
    "shortDescription": "Es una motocicleta de estilo naked con un diseño agresivo y minimalista.",
    "description": "Es una motocicleta de estilo naked diseñada para aquellos que buscan una experiencia de conducción emocionante y minimalista. Con un diseño agresivo y minimalista, esta moto cuenta con una potencia impresionante y una maniobrabilidad excepcional, lo que la hace ideal para la conducción en ciudad y carreteras sinuosas. Además, cuenta con características modernas como un panel de instrumentos modernos.",
    "location": {
      "hub": {
        "_id": "62c86779af7d182793b9dd48",
        "name": "Anahuac"
      },
      "city": {
        "_id": "602479bab88ce81d7ea7a275",
        "name": "Ciudad de México"
      },
      "country": {
        "_id": "60247963b88ce81d7ea7a272",
        "tax_name": "IVA",
        "tax": 0.16,
        "currency": "MXN",
        "iso": "MX",
        "deposit": 150,
        "insurance": 0.2,
        "deliveryPrice": 150,
        "name": "México"
      }
    },
    "extraCosts": {
      "basicExtraCost": 0,
      "repairsCost": 0,
      "debtsCost": 0,
      "gpsHardwareCost": 599,
      "gpsMonthlySoftwareCost": 50
    },
    "marketPrice": 44000,
    "hasGpsTracker": false,
    "maintenanceColor": "black",
    "isPlatedVehicle": false,
    "salePrices": [
      {
        "loanAmount": 80036,
        "weeks": 78,
        "percentageIncrease": 81.9,
        "paymentWeek": 1026.1025641025642,
        "earn": 36036,
        "capital": 312.1333333333334,
        "interest": 713.9692307692308,
        paymentWeekBeforeDiscount: null,
        loanAmountBeforeDiscount: null
      },
      {
        "loanAmount": 93192,
        "weeks": 104,
        "percentageIncrease": 111.8,
        "paymentWeek": 896.0769230769231,
        "earn": 49192,
        "capital": 234.10000000000002,
        "interest": 661.9769230769231,
        paymentWeekBeforeDiscount: null,
        loanAmountBeforeDiscount: null
      },
      {
        "loanAmount": 120912.00000000001,
        "weeks": 156,
        "percentageIncrease": 174.8,
        "paymentWeek": 775.0769230769232,
        "earn": 76912.00000000001,
        "capital": 156.0666666666667,
        "interest": 619.0102564102565,
        paymentWeekBeforeDiscount: null,
        loanAmountBeforeDiscount: null
      }
    ],
    "activeDiscount": null
  },
  {
    "_id": "6639742ce3f72ab38a9321a4",
    "brand": {
      "_id": "6697fc7ec3aed627ff2cd3fe",
      "name": "VICTORY",
      "models": []
    },
    "model": {
      "_id": "65664f1aecccdd6a95a81a49",
      "name": "BOMBER",
      "cylinderCapacity": []
    },
    "cylindersCapacity": 125,
    "purchaseDate": "2024-05-06T00:00:00.000Z",
    "color": "blue",
    "purchaseCost": 25500,
    "brakeType": "drum",
    "engineSN": "9GFPCJ509RCK18474",
    "vehicleSN": "156FMI3BN1165774",
    "images": [
      {
        "name": "Foto1715041318627",
        "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/2e698a41-778f-4ad7-aece-52e2ef76c1a6/DSC2916.jpg",
        "formats": {
          "thumbnail": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/2e698a41-778f-4ad7-aece-52e2ef76c1a6/thumb.DSC2916.jpg",
            "size": 3566,
            "path": "uploads/2e698a41-778f-4ad7-aece-52e2ef76c1a6/thumb.DSC2916.jpg",
            "hash": "4d7a65df8aaf5ebaff38c9329db3d86fd0fa01b88b106460ec0eb510f860de21",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 132,
            "height": 132,
            "_id": "6639742ce3f72ab38a9321a8"
          },
          "small": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/2e698a41-778f-4ad7-aece-52e2ef76c1a6/small.DSC2916.jpg",
            "size": 16256,
            "path": "uploads/2e698a41-778f-4ad7-aece-52e2ef76c1a6/small.DSC2916.jpg",
            "hash": "96d4356f1a4319292fa7128f7316e00f560c3951b5c221eed209cf30ee11a902",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 320,
            "height": 240,
            "_id": "6639742ce3f72ab38a9321a9"
          },
          "_id": "6639742ce3f72ab38a9321a7"
        },
        "_id": "663bfa88b9cc5965a2e13e33"
      },
      {
        "name": "Foto1715041318727",
        "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/1/DSC2918.jpg",
        "formats": {
          "thumbnail": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/1/thumb.DSC2918.jpg",
            "size": 2798,
            "path": "uploads/1/thumb.DSC2918.jpg",
            "hash": "b38ea824b9c877cab6a0e9cdc77364b8fbb84c3687a9a21f461af11a179c08a7",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 132,
            "height": 132,
            "_id": "6639742ce3f72ab38a9321ac"
          },
          "small": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/1/small.DSC2918.jpg",
            "size": 13303,
            "path": "uploads/1/small.DSC2918.jpg",
            "hash": "5a6366efc162b4b3989ad1c819fba3eb2286318556be41ef4f95b4e714dbe507",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 320,
            "height": 240,
            "_id": "6639742ce3f72ab38a9321ad"
          },
          "_id": "6639742ce3f72ab38a9321ab"
        },
        "_id": "663bfa88b9cc5965a2e13e37"
      },
      {
        "name": "Foto1715041318827",
        "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/2/DSC2919.jpg",
        "formats": {
          "thumbnail": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/2/thumb.DSC2919.jpg",
            "size": 3267,
            "path": "uploads/2/thumb.DSC2919.jpg",
            "hash": "a4fab0835f4b2535c6949c62dc5d212e95f0e2b5cfa42d433bdc50175abf1556",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 132,
            "height": 132,
            "_id": "6639742ce3f72ab38a9321b0"
          },
          "small": {
            "url": "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/2/small.DSC2919.jpg",
            "size": 15001,
            "path": "uploads/2/small.DSC2919.jpg",
            "hash": "8c955af2932a3066fc93dd507c67a56431d6c12a5d618c333ce61fc7f5ab1909",
            "ext": ".jpg",
            "mimeType": "image/jpeg",
            "width": 320,
            "height": 240,
            "_id": "6639742ce3f72ab38a9321b1"
          },
          "_id": "6639742ce3f72ab38a9321af"
        },
        "_id": "663bfa88b9cc5965a2e13e3b"
      }
    ],
    "internalId": "OMX1716A",
    "discounts": [
      {
        "id": "171520685145600000000000",
        "status": "inactive",
        "type": "value",
        "value": 38,
        "createdAt": "2024-05-08T22:20:51.456Z"
      },
      {
        "id": "171579791893300000000000",
        "status": "inactive",
        "type": "percentage",
        "value": 0.05,
        "createdAt": "2024-05-15T18:31:58.933Z"
      },
      {
        "id": "172730598963100000000000",
        "status": "active",
        "type": "percentage",
        "value": 0.1,
        "createdAt": "2024-09-25T23:13:09.631Z"
      }
    ],
    "status": VehicleStatusEnum.AVAILABLE,
    "isPresale": false,
    "year": 2024,
    "mileage": 2439,
    "isNewVehicle": false,
    "shortDescription": "Es una motocicleta deportiva de alto rendimiento con un diseño aerodinámico y elegante.",
    "description": "Es una motocicleta deportiva diseñada para ofrecer un alto rendimiento y una experiencia de conducción emocionante. Con un motor potente y un chasis ligero, esta moto está construida para la velocidad y la agilidad en carreteras y circuitos. El diseño aerodinámico y elegante también la convierte en una moto impresionante que no pasará desapercibida en la carretera.",
    "location": {
      "hub": {
        "_id": "62c86779af7d182793b9dd48",
        "name": "Anahuac"
      },
      "city": {
        "_id": "602479bab88ce81d7ea7a275",
        "name": "Ciudad de México"
      },
      "country": {
        "_id": "60247963b88ce81d7ea7a272",
        "tax_name": "IVA",
        "tax": 0.16,
        "currency": "MXN",
        "iso": "MX",
        "deposit": 150,
        "insurance": 0.2,
        "deliveryPrice": 150,
        "name": "México"
      }
    },
    "extraCosts": {
      "basicExtraCost": 2000,
      "repairsCost": 0,
      "debtsCost": 0,
      "gpsHardwareCost": 599,
      "gpsMonthlySoftwareCost": 50
    },
    "marketPrice": 25500,
    "hasTileDevice": false,
    "maintenanceDays": 11,
    "visualStatus": "regular",
    "documentalStatus": "digital",
    "maintenanceColor": "black",
    "isPlatedVehicle": false,
    "activeDiscount": {
      "id": "172730598963100000000000",
      "status": "active",
      "type": "percentage",
      "value": 0.1,
      "createdAt": "2024-09-25T23:13:09.631Z"
    },
    "salePrices": [
      {
        "loanAmount": 35904,
        "weeks": 78,
        "percentageIncrease": 40.8,
        "paymentWeek": 460.3076923076923,
        "earn": 10404,
        "capital": 326.92307692307696,
        "interest": 133.38461538461536,
        paymentWeekBeforeDiscount: null,
        loanAmountBeforeDiscount: null
      },
      {
        "loanAmount": 40162.5,
        "weeks": 104,
        "percentageIncrease": 57.5,
        "paymentWeek": 386.17788461538464,
        "earn": 14662.5,
        "capital": 245.1923076923077,
        "interest": 140.98557692307693,
        paymentWeekBeforeDiscount: null,
        loanAmountBeforeDiscount: null
      },
      {
        "loanAmount": 49929,
        "weeks": 156,
        "percentageIncrease": 95.8,
        "paymentWeek": 320.0576923076923,
        "earn": 24429,
        "capital": 163.46153846153848,
        "interest": 156.59615384615384,
        paymentWeekBeforeDiscount: null,
        loanAmountBeforeDiscount: null
      }
    ]
  }
];

const getRandomVehicle = (): TVehicle => {
    const randomIndex = Math.floor(Math.random() * data.length);
    return new TVehicle(data[randomIndex]);
};

export const mockVehicleList = data.map((vehicle) => new TVehicle(vehicle));

export const mockVehicle = () => getRandomVehicle();

export const buildVehicleMock = (newData: Partial<ITVehicle>) => {
  const vehicle = getRandomVehicle();

  return new TVehicle({
    ...vehicle,
    ...newData
  });
};
